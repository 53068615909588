// import "../../src/elements/VerticalLayout/index.css"
//imports
import React, { lazy } from "react"
//CompanyUsers
const CompanyUsers = lazy(() => import("../pages/Dashboard/CompanyUsers"))
//Campaigns Page
const Campaign = lazy(() => import("../pages/Dashboard/Campaign"))
//Rewards Page
const Rewards = lazy(() => import("../pages/Dashboard/Rewards/Rewards"))

//Routes
const subscriberRoutes = [
  //Campaigns
  { path: "campaign", element: <Campaign /> },
]

export const subscriberPermission = [2]

export default subscriberRoutes
