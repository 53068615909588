import React, { Suspense, useEffect, useState } from "react"
import AppRoutes from "./routes"

// Import scss
import "./assets/scss/theme.scss"
//import Css
import "./pages/Guidelines/styles/channels.css"
import { useNavigate } from "react-router-dom"
import { ImageProvider } from "components/VerticalLayout/ImageContextProvider"

//cache busting
import CacheBuster from "react-cache-buster"
import packageJson from "../package.json"
// styleSheet
import "../src/App.css"
import AnimatedLoader from "Modules/AuthKit/components/AnimatedLoader"
import { isDevelopment, isProduction } from "Shared/utils/environmentVariables"

const App = () => {
  useEffect(() => {
    const session = Date.now()
    localStorage.setItem("session", session)
    window.dispatchEvent(new Event("storage"))
  }, [])

  return (
    <ImageProvider>
      <Suspense fallback={<>...Loading</>}>
        <CacheBuster
          currentVersion={packageJson.version}
          isEnabled={isProduction || isDevelopment}
          isVerboseMode={false}
          loadingComponent={<AnimatedLoader />}
          metaFileDirectory={"."}
        >
          <AppRoutes />
        </CacheBuster>
      </Suspense>
    </ImageProvider>
  )
}

// App.propTypes = {
//   layout: PropTypes.any,
// }

export default App
