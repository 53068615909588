import address from "../../services/address"
import axios from "axios"

const axiosApi = axios.create({
  baseURL: address,
})

export async function getAllPlans() {
  return await axiosApi.get("/api/plans")
}
export async function createSubscription(id, comapanyId, formdata) {
  const token = localStorage.getItem("token")
  if (token) {
    try {
      const { data } = await axiosApi.post(
        `/api/subscription-create/${id}/company/${comapanyId}`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      return data
    } catch (error) {
      return { success: false }
    }
  }
}
export async function changePaymentMethod(formdata) {
  const token = localStorage.getItem("token")
  if (token) {
    try {
      const { data } = await axiosApi.post(
        `/api/subscriber/subscription/payment-method/update`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      return data
    } catch (error) {
      return { success: false }
    }
  }
}
export async function verifyEmail(url) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    return data
  }
}
export async function changeSubscription(formdata) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.post(
      "/api/subscriber/subscription/plan/change",
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}

const allapis = {
  getAllPlans,
  changeSubscription,
  createSubscription,
  verifyEmail,
}
export default allapis
