import React from "react"

function withTitle(Component, title) {
  const ComponentWithTitle = props => {
    document.title = title ?? "MahaloHub"
    return <Component {...props} />
  }

  return ComponentWithTitle
}

export default withTitle
