import React from "react"
import "./Loading.css"
import sitelogo from "assets/images/Site Logos/sitelogo.png"
import VerticalLayout from "components/VerticalLayout"
export const MahaloLoader = props => {
  return (
    <>
      {props.layout ? (
        <VerticalLayout isUser="Loading">
          <LoadingPage {...props} />
        </VerticalLayout>
      ) : (
        <LoadingPage {...props} />
      )}
    </>
  )
}

const LoadingPage = props => {
  return (
    <div
      className={` ${!props.centered ? "" : " nonauth "}  ${
        props?.fullscreen ? " loader-screen " : ""
      } d-flex justify-content-end align-items-end`}
    >
      <div className="preloader-1">
        {/* //Logo in center and rotate it */}
        <div className="d-flex justify-content-center rotateLoading">
          <img width={30} src={sitelogo} alt="logo" className="logo" />
        </div>

        <div>
          <b>MahaloHub</b>
        </div>
      </div>
    </div>
  )
}
