import React, { useState, useEffect } from "react"
import {
  Modal,
  Button,
  Badge,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
} from "reactstrap"
import "../Sidebar.css"
import { changeSubscription } from "services/Subscription/Subscription"
import Swal from "sweetalert2"
import {
  useSelectedPlanId,
  usePlanUpdate,
  useUser,
  useSelectedPlanIdUpdate,
  usePlan,
} from "../ImageContextProvider"
import ReactLoading from "react-loading"
import Toast from "services/toast"

const PlanUpgradeModal = ({ isOpen, toggle }) => {
  const [isPlanSelected, setIsPlanSelected] = useState(false)
  const userDetails = useUser()
  const [isChanging, setIsChanging] = useState(false)
  const [basicDropDown, setBasicDropDown] = useState(false)
  const [plusDropDown, setPlusDropDown] = useState(false)
  const [proDropDown, setProDropDown] = useState(false)

  const [basicCategory, setBasicCategory] = useState("Select")
  const [plusCategory, setPlusCategory] = useState("Select")
  const [proCategory, setProCategory] = useState("Select")
  const updatePlan = usePlanUpdate()
  const plan = usePlan()
  const updateSelectedPlanId = useSelectedPlanIdUpdate()
  const selectedPlanId = useSelectedPlanId()
  const [newId, setNewId] = useState(selectedPlanId)
  useEffect(() => {
    if (selectedPlanId == 1) {
      setBasicCategory("Quarterly")
    } else if (selectedPlanId == 2) {
      setBasicCategory("Yearly")
    } else if (selectedPlanId == 3) {
      setPlusCategory("Quarterly")
    } else if (selectedPlanId == 4) {
      setPlusCategory("Yearly")
    } else if (selectedPlanId == 5) {
      setProCategory("Quarterly")
    } else if (selectedPlanId == 6) {
      setProCategory("Yearly")
    }
  }, [])
  const handleChangeSubscription = () => {
    if (!userDetails) return
    //Ask user to confirm change plan and give option for apply token, continue without token and cancel
    //If user selects apply token, then ask for token
    //If user selects continue without token, then change plan
    //If user selects cancel, then do nothing
    //If user selects apply token and enters wrong token, then show error message
    //If user selects apply token and enters correct token, then change plan
    let couponCode = null
    Swal.fire({
      title: "Are you sure?",
      text: "You want to change your plan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "Apply Coupon",
      denyButtonColor: "#41c7f2",
      showDenyButton: true,
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "order-0",
        denyButton: "order-0",
        cancelButton: "order-0",
      },
      // buttonsStyling: false,
    }).then(result => {
      if (result.isDenied) {
        Swal.fire({
          title: "Enter Coupon Code",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Apply",
          showLoaderOnConfirm: true,
          preConfirm: coupon => {
            applyChangePlan(coupon)
          },
        })
      } else if (result.isConfirmed) {
        applyChangePlan()
      }
    })

    function applyChangePlan(couponCode) {
      const formData = new FormData()
      formData.append("planId", newId)
      if (couponCode) {
        formData.append("couponCode", couponCode)
      }
      formData.append(
        "cardholder_name",
        userDetails.subscription.find(sub => sub?.cardholder_name != null)
          ?.cardholder_name || "N/A"
      )
      setIsChanging(true)
      changeSubscription(formData)
        .then(res => {
          setIsChanging(false)
          if (res?.success) {
            if (plan < newId) {
              if (newId == 1 || newId == 2) {
                updatePlan(1)
              } else if (newId == 3 || newId == 4) {
                updatePlan(2)
              } else if (newId == 5 || newId == 6) {
                updatePlan(3)
              }
              updateSelectedPlanId(newId)
            }
            if (couponCode) {
              Swal.fire({
                title: `Success!`,
                text: "Coupon Applied Successfully",
                timer: 1500,
                timerProgressBar: true,
                icon: "success",
              })
            }
            Swal.fire({
              title: `Success!`,
              text: "Plan Changed Successfully",
              timer: 1500,
              timerProgressBar: true,
              icon: "success",
            })
            toggle()
          } else {
            // Show error Message in Toast
            Swal.fire({
              title: "Update Plan!",
              text: res?.message,
              icon: "info",
              timer: 4500,
              timerProgressBar: true,
              confirmButtonText: "Ok",
              showConfirmButton: true,
            })
          }
        })
        .catch(err => {
          setIsChanging(false)
          Toast.fire({
            icon: "error",
            title: "Upgrade Plan!",
            text: "Something went wrong. Please try again later.",
          })
        })
    }
  }
  return (
    <Modal isOpen={isOpen} size="xl" centered toggle={toggle}>
      <div
        className="modal-header"
        style={{ backgroundColor: "#ebfcfc", border: "none" }}
      >
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body" style={{ backgroundColor: "#ebfcfc" }}>
        <div className="d-flex justify-content-center">
          <div style={{ width: "95%" }}>
            <Row>
              <Col md={4} className="mb-4 d-flex">
                <div
                  className="card payment-card round-card flex-grow-1"
                  style={{ borderRadius: "20px" }}
                >
                  <div className="card-body d-flex flex-column align-items-center">
                    <h1>
                      <b>Basic</b>
                    </h1>
                    <h3 className="card-subtitle mb-4 text-muted">
                      $99 /Month
                    </h3>
                    <Dropdown
                      className="mb-3"
                      disabled={isChanging}
                      style={{ marginTop: "-0.5vh" }}
                      isOpen={basicDropDown}
                      toggle={() => setBasicDropDown(!basicDropDown)}
                    >
                      <DropdownToggle
                        tag="button"
                        className="btn btn-primary btn-rounded"
                        onClick={() => setBasicDropDown(!basicDropDown)}
                        style={{
                          backgroundColor: "#41c7f2",
                          border: "none",
                        }}
                      >
                        {basicCategory} <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setPlusCategory("Select")
                            setProCategory("Select")
                            setBasicCategory("Quarterly")
                            setNewId(1)
                          }}
                        >
                          Quarterly
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setPlusCategory("Select")
                            setProCategory("Select")
                            setBasicCategory("Yearly")
                            setNewId(2)
                          }}
                        >
                          Yearly
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <p className="fLarge">$297 Billed Quarterly</p>
                    <p className="fLarge">$1,069 Billed Annually</p>
                    <p className="fLarge">(10% Discount)</p>
                    {(selectedPlanId == 1 || selectedPlanId == 2) && (
                      <Badge
                        pill
                        color="primary"
                        className="mb-3 custom-badge"
                        style={{ fontSize: "14px" }}
                      >
                        Current Plan
                      </Badge>
                    )}
                  </div>
                </div>
              </Col>
              <Col md={4} className="mb-4 d-flex">
                <div
                  className="card payment-card round-card flex-grow-1"
                  style={{
                    borderRadius: "20px",
                    backgroundColor: "#20394A",
                    color: "white",
                  }}
                >
                  <div className="card-body d-flex flex-column align-items-center">
                    <h1>
                      <b>Plus</b>
                    </h1>
                    <h3 className="card-subtitle mb-4">$149 /Month</h3>
                    <Dropdown
                      disabled={isChanging}
                      className="mb-3"
                      style={{ marginTop: "-0.5vh" }}
                      isOpen={plusDropDown}
                      toggle={() => setPlusDropDown(!plusDropDown)}
                    >
                      <DropdownToggle
                        tag="button"
                        className="btn btn-primary btn-rounded"
                        onClick={() => setPlusDropDown(!plusDropDown)}
                        style={{
                          backgroundColor: "#41c7f2",
                          border: "none",
                        }}
                      >
                        {plusCategory} <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setBasicCategory("Select")
                            setPlusCategory("Quarterly")
                            setProCategory("Select")
                            setNewId(3)
                          }}
                        >
                          Quarterly
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setBasicCategory("Select")
                            setPlusCategory("Yearly")
                            setProCategory("Select")
                            setNewId(4)
                          }}
                        >
                          Yearly
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <p className="fLarge">$447 Billed Quarterly</p>
                    <p className="fLarge">$1,609 Billed Annually</p>
                    <p className="fLarge">(10% Discount)</p>
                    <Badge
                      pill
                      color="warning"
                      className="mb-3 custom-badge"
                      style={{ fontSize: "inherit" }}
                    >
                      Most Popular
                    </Badge>
                    {(selectedPlanId == 3 || selectedPlanId == 4) && (
                      <Badge
                        pill
                        color="primary"
                        className=" mb-3 custom-badge"
                        style={{ fontSize: "14px" }}
                      >
                        Current Plan
                      </Badge>
                    )}
                  </div>
                </div>
              </Col>
              <Col md={4} className="mb-4 d-flex">
                <div
                  className="card payment-card round-card flex-grow-1"
                  style={{ borderRadius: "20px" }}
                >
                  <div className="card-body d-flex flex-column align-items-center">
                    <h1>
                      <b>Pro</b>
                    </h1>
                    <h4 className="card-subtitle mb-4 text-muted">
                      $249 /Month
                    </h4>
                    <Dropdown
                      className="mb-3"
                      disabled={isChanging}
                      style={{ marginTop: "-0.5vh" }}
                      isOpen={proDropDown}
                      toggle={() => setProDropDown(!proDropDown)}
                    >
                      <DropdownToggle
                        tag="button"
                        className="btn btn-primary btn-rounded"
                        onClick={() => setProDropDown(!proDropDown)}
                        style={{
                          backgroundColor: "#41c7f2",
                          border: "none",
                        }}
                      >
                        {proCategory} <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setBasicCategory("Select")
                            setPlusCategory("Select")
                            setProCategory("Quarterly")
                            setNewId(5)
                          }}
                        >
                          Quarterly
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setBasicCategory("Select")
                            setPlusCategory("Select")
                            setProCategory("Yearly")
                            setNewId(6)
                          }}
                        >
                          Yearly
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <p className="fLarge">$747 Billed Quarterly</p>
                    <p className="fLarge">$2,689 Billed Annually</p>
                    <p className="fLarge">(10% Discount)</p>
                    {(selectedPlanId == 5 || selectedPlanId == 6) && (
                      <Badge
                        pill
                        color="primary"
                        className=" mb-3 custom-badge"
                        style={{ fontSize: "14px" }}
                      >
                        Current Plan
                      </Badge>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <div className="text-center mb-4">
              <Button
                className="btn-mahalo"
                disabled={isChanging}
                onClick={() => {
                  window.open("https://www.mahalohub.com/pricing", "_blank")
                }}
              >
                Plan Details
              </Button>
              &nbsp;
              {selectedPlanId != newId && (
                <Button
                  className="btn-mahalo"
                  onClick={() => {
                    handleChangeSubscription()
                  }}
                >
                  {isChanging ? (
                    <ReactLoading
                      type={"spin"}
                      color={"#f3f3f3"}
                      height={20}
                      width={20}
                    />
                  ) : (
                    "Update Plan"
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default PlanUpgradeModal
