export const address =
  process.env.REACT_APP_BACKEND_ADDRESS || "https://REACT_APP_BACKEND_ADDRESS"

export const VideoEditaddress =
  process.env.REACT_APP_VIDEO_EDIT || "https://SET_REACT_APP_VIDEO_EDIT.com"

export const AwsS3DomainUrladdress =
  process.env.REACT_APP_AWS_S3_DOMAIN_URL ||
  "https://SET_REACT_APP_AWS_S3_DOMAIN_URL.com"

export default address
