import React, { useEffect, useState, useRef } from "react"
//components
import { Card, Input } from "reactstrap"
//dependencies
import { useNavigate } from "react-router-dom"
import SimpleBar from "simplebar-react"
import ReactLoading from "react-loading"
//services
import services, {
  getAllAssignedCampaigns,
  selectCampaignForUser,
} from "./services"
// users
import user1 from "../../../assets/images/userAvatar.png"
import {
  useAssignedCampaigns,
  useAssignedCampaignsUpdate,
  useUser,
  useUserUpdate,
} from "../../VerticalLayout/ImageContextProvider"
import { refreshSession } from "helpers/utils"
import { useDispatch } from "react-redux"
import { initAuth } from "store/actions"
import clearUserData from "Shared/helpers/clearUserData"
export function CampaignViewer(props) {
  const globalUser = useUser()
  const globalAssignedCampaigns = useAssignedCampaigns()
  const globalUpdateAssignedCampaigns = useAssignedCampaignsUpdate()
  const { setCampaignMenu } = props
  const [allAssignedCampaigns, setAllAssignedCampaigns] = useState(false)

  useEffect(() => {
    if (globalUser) {
      if (!globalAssignedCampaigns) {
        //getAssigned Campaigns
        services(getAllAssignedCampaigns).then(res => {
          setAllAssignedCampaigns(res.campaigns)
          globalUpdateAssignedCampaigns(res.campaigns)
        })
      } else {
        //Update state Assigned Campaigns
        setAllAssignedCampaigns(globalAssignedCampaigns)
      }
    }
  }, [globalUser])

  return (
    <Card
      style={{
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
      }}
      className="p-2 mx-2 border-bottom"
    >
      {!(allAssignedCampaigns && globalUser) ? (
        <div className="d-flex justify-content-center flex-wrap px-2">
          <button className="btn btn-sm bg-transparent col-12 d-flex align-items-center gap-2">
            <img
              src={user1}
              alt=""
              className="rounded-circle header-profile-user"
            />

            <h5 className="font-size-16 m-0 wrap-eclipses-100">
              Loading Campaigns...
            </h5>
            {/* <p className="mb-0 text-muted">Switch Campaign</p> */}
            {/* //   <div className="spinner-border text-primary" role="status"></div> */}
          </button>
          <div
            className="m-2"
            style={{
              width: "95%",
              height: "0.5px",
              background: "rgba(204, 214, 218, 1)",
            }}
          ></div>
          <div className="col-12 justify-content-between d-flex align-items-center">
            <button className="btn bg-transparent text-primary h4 mb-0 border-0">
              <b>Please wait</b>
            </button>
            <span className="badge bg-danger rounded-pill align-self-center">
              <ReactLoading
                type={"spin"}
                color={"white"}
                height={20}
                width={20}
              />
            </span>
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-center flex-wrap px-2">
            <button className="btn btn-sm bg-transparent col-12 d-flex align-items-center gap-2">
              <img
                src={user1}
                alt=""
                className="rounded-circle header-profile-user"
              />

              <h5 className="font-size-16 m-0 wrap-eclipses-100">
                {allAssignedCampaigns.find(
                  campaign => campaign.id === globalUser?.activeCampaign?.id
                )?.name || "No Campaign Selected"}
              </h5>
              {/* <p className="mb-0 text-muted">Switch Campaign</p> */}
            </button>
            <div
              className="m-2"
              style={{
                width: "95%",
                height: "0.5px",
                background: "rgba(204, 214, 218, 1)",
              }}
            ></div>
          </div>

          <div className="col-12 justify-content-between d-flex align-items-center">
            <button
              className="btn bg-transparent text-primary h4 mb-0 border-0"
              onClick={() => {
                setCampaignMenu(prev => !prev)
              }}
            >
              <b>See all campaigns</b>
            </button>
            {allAssignedCampaigns?.length - 1 > 0 && (
              <span className="badge bg-danger rounded-pill align-self-center">
                + {allAssignedCampaigns.length - 1}
              </span>
            )}
          </div>
        </>
      )}
    </Card>
  )
}
export function AssignedCampaignsMenu(props) {
  const globalAssignedCampaigns = useAssignedCampaigns()
  const globalUpdateAssignedCampaigns = useAssignedCampaignsUpdate()
  return (
    <SimpleBar
      className="d-flex flex-wrap mt-1 py-2"
      style={{ maxHeight: "180px", overflow: "auto" }}
    >
      {globalAssignedCampaigns &&
        globalAssignedCampaigns.map(campaign => {
          return <CampaignOption key={campaign.id} campaign={campaign} />
        })}
    </SimpleBar>
  )
}
export function CampaignOption(props) {
  const navigate = useNavigate()
  const globalUser = useUser()
  const { campaign } = props
  const clearAllData = clearUserData()
  const dispatch = useDispatch()
  //
  const RadioRef = useRef()
  useEffect(() => {
    if (globalUser.activeCampaign.id == campaign.id) {
      RadioRef.current.checked = true
    } else {
      RadioRef.current.checked = false
    }
  }, [globalUser])

  return (
    <div
      className={`${
        campaign.status == "inactive" ? "disabled-div" : ""
      } bg-hover-light form-check form-radio-outline form-radio-primary | col-12 p-0 mb-1 px-2 p-1 | d-flex align-items-center justify-content-between`}
    >
      <label
        onClick={e => {
          e.stopPropagation()
          RadioRef.current.click()
        }}
        className="form-check-label d-flex align-items-center gap-2 flex-grow-1"
        htmlFor={campaign.id}
      >
        <img
          src={user1}
          alt=""
          className="rounded-circle header-profile-user"
        />
        <h5 className="m-0 h6 wrap-eclipses-20">{campaign.name}</h5>
      </label>
      <input
        ref={RadioRef}
        onChange={e => {
          e.stopPropagation()
          services(selectCampaignForUser, campaign.id).then(res => {
            const token = localStorage.getItem("token")
            const superAgency = localStorage.getItem("superAgency")
            const superToken = localStorage.getItem("superToken")
            if (res.success) {
              localStorage.clear()
              if (superAgency) localStorage.setItem("superAgency", superAgency)
              if (superToken) localStorage.setItem("superToken", superToken)
              dispatch(initAuth({ token: token }))
              clearAllData()
              refreshSession(token)
              navigate("/user/manage", { replace: true })
            }
          })
        }}
        type="radio"
        id={campaign.id}
        name={campaign.name}
        className="form-check-input"
      />
    </div>
  )
}
