import actionTypes from "./actionTypes"

const initialState = {
  search: "",
  searchResults: [],
  loading: false,
  error: null,
  success: false,
}

const AdvanceSearch = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH:
      return {
        ...state,
        search: action.payload,
        loading: null,
        error: null,
        success: false,
      }
    case actionTypes.GET_ADVANCE_SEARCH_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      }
    case actionTypes.GET_ADVANCE_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
        searchResults: action.payload.payload,
        // search: action.payload.search,
      }
    case actionTypes.GET_ADVANCE_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      }
    case actionTypes.RESET_SEARCH:
      return {
        ...state,
        search: "",
        searchResults: [],
        loading: false,
        error: null,
        success: false,
      }
    default:
      return state
  }
}

export default AdvanceSearch
