import {
  FETCH_EMAIL_BODIES_REQUEST,
  FETCH_EMAIL_BODIES_SUCCESS,
  SET_FIRST_EMAIL_BODY,
  FETCH_EMAIL_BODIES_FAILURE,
  SET_OnBording_EMAIL_BODY,
  SET_EMAIL_CADENCE,
} from "./actionTypes"

export const fetchEmailBodies = () => ({
  type: FETCH_EMAIL_BODIES_REQUEST,
})

export const fetchEmailBodiesSuccess = data => ({
  type: FETCH_EMAIL_BODIES_SUCCESS,
  payload: data,
})

export const fetchEmailBodiesFailure = error => ({
  type: FETCH_EMAIL_BODIES_FAILURE,
  payload: error,
})

export const setOnBordingBody = emailBody => {
  return {
    type: SET_OnBording_EMAIL_BODY,
    payload: emailBody,
  }
}

export const setEmailCadence = emailCadence => {
  return {
    type: SET_EMAIL_CADENCE,
    payload: emailCadence,
  }
}

export const setFirstEmailBody = emailBody => {
  return {
    type: SET_FIRST_EMAIL_BODY,
    payload: emailBody,
  }
}
