import React, { lazy, Suspense } from "react"
import { Routes } from "react-router-dom"
import { Route } from "react-router-dom"
import Page404 from "../Modules/AuthKit/pages/Page404"

import RequireAuth from "../Modules/AuthKit/Middlewares/RequireAuth"
import PublicRoutes from "./publicRoutes"
import PersistantUser from "../Modules/AuthKit/Middlewares/PersistantUser"
import { MahaloLoader } from "../Modules/AuthKit/components/MahaloLoader"
//Routes
import loginRoutes from "./loginRoutes"

//utils
import checkCacheVersion from "../Shared/utils/checkCacheVersion"
import userRoutes, { userPermission } from "./userRoutes"
import { uniqueId } from "lodash"
import VerticalLayout from "components/VerticalLayout"
import subscriberRoutes, { subscriberPermission } from "./subscriberRoutes"
import managerRoutes, { managerPermission } from "./managerRoutes"
import agencyRoutes from "./agencyRoutes"
import EmailVerified from "Modules/AuthKit/Middlewares/EmailVerified"
import PaymentVerified from "Modules/AuthKit/Middlewares/PaymentVerified"
import adminRoutes from "./adminRoutes"
import RequireAdmin from "Modules/AuthKit/Middlewares/RequireAdmin"
import { isAgency } from "Shared/utils/roles"
import NonPaymentVerified from "Modules/AuthKit/Middlewares/NonPaymentVerified"
import VerificationRoutes from "./verificationRoutes"
import SyncAccounts from "Modules/AuthKit/Middlewares/SyncAccounts"
import subdomainRoutes from "./subdomainRoutes"
import subscriberManagerRoutes, {
  subscriberManagerPermission,
} from "./subscriberManagerRoutes"

//

function index() {
  checkCacheVersion()
  return (
    <Suspense
      fallback={
        <MahaloLoader
          layout={
            localStorage.getItem("token") || localStorage.getItem("superToken")
          }
          fullscreen={true}
        />
      }
    >
      <Routes>
        <Route element={<SyncAccounts />}>
          {/* Login Route */}
          <Route element={<EmailVerified />}>
            <Route element={<PaymentVerified />}>
              <Route element={<PersistantUser />}>
                {loginRoutes.map((route, index) => (
                  <Route
                    key={uniqueId}
                    path={route.path}
                    element={route.element}
                  />
                ))}
              </Route>
            </Route>
          </Route>

          {/* Non Payment Verified */}
          <Route element={<NonPaymentVerified />}>
            {VerificationRoutes.map((route, index) => (
              <Route key={uniqueId} path={route.path} element={route.element} />
            ))}
          </Route>

          {/* Admin Routes */}
          <Route element={<RequireAdmin />}>
            {adminRoutes.map((route, index) => (
              <Route
                key={uniqueId}
                path={route.path}
                element={
                  <VerticalLayout isUser="admin">
                    {route.element}
                  </VerticalLayout>
                }
              />
            ))}
          </Route>
          {/*End of Admin Routes */}

          {/*!--------- Auth Routes -----------!*/}
          <Route path="/user/*">
            {/* //User Routes */}
            <Route element={<RequireAuth allowedRole={userPermission} />}>
              {userRoutes.map((route, index) => (
                <Route
                  key={uniqueId}
                  path={route.path}
                  element={<VerticalLayout>{route.element}</VerticalLayout>}
                />
              ))}
            </Route>

            {/* Subscriber Routes */}
            <Route element={<RequireAuth allowedRole={subscriberPermission} />}>
              {subscriberRoutes.map((route, index) => (
                <Route
                  key={uniqueId}
                  path={route.path}
                  element={<VerticalLayout>{route.element}</VerticalLayout>}
                />
              ))}
            </Route>
            {/* Manager Routes */}
            <Route element={<RequireAuth allowedRole={managerPermission} />}>
              {managerRoutes.map((route, index) => (
                <Route
                  key={uniqueId}
                  path={route.path}
                  element={<VerticalLayout>{route.element}</VerticalLayout>}
                />
              ))}
            </Route>
            {/* Common Subscriber Manager Routes */}
            <Route
              element={
                <RequireAuth allowedRole={subscriberManagerPermission} />
              }
            >
              {subscriberManagerRoutes.map((route, index) => (
                <Route
                  key={uniqueId}
                  path={route.path}
                  element={<VerticalLayout>{route.element}</VerticalLayout>}
                />
              ))}
            </Route>
            {/* Agency Route */}
            <Route element={<RequireAuth isAllowed={!!isAgency()} />}>
              {agencyRoutes.map((route, index) => (
                <Route
                  key={uniqueId}
                  path={route.path}
                  element={<VerticalLayout>{route.element}</VerticalLayout>}
                />
              ))}
            </Route>
            <Route path="*" element={<Page404 />} />
          </Route>
        </Route>

        {/* Public Route */}
        {PublicRoutes.map((route, index) => (
          <Route key={uniqueId} path={route.path} element={route.element} />
        ))}

        {/* Microsite */}
        {subdomainRoutes.map((route, index) => (
          <Route key={uniqueId} path={route.path} element={route.element} />
        ))}

        {/*Not Found Route*/}
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Suspense>
  )
}

export default index
