import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import oneThemeLogo from "assets/images/Site Logos/oneTheme.png"
import logo from "../../assets/images/Site Logos/Logo.jpg"
import sitelogo from "assets/images/Site Logos/sitelogo.png"
import backgroundOverlay from "assets/images/backgroundoverlay.jpg"
import { sendResetEmail } from "../../services/auth/auth"
import { login } from "../../services/auth/auth"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Modal,
  Container,
  Form,
  Input,
  FormFeedback,
  Button,
  Label,
} from "reactstrap"
import ReactLoading from "react-loading"
//Import Pricing Cards
import Swal from "sweetalert2"
import ModalOptionSelection from "./components/ModalOptionSelection"
//Services
import services, {
  selectCampaignForUser,
} from "components/CommonForBoth/TopbarDropdown/services"
import { axiosApi } from "services"
import Toast from "services/toast"
import { removeToken } from "helpers/utils"
import useNavigateWithState from "Shared/hooks/useNavigateWithState"
import { useDispatch, useSelector } from "react-redux"
import { LOGIN_SUCCESS } from "Modules/AuthKit/store/actionTypes"
import { initAuth, loginAdmin, loginSuccess, loginUser } from "store/actions"
import withTitle from "Shared/helpers/withTitle"

const Subscription = () => {
  //redux
  const dispatch = useDispatch()
  //navigation
  const auth = useSelector(state => state.Auth)
  //
  const navigate = useNavigateWithState()
  const location = useLocation()
  const fromLocation = useMemo(() => {
    return location.state?.from
  }, [location])

  // useEffect(() => {
  //   if (!auth.token) return
  //   if (auth.emailVerified && auth.paymentVerified) {
  //     console.log("dispatching login success")
  //     // dispatch(initAuth(auth.token))
  //     navigate(fromLocation ?? "/user/dashboard")
  //   }
  // }, [auth])

  // <!---Fetch New Version-->
  //States
  const [signin_modal, setSignin_modal] = useState(
    location.state && location.state.openLogin !== undefined
      ? location.state.openLogin
      : true
  )
  // <!--Login Credentials-->
  const [credentials, setCredentials] = useState()
  //<!---------Login Tokens----------->
  const [accessToken, setAccessToken] = useState(localStorage.getItem("token"))
  const [forgot_modal, setForgot_modal] = useState(false)
  const [isloading, setIsLoading] = useState(false)
  const [signing, setSigning] = useState(false)
  // <!---Companies Selection-->
  const [companies, setCompanies] = useState([])
  const [campaigns, setCampaigns] = useState([])
  const [loginData, setLoginData] = useState([])
  //<!---------Modal Settings--------------->
  const [modal_Company, setModal_Company] = useState(false)
  const [modal_Campaign, setModal_Campaign] = useState(false)
  function tog_modal_Company() {
    setModal_Company(prev => !prev)
  }
  function tog_modal_Campaign() {
    setModal_Campaign(prev => !prev)
  }

  //Login Protected Route Transfer
  function loginToCampaign(data, id) {
    if (data.token) {
      const regex = /playlist\/([^/]+)/
      localStorage.clear()
      localStorage.setItem("authUser", JSON.stringify(data))
      if (data.user?.isSystemAdmin) {
        removeToken()
        localStorage.setItem("superToken", data["token"])
      } else {
        localStorage.setItem("token", data["token"])
      }
      if (data?.company?.plan) {
        // localStorage.setItem("spdT", "p1")
      }
      // if (data.socials) {
      //   if (
      //     data.socials.facebookPageToken != undefined ||
      //     data.socials.facebookPageToken != null
      //   ) {
      //     localStorage.setItem("fbToken", "1")
      //   }
      //   if (
      //     data.socials.googleYoutubeToken != undefined ||
      //     data.socials.googleYoutubeToken != null
      //   ) {
      //     localStorage.setItem("ytToken", "1")
      //   }
      //   if (
      //     data.socials.instagramPageId != undefined ||
      //     data.socials.instagramPageId != null
      //   ) {
      //     localStorage.setItem("instagramToken", "1")
      //   }

      //   if (data.socials.linkedinToken) {
      //     localStorage.setItem("linkedInToken", data.socials.linkedinToken)
      //   }
      //   if (data.socials.twitterToken) {
      //     localStorage.setItem("TwitterToken", data.socials.twitterToken)
      //   }
      //   // if (data.activeCampaign.widgetUrl) {
      //   //   const matches = data.activeCampaign.widgetUrl.match(regex)
      //   //   const widgetlistId = matches[1]
      //   //   localStorage.setItem("widgetlistId", widgetlistId)
      //   // }
      // }
      if (data.user.isSystemAdmin) {
        // localStorage.setItem("isUser", "admin")
        // localStorage.setItem("isFresh", "9")
        // localStorage.setItem("spdT", "p1")
      } else {
        // if (id || data?.user.assignedCampaigns[id].pivot.role_id > 2) {
        // localStorage.setItem("isFresh", "100")
        // } else {
        //   localStorage.setItem("isFresh", data.company.user.isFresh)
        // }
        // localStorage.setItem("isUser", "user")
        // localStorage.setItem("playlistId", data.activeCampaign.playlistId)
      }
      return data
    }
  }
  function LoginProtectedRoute(res, id) {
    loginToCampaign(res, id)
    if (res?.success) {
      if (!res.user?.isSystemAdmin) {
        dispatch(loginUser(res))
        // localStorage.setItem("session", "new")
        // window.dispatchEvent(new Event("storage"))
      } else {
        dispatch(loginAdmin(res))
        navigate("/manage")
      }
    } else {
      if (res) {
        //Login Failed
        setSigning(false)
        const x = document.getElementById("wrong")
        x.innerText = "*Incorrect email or password"
      }
    }
  }
  // <!----------------------->
  const [pricing, setPricing] = useState([])
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async values => {
      // Loggin in then Return
      if (signing) return
      //Start Loggin in Process
      //Set Credentials in State
      setSigning(true)
      setCredentials(values)
      //Call Api for companies
      await login(values)
        .then(res => {
          //Login During Campaign Selection
          if (res?.success) {
            if (res.companies) {
              if (res.companies.length == 0) {
                //Add to Campaign
                removeToken()
                const x = document.getElementById("wrong")
                x.innerText = "*Please Contact Admin to add you to a company"
                setSigning(false)
              } else {
                //Show Campaigns to select
                if (res.companies.length > 1) {
                  setCompanies(res.companies)
                  tog_modal_Company()
                }
              }
            } else {
              if (res.assignedCampaigns) {
                if (res.assignedCampaigns.length > 1) {
                  setCampaigns(res.assignedCampaigns)
                  setLoginData(res)
                  setModal_Campaign(true)
                } else if (res.assignedCampaigns[0].status == "active")
                  selectCampaign(res.assignedCampaigns[0].id, res)
                else {
                  alert("Please Contact Admin to activate your account")
                }
              } else {
                LoginProtectedRoute(res, null)
              }
            }
          } else {
            //Login Failed
            setSigning(false)
            const x = document.getElementById("wrong")
            if (res?.message) {
              x.innerText = `*${res?.message}`
            } else {
              x.innerText = "*Incorrect email or password"
            }
          }
        })
        .catch(err => {
          console.log("error:", err)
          x.innerText = "*Something went wrong, Please try again later"
        })
    },
  })

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_signin() {
    setSignin_modal(!signin_modal)
    removeBodyCss()
  }
  function tog_forgot() {
    setForgot_modal(!forgot_modal)
    removeBodyCss()
  }

  const validation2 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      setIsLoading(true)
      sendResetEmail(values)
        .then(res => {
          setIsLoading(false)
          const x = document.getElementById("wrong1")
          setIsLoading(false)

          if (res.status === "We have emailed your password reset link.") {
            x.style.color = "green"
            x.style.display = "block"
            x.innerText = "*Reset Email Sent"
          } else if (
            res.email === "We can't find a user with that email address."
          ) {
            x.style.color = "red"
            x.style.display = "block"
            x.innerText = "*Cannot Find Such User."
          } else {
            x.style.color = "red"
            x.style.display = "block"
            x.innerText = "*Kindly wait Before resending Email."
          }
        })
        .catch(err => {
          console.log("error:", err)
          Swal.fire({
            title: "Error!",
            text: "Something went wrong",
            icon: "error",
            confirmButtonText: "Ok",
            timerProgressBar: true,
            timer: 3000,
          })
          setIsLoading(false)
        })
    },
  })

  // Select Campaign
  const selectCampaign = async (id, loginData) => {
    return new Promise(async (resolve, reject) => {
      const token = loginData.token
      const res = await axiosApi.post(
        `/api/subscriber/campaigns/user/campaigns/${id}/select`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (res.data.success) {
        LoginProtectedRoute(loginData, id)
        resolve()
      } else {
        reject()
        setSigning(false)
        setModal_Campaign(false)
        setModal_Campaign(false)
        Toast.fire({
          text: res.data.message ? res.data.message : "Something Went Wrong",
          icon: "info",
        })
      }
    })
  }

  return (
    <div
      className="vh-100"
      style={{ backgroundImage: `url(${backgroundOverlay})` }}
    >
      <div className="d-flex justify-content-center p-4">
        <img
          src={oneThemeLogo}
          alt="Logo"
          style={{
            mixBlendMode: "multiply",
            maxWidth: "100%",
            width: "auto",
            minWidth: "10rem",
            height: "7vw",
            margin: "20px auto 0",
            display: "block",
          }}
          className="logo-image"
        />
      </div>
      <Col lg="12" className="d-none">
        <Card>
          <CardBody className="border-bottom">
            <div className="d-flex justify-content-between align-items-center">
              <div className="flex-left">
                <img src={logo} alt="Logo" height={24} className="logo-image" />
              </div>
              <div className="flex-right">
                {/* <Button
                  style={{
                    background: "#02C6FF",
                    border: "none",
                    color: "white",
                  }}
                  className="btn-rounded btn-sm"
                  onClick={() => {
                    tog_signin()
                  }}
                >
                  Sign In
                </Button> */}
                <Modal
                  keyboard={false}
                  size="md"
                  isOpen={signin_modal}
                  // toggle={() => {
                  //   tog_signin()
                  // }}
                  onClosed={() => {
                    validation.resetForm() // this clears the form fields
                  }}
                  centered
                >
                  <div className="modal-header border-0 pt-0">
                    {/* <button
                      onClick={() => {
                        setSignin_modal(false)
                      }}
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button> */}
                  </div>
                  <div className="modal-body">
                    {modal_Company ? (
                      <ModalOptionSelection
                        key={"Select Company"}
                        title={"Select Company"}
                        description={""}
                        data={companies}
                        onClick={id => {
                          return new Promise(async (resolve, reject) => {
                            const values = {
                              email: credentials.email,
                              password: credentials.password,
                              companyId: id,
                            }
                            await login(values)
                              .then(res => {
                                resolve()
                                if (res.assignedCampaigns.length > 1) {
                                  setCampaigns(res.assignedCampaigns)
                                  setLoginData(res)
                                  setModal_Campaign(true)
                                  setModal_Company(false)
                                } else {
                                  selectCampaign(
                                    res.assignedCampaigns[0].id,
                                    res
                                  )
                                }
                              })
                              .catch(err => {
                                reject()
                                console.log("error:", err)
                              })
                          })
                        }}
                      />
                    ) : modal_Campaign ? (
                      <ModalOptionSelection
                        key={"Select Campaign"}
                        title={"Select Campaign"}
                        description={""}
                        data={campaigns}
                        onClick={id => {
                          //Select Campaign
                          return selectCampaign(id, loginData)
                        }}
                      />
                    ) : (
                      <Row>
                        <Col md={12} lg={12} xl={12}>
                          <div className="bg-primary bg-soft">
                            <Row>
                              <Col xs={8}>
                                <div className="text-primary p-4">
                                  <h6 className="text-primary">
                                    Welcome Back!
                                  </h6>
                                  {/* <p>Sign in to continue to MahaloHub.</p> */}
                                </div>
                              </Col>
                              <Col className="col-4 align-self-end">
                                <img
                                  src={sitelogo}
                                  alt=""
                                  style={{ width: "70%", height: "70%" }}
                                  className="img-fluid"
                                />
                              </Col>
                            </Row>
                          </div>
                          <div>
                            <Link to="/" className="logo-light-element">
                              <div className="avatar-md profile-user-wid mb-4">
                                <span className="avatar-title rounded-circle bg-light">
                                  <img
                                    src={logo}
                                    alt=""
                                    className="rounded-circle"
                                    height="34"
                                  />
                                </span>
                              </div>
                            </Link>
                          </div>
                          <div className="p-2">
                            <Form
                              className="form-horizontal"
                              onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                              }}
                            >
                              {/* {error ? (
                                <Alert color="danger">{error}</Alert>
                              ) : null} */}

                              <div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                  autoComplete="username"
                                  name="email"
                                  className="form-control"
                                  placeholder="Enter email"
                                  type="email"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email &&
                                    validation.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.email &&
                                validation.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">Password</Label>
                                <Input
                                  autoComplete="current-password"
                                  name="password"
                                  value={validation.values.password || ""}
                                  type="password"
                                  placeholder="Enter Password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.password &&
                                    validation.errors.password
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.password &&
                                validation.errors.password ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.password}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div id="wrong" style={{ color: "red" }}></div>

                              <div className="mt-3 d-grid">
                                {!signing ? (
                                  <Button
                                    style={{
                                      background: "#02C6FF",
                                      border: "none",
                                      color: "white",
                                    }}
                                    className="btn-rounded btn-block "
                                    type="submit"
                                  >
                                    Log In
                                  </Button>
                                ) : (
                                  <Button
                                    style={{
                                      background: "#02C6FF",
                                      border: "none",
                                      color: "white",
                                    }}
                                    type="button"
                                    className="btn btn-rounded waves-effect waves-light d-flex justify-content-center align-items-center"
                                  >
                                    <ReactLoading
                                      type={"spin"}
                                      color={"white"}
                                      height={19}
                                      width={19}
                                    />
                                  </Button>
                                )}
                              </div>
                              <div className="mt-4 text-center">
                                <Button
                                  className="font-weight-medium text-primary bg-white border-0"
                                  onClick={() => {
                                    tog_signin()
                                    tog_forgot()
                                  }}
                                >
                                  {" "}
                                  <a>Forgotten password?</a>
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </div>
                </Modal>
                <Modal
                  size="md"
                  isOpen={forgot_modal}
                  toggle={() => {
                    tog_forgot()
                  }}
                  onClosed={() => {
                    validation2.resetForm() // this clears the form fields
                  }}
                  centered
                >
                  <div className="modal-header border-0">
                    <button
                      onClick={() => {
                        setForgot_modal(false)
                      }}
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Row>
                      <Col md={12} lg={12} xl={12}>
                        <div className="bg-primary bg-soft">
                          <Row>
                            <Col xs={8}>
                              <div className="text-primary p-4">
                                <h6 className="text-primary">
                                  Forgot Password !
                                </h6>
                                <p>
                                  Enter your email address to reset your
                                  password.
                                </p>
                              </div>
                            </Col>
                            <Col className="col-4 align-self-end">
                              <img
                                src={sitelogo}
                                alt=""
                                style={{ width: "70%", height: "70%" }}
                                className="img-fluid"
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="p-2">
                          <Form
                            className="form-horizontal"
                            onSubmit={e => {
                              e.preventDefault()
                              const x = document.getElementById("wrong1")
                              x.style.display = "none"
                              validation2.handleSubmit()
                              return false
                            }}
                          >
                            <div className="mb-3">
                              <Label className="form-label">Email</Label>
                              <Input
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                type="email"
                                onChange={validation2.handleChange}
                                onBlur={validation2.handleBlur}
                                value={validation2.values.email || ""}
                                invalid={
                                  validation2.touched.email &&
                                  validation2.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation2.touched.email &&
                              validation2.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation2.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div
                              id="wrong1"
                              style={{ color: "red", display: "none" }}
                            ></div>
                            <Row className="mb-3">
                              <Col className="text-end">
                                <Button className="btn-mahalo" type="submit">
                                  {isloading ? (
                                    <ReactLoading
                                      type="spin"
                                      color="#d5d8dc"
                                      height={20}
                                      width={15}
                                    />
                                  ) : (
                                    "Reset"
                                  )}
                                </Button>
                              </Col>
                              <div className="mt-2 text-center">
                                <Button
                                  onClick={() => {
                                    tog_signin()
                                    tog_forgot()
                                  }}
                                  className="font-weight-medium text-primary bg-white border-0 p-0 m-0"
                                >
                                  <span className="text-dark">Back to </span>
                                  Login
                                </Button>
                              </div>
                            </Row>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Modal>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </div>
  )
}
export default withTitle(Subscription, "Login | MahaloHub")
