import React, { lazy } from "react"

//Agency
const Agency = lazy(() => import("../pages/Dashboard/Agency"))

//Routes
const agencyRoutes = [
  //Agency
  { path: "agency", element: <Agency /> },
]

export const subscriberPermission = undefined

export default agencyRoutes
