import { axiosRequest } from "services"

const apis = {
  getUserDetails: (token, config) => ({
    endpoint: `api/profile/user/details`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      ...config,
    },
    trueMessage: null,
    falseMessage: "default",
    errorMessage: "default",
  }),
}

//Requests
export const getUserDetails = async (token, config = {}) => {
  return await axiosRequest(apis.getUserDetails(token, config))
}
