import React, { useMemo } from "react"
import { useSelector } from "react-redux"

function isAdmin() {
  const auth = useSelector(state => state.Auth)
  const admin = useMemo(() => {
    return !auth.authUser && !!auth.superUser
  }, [auth.authUser, auth.superUser])
  return admin
}

export default isAdmin
