export const SET_SEARCH = "SET_SEARCH"
export const GET_ADVANCE_SEARCH = "GET_ADVANCE_SEARCH"
export const GET_ADVANCE_SEARCH_SUCCESS = "GET_ADVANCE_SEARCH_SUCCESS"
export const GET_ADVANCE_SEARCH_FAILURE = "GET_ADVANCE_SEARCH_FAILURE"
export const GET_ADVANCE_SEARCH_LOADING = "GET_ADVANCE_SEARCH_LOADING"
export const RESET_SEARCH = "RESET_SEARCH"

const actionTypes = {
  SET_SEARCH,
  GET_ADVANCE_SEARCH,
  GET_ADVANCE_SEARCH_SUCCESS,
  GET_ADVANCE_SEARCH_FAILURE,
  GET_ADVANCE_SEARCH_LOADING,
  RESET_SEARCH,
}

export default actionTypes
