import React, { useEffect, useRef, useState } from "react"
import SearchBoxMobile from "./SearchBoxMobile"
import { useNavigate } from "react-router"
// import useSearchQuery from "helpers/hooks/useSearchquery"
//redux
import { useSelector, useDispatch } from "react-redux"

function SearchBox() {
  //imports
  // const searchQuery = useSearchQuery("search")
  // const dispatch = useDispatch()
  // redux
  const AdvanceSearch = useSelector(state => state.AdvanceSearch)
  //states
  const [search, setSearch] = useState("")
  const navigate = useNavigate()
  //helpers
  const inputRef = useRef(null)
  //
  const handleSearch = e => {
    e.preventDefault()
    navigate(`/user/advance-search?search=${search}`)
    setSearch(search)
    //remove search parameter
    const url = new URL(window.location.href)
    url.searchParams.delete("search")
    window.history.replaceState({}, "", url)
    //unfocus the input
    if (inputRef.current) {
      inputRef.current.blur()
    }
  }
  //______________________USE EFFECT_______________________________
  useEffect(() => {
    setSearch("")
  }, [AdvanceSearch])

  return (
    <>
      <form className="app-search d-none d-md-block" onSubmit={handleSearch}>
        <div className="position-relative">
          <input
            ref={inputRef}
            type="text"
            value={search}
            onChange={e => setSearch(e.target.value)}
            className="form-control"
            placeholder="Search..."
          />
          <span
            className="bx bx-search-alt cursor-pointer"
            onClick={() => {
              navigate(`/user/advance-search?search=${""}`)
            }}
          />
        </div>
      </form>
    </>
  )
}

export default SearchBox
