import React, { useState, useContext } from "react"
//createContextFunctions
const ImageContext = React.createContext()
const ImageUpdateContext = React.createContext()
const clearData = React.createContext()
const TestimonialDataContext = React.createContext()
const TestimonialDataUpdateContext = React.createContext()
const RefreshContext = React.createContext()
const TestimonialChangeFlagContext = React.createContext()
const TestimonialChangeFlagUpdateContext = React.createContext()
const RefreshUpdateContext = React.createContext()
const PlaylistContext = React.createContext()
const PlaylistUpdateContext = React.createContext()
const WidgetlistContext = React.createContext()
const WidgetlistUpdateContext = React.createContext()
const NotificationsContext = React.createContext()
const NotificationsUpdateContext = React.createContext()
const PlanContext = React.createContext()
const PlanUpdateContext = React.createContext()
const AnalyticsContext = React.createContext()
const AnalyticsUpdateContext = React.createContext()
const ClientsContext = React.createContext()
const ClientsUpdateContext = React.createContext()
const AnalyticsOverviewContext = React.createContext()
const AnalyticsOverviewUpdateContext = React.createContext()
const SharedTestimonialContext = React.createContext()
const SharedTestimonialUpdateContext = React.createContext()
const SelectedPlanIdContext = React.createContext()
const SelectedPlanIdUpdateContext = React.createContext()
const UserContext = React.createContext()
const UserUpdateContext = React.createContext()
const TempRefreshContext = React.createContext()
const TempRefreshUpdateContext = React.createContext()
const CompanyNameContext = React.createContext()
const CompanyNameUpdateContext = React.createContext()
const EmailFrameContext = React.createContext()
const EmailFrameUpdateContext = React.createContext()
const AskEmailContext = React.createContext()
const AskEmailUpdateContext = React.createContext()
const ReminderEmailContext = React.createContext()
const ReminderEmailUpdateContext = React.createContext()
const InviteEmailContext = React.createContext()
const InviteEmailUpdateContext = React.createContext()
const OwnQuestionsContext = React.createContext()
const OwnQuestionsUpdateContext = React.createContext()
const QuestionBankContext = React.createContext()
const QuestionBankUpdateContext = React.createContext()
//Assigned Campaigns
const AssignedCampaignsContext = React.createContext()
const AssignedCampaignsUpdateContext = React.createContext()
export function useAssignedCampaigns() {
  return useContext(AssignedCampaignsContext)
}
export function useAssignedCampaignsUpdate() {
  return useContext(AssignedCampaignsUpdateContext)
}
//End Assigned Campaigns
//Rewards Dashboard
const TangoAccountContext = React.createContext()
const TangoAccountUpdateContext = React.createContext()
export function useTangoAccount() {
  return useContext(TangoAccountContext)
}
export function useTangoAccountUpdate() {
  return useContext(TangoAccountUpdateContext)
}
//End Rewards Dashboard

//<!-----Use Context Function------->
export function useOwnQuestions() {
  return useContext(OwnQuestionsContext)
}
export function useOwnQuestionsUpdateContext() {
  return useContext(OwnQuestionsUpdateContext)
}
export function useQuestionBank() {
  return useContext(QuestionBankContext)
}
export function useQuestionBankUpdateContext() {
  return useContext(QuestionBankUpdateContext)
}
export function useEmailFrame() {
  return useContext(EmailFrameContext)
}
export function useEmailFrameUpdate() {
  return useContext(EmailFrameUpdateContext)
}
export function useAskEmail() {
  return useContext(AskEmailContext)
}
export function useAskEmailUpdate() {
  return useContext(AskEmailUpdateContext)
}
export function useReminderEmail() {
  return useContext(ReminderEmailContext)
}
export function useReminderEmailUpdate() {
  return useContext(ReminderEmailUpdateContext)
}
export function useInviteEmail() {
  return useContext(InviteEmailContext)
}
export function useInviteEmailUpdate() {
  return useContext(InviteEmailUpdateContext)
}

export function useCompanyName() {
  return useContext(CompanyNameContext)
}
export function useCompanyNameUpdate() {
  return useContext(CompanyNameUpdateContext)
}

export function useTempRefresh() {
  return useContext(TempRefreshContext)
}
export function useTempRefreshUpdate() {
  return useContext(TempRefreshUpdateContext)
}

export function useUser() {
  return useContext(UserContext)
}
export function useUserUpdate() {
  return useContext(UserUpdateContext)
}

export function useSelectedPlanId() {
  return useContext(SelectedPlanIdContext)
}

export function useSelectedPlanIdUpdate() {
  return useContext(SelectedPlanIdUpdateContext)
}
export function useSharedTestimonial() {
  return useContext(SharedTestimonialContext)
}
export function useSharedTestimonialUpdate() {
  return useContext(SharedTestimonialUpdateContext)
}

export function useAnalyticsOverview() {
  return useContext(AnalyticsOverviewContext)
}
export function useAnalyticsOverviewUpdate() {
  return useContext(AnalyticsOverviewUpdateContext)
}
export function useClients() {
  return useContext(ClientsContext)
}
export function useClientUpdate() {
  return useContext(ClientsUpdateContext)
}

export function usePlaylist() {
  return useContext(PlaylistContext)
}
export function useWidgetlist() {
  return useContext(WidgetlistContext)
}

export function usePlaylistUpdate() {
  return useContext(PlaylistUpdateContext)
}
export function useWidgetlistUpdate() {
  return useContext(WidgetlistUpdateContext)
}
export function useImage() {
  return useContext(ImageContext)
}

export function useImageUpdate() {
  return useContext(ImageUpdateContext)
}
export function useClearData() {
  return useContext(clearData)
}

export function useTestimonialData() {
  return useContext(TestimonialDataContext)
}

export function useTestimonialDataUpdate() {
  return useContext(TestimonialDataUpdateContext)
}
export function useTestimonialChangeFlag() {
  return useContext(TestimonialChangeFlagContext)
}
export function useTestimonialChangeFlagUpdate() {
  return useContext(TestimonialChangeFlagUpdateContext)
}
export function useRefresh() {
  return useContext(RefreshContext)
}

export function useRefreshUpdate() {
  return useContext(RefreshUpdateContext)
}

export function useNotifications() {
  return useContext(NotificationsContext)
}

export function useNotificationsUpdate() {
  return useContext(NotificationsUpdateContext)
}

export function usePlan() {
  return useContext(PlanContext)
}

export function usePlanUpdate() {
  return useContext(PlanUpdateContext)
}

export function useAnalytics() {
  return useContext(AnalyticsContext)
}

export function useAnalyticsUpdate() {
  return useContext(AnalyticsUpdateContext)
}

export function ImageProvider({ children }) {
  const [image, setImage] = useState()
  const [testimonialData, setTestimonialData] = useState([])
  const [testimonialChangeFlag, setTestimonialChangeFlag] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [playlist, setPlaylist] = useState([])
  const [Widgetlist, setWidgetlist] = useState([])
  const [emailFrame, setEmailFrame] = useState([null, null, null])
  const [askEmail, setAskEmail] = useState("")
  const [inviteEmail, setInviteEmail] = useState("")
  const [reminderEmail, setReminderEmail] = useState("")
  const [notifications, setNotifications] = useState([])
  const [plan, setPlan] = useState("")
  const [analytics, setAnalytics] = useState()
  const [clients, setClients] = useState()
  const [analyticsOverview, setAnalyticsOverview] = useState([])
  const [sharedTestimonial, setSharedTestimonial] = useState()
  const [selectedPlanId, setSelectedPlanId] = useState()
  const [user, setUser] = useState()
  const [tempRefresh, setTempRefresh] = useState(false)
  const [companyName, setCompanyName] = useState("")
  const [ownQuestions, setOwnQuestions] = useState(null)
  const [questionBank, setQuestionBank] = useState(null)
  const [assignedCampaigns, setAssignedCampaigns] = useState()
  const [tangoAccount, setTangoAccount] = useState()

  //clear Function
  const clearContextData = () => {
    setImage()
    setTestimonialData([])
    setTestimonialChangeFlag(true)
    setRefresh(false)
    setPlaylist([])
    setWidgetlist([])
    setEmailFrame([null, null, null])
    setAskEmail("")
    setInviteEmail("")
    setReminderEmail("")
    setNotifications([])
    setPlan("")
    setAnalytics()
    setClients()
    setAnalyticsOverview([])
    setSharedTestimonial()
    setSelectedPlanId()
    setUser()
    setTempRefresh(false)
    setCompanyName("")
    setOwnQuestions(null)
    setQuestionBank(null)
    setAssignedCampaigns()
    setTangoAccount()
  }

  // Update State Function
  function updateOwnQuestions(oldOwnQuestions) {
    setOwnQuestions(oldOwnQuestions)
  }
  function updateQuestionBank(questionsBank) {
    setQuestionBank(questionsBank)
  }
  function updatEmailFrame(emailFrame) {
    setEmailFrame(emailFrame)
  }
  function updateAskEmail(email) {
    setAskEmail(email)
  }

  function updateReminderEmail(email) {
    setReminderEmail(email)
  }
  function updateInviteEmail(email) {
    setInviteEmail(email)
  }

  function updateCompanyName(companyNames) {
    setCompanyName(companyNames)
  }
  function updateTRefresh(trefresh) {
    setTempRefresh(trefresh)
  }
  function updateUser(users) {
    setUser(users)
  }
  function updateSelectedPlanId(selectedPlanIds) {
    setSelectedPlanId(selectedPlanIds)
  }

  function updateSharedTestimonial(sharedTestimonials) {
    setSharedTestimonial(sharedTestimonials)
  }
  function updateAnalyticsOverView(analyticsOverviews) {
    setAnalyticsOverview(analyticsOverviews)
  }
  function updateClients(client) {
    setClients(client)
  }
  function updatePlaylist(playlists) {
    setPlaylist(playlists)
  }
  function updateWidgetlist(widgetlists) {
    setWidgetlist(widgetlists)
  }

  function updateTestimonialFlagChange(testimonialChangeFlag) {
    setTestimonialChangeFlag(testimonialChangeFlag)
  }
  function updateRefresh(refresh) {
    setRefresh(refresh)
  }

  function updateImage(image) {
    setImage(image)
  }

  function updateTestimonialData(data) {
    setTestimonialData(data)
  }

  function updateNotifications(notifications) {
    setNotifications(notifications)
  }

  function updatePlan(paymentplan) {
    setPlan(paymentplan)
  }

  function updateAnalytics(newAnalytics) {
    setAnalytics(newAnalytics)
  }
  function updateAssignedCampaigns(assignedCampaigns) {
    setAssignedCampaigns(assignedCampaigns)
  }
  function updateTangoAccount(tangoAccount) {
    setTangoAccount(tangoAccount)
  }

  return (
    <ImageContext.Provider value={image}>
      <ImageUpdateContext.Provider value={updateImage}>
        <clearData.Provider value={clearContextData}>
          <TestimonialDataContext.Provider value={testimonialData}>
            <TestimonialDataUpdateContext.Provider
              value={updateTestimonialData}
            >
              <TestimonialChangeFlagContext.Provider
                value={testimonialChangeFlag}
              >
                <TestimonialChangeFlagUpdateContext.Provider
                  value={updateTestimonialFlagChange}
                >
                  <RefreshContext.Provider value={refresh}>
                    <RefreshUpdateContext.Provider value={updateRefresh}>
                      <PlaylistContext.Provider value={playlist}>
                        <WidgetlistContext.Provider value={Widgetlist}>
                          <PlaylistUpdateContext.Provider
                            value={updatePlaylist}
                          >
                            <WidgetlistUpdateContext.Provider
                              value={updateWidgetlist}
                            >
                              <NotificationsContext.Provider
                                value={notifications}
                              >
                                <NotificationsUpdateContext.Provider
                                  value={updateNotifications}
                                >
                                  <PlanContext.Provider value={plan}>
                                    <PlanUpdateContext.Provider
                                      value={updatePlan}
                                    >
                                      <AnalyticsContext.Provider
                                        value={analytics}
                                      >
                                        <AnalyticsUpdateContext.Provider
                                          value={updateAnalytics}
                                        >
                                          <ClientsContext.Provider
                                            value={clients}
                                          >
                                            <ClientsUpdateContext.Provider
                                              value={updateClients}
                                            >
                                              <AnalyticsOverviewContext.Provider
                                                value={analyticsOverview}
                                              >
                                                <AnalyticsOverviewUpdateContext.Provider
                                                  value={
                                                    updateAnalyticsOverView
                                                  }
                                                >
                                                  <SharedTestimonialContext.Provider
                                                    value={sharedTestimonial}
                                                  >
                                                    <SharedTestimonialUpdateContext.Provider
                                                      value={
                                                        updateSharedTestimonial
                                                      }
                                                    >
                                                      <SelectedPlanIdContext.Provider
                                                        value={selectedPlanId}
                                                      >
                                                        <SelectedPlanIdUpdateContext.Provider
                                                          value={
                                                            updateSelectedPlanId
                                                          }
                                                        >
                                                          <UserContext.Provider
                                                            value={user}
                                                          >
                                                            <UserUpdateContext.Provider
                                                              value={updateUser}
                                                            >
                                                              <TempRefreshContext.Provider
                                                                value={
                                                                  tempRefresh
                                                                }
                                                              >
                                                                <TempRefreshUpdateContext.Provider
                                                                  value={
                                                                    updateTRefresh
                                                                  }
                                                                >
                                                                  <CompanyNameContext.Provider
                                                                    value={
                                                                      companyName
                                                                    }
                                                                  >
                                                                    <CompanyNameUpdateContext.Provider
                                                                      value={
                                                                        updateCompanyName
                                                                      }
                                                                    >
                                                                      <EmailFrameContext.Provider
                                                                        value={
                                                                          emailFrame
                                                                        }
                                                                      >
                                                                        <EmailFrameUpdateContext.Provider
                                                                          value={
                                                                            updatEmailFrame
                                                                          }
                                                                        >
                                                                          <AskEmailContext.Provider
                                                                            value={
                                                                              askEmail
                                                                            }
                                                                          >
                                                                            <AskEmailUpdateContext.Provider
                                                                              value={
                                                                                updateAskEmail
                                                                              }
                                                                            >
                                                                              <ReminderEmailContext.Provider
                                                                                value={
                                                                                  reminderEmail
                                                                                }
                                                                              >
                                                                                <ReminderEmailUpdateContext.Provider
                                                                                  value={
                                                                                    updateReminderEmail
                                                                                  }
                                                                                >
                                                                                  <InviteEmailContext.Provider
                                                                                    value={
                                                                                      inviteEmail
                                                                                    }
                                                                                  >
                                                                                    <InviteEmailUpdateContext.Provider
                                                                                      value={
                                                                                        updateInviteEmail
                                                                                      }
                                                                                    >
                                                                                      <OwnQuestionsContext.Provider
                                                                                        value={
                                                                                          ownQuestions
                                                                                        }
                                                                                      >
                                                                                        <OwnQuestionsUpdateContext.Provider
                                                                                          value={
                                                                                            updateOwnQuestions
                                                                                          }
                                                                                        >
                                                                                          <QuestionBankContext.Provider
                                                                                            value={
                                                                                              questionBank
                                                                                            }
                                                                                          >
                                                                                            <QuestionBankUpdateContext.Provider
                                                                                              value={
                                                                                                updateQuestionBank
                                                                                              }
                                                                                            >
                                                                                              <AssignedCampaignsContext.Provider
                                                                                                value={
                                                                                                  assignedCampaigns
                                                                                                }
                                                                                              >
                                                                                                <AssignedCampaignsUpdateContext.Provider
                                                                                                  value={
                                                                                                    updateAssignedCampaigns
                                                                                                  }
                                                                                                >
                                                                                                  <TangoAccountContext.Provider
                                                                                                    value={
                                                                                                      tangoAccount
                                                                                                    }
                                                                                                  >
                                                                                                    <TangoAccountUpdateContext.Provider
                                                                                                      value={
                                                                                                        updateTangoAccount
                                                                                                      }
                                                                                                    >
                                                                                                      {
                                                                                                        children
                                                                                                      }
                                                                                                    </TangoAccountUpdateContext.Provider>
                                                                                                  </TangoAccountContext.Provider>
                                                                                                </AssignedCampaignsUpdateContext.Provider>
                                                                                              </AssignedCampaignsContext.Provider>
                                                                                            </QuestionBankUpdateContext.Provider>
                                                                                          </QuestionBankContext.Provider>
                                                                                        </OwnQuestionsUpdateContext.Provider>
                                                                                      </OwnQuestionsContext.Provider>
                                                                                    </InviteEmailUpdateContext.Provider>
                                                                                  </InviteEmailContext.Provider>
                                                                                </ReminderEmailUpdateContext.Provider>
                                                                              </ReminderEmailContext.Provider>
                                                                            </AskEmailUpdateContext.Provider>
                                                                          </AskEmailContext.Provider>
                                                                        </EmailFrameUpdateContext.Provider>
                                                                      </EmailFrameContext.Provider>
                                                                    </CompanyNameUpdateContext.Provider>
                                                                  </CompanyNameContext.Provider>
                                                                </TempRefreshUpdateContext.Provider>
                                                              </TempRefreshContext.Provider>
                                                            </UserUpdateContext.Provider>
                                                          </UserContext.Provider>
                                                        </SelectedPlanIdUpdateContext.Provider>
                                                      </SelectedPlanIdContext.Provider>
                                                    </SharedTestimonialUpdateContext.Provider>
                                                  </SharedTestimonialContext.Provider>
                                                </AnalyticsOverviewUpdateContext.Provider>
                                              </AnalyticsOverviewContext.Provider>
                                            </ClientsUpdateContext.Provider>
                                          </ClientsContext.Provider>
                                        </AnalyticsUpdateContext.Provider>
                                      </AnalyticsContext.Provider>
                                    </PlanUpdateContext.Provider>
                                  </PlanContext.Provider>
                                </NotificationsUpdateContext.Provider>
                              </NotificationsContext.Provider>
                            </WidgetlistUpdateContext.Provider>
                          </PlaylistUpdateContext.Provider>
                        </WidgetlistContext.Provider>
                      </PlaylistContext.Provider>
                    </RefreshUpdateContext.Provider>
                  </RefreshContext.Provider>
                </TestimonialChangeFlagUpdateContext.Provider>
              </TestimonialChangeFlagContext.Provider>
            </TestimonialDataUpdateContext.Provider>
          </TestimonialDataContext.Provider>
        </clearData.Provider>
      </ImageUpdateContext.Provider>
    </ImageContext.Provider>
  )
}
