import React, { useEffect } from "react"
//dependencies
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import ReactLoading from "react-loading"

function ModalFetching(props) {
  const { preloader, title, text } = props
  useEffect(() => {
    if (preloader) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [preloader])
  return (
    <Modal
      backdrop={props?.backdrop ?? "static"}
      keyboard={props?.keyboard ?? false}
      centered={props?.centered ?? false}
      isOpen={preloader}
      className="bg-transparent d-flex justify-content-center align-items-center"
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      {!!props?.toggle && (
        <ModalHeader
          toggle={props?.toggle}
          className="bg-transparent border-0"
        />
      )}
      <ModalBody
        className={`bg-transparent d-flex flex-row flex-wrap justify-content-center align-items-center gap-3 p-3 ${
          !!props?.toggle ? "pt-0" : "pt-4"
        }`}
      >
        {props?.reactLoading !== false && (
          <ReactLoading
            type="spin"
            color="#d5d8dc"
            height={30}
            width={30}
            className="d-flex justify-content-center align-items-center"
          />
        )}
        <h3 className="m-0">{title}</h3>
        {!!text && <p className="col-12">{text}</p>}
        {props?.body ? props.body : null}
      </ModalBody>
    </Modal>
  )
}

export default ModalFetching
