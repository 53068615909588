import React, { useEffect } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PaymentDetail from "pages/Subscription/paymentDetail"
import {
  paymentVerifiedFailure,
  paymentVerifiedSuccess,
} from "../store/actions"

function PaymentVerified(s) {
  const auth = useSelector(state => state.Auth.authUser)
  const paymentVerified = useSelector(state => state.Auth.paymentVerified)
  const token = useSelector(state => state.Auth.token)
  const plan = useSelector(state => state.Auth.authUser?.company?.plan)
  const error = useSelector(state => state.Auth.error)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!auth || !token) return
    if (auth?.company?.plan) {
      dispatch(paymentVerifiedSuccess())
    } else {
      dispatch(paymentVerifiedFailure())
    }
  }, [error, auth, token, plan])

  return localStorage.getItem("authUser") &&
    !JSON.parse(localStorage.getItem("authUser"))?.user?.email_verified_at &&
    token ? (
    <Navigate to="/verifyemail" />
  ) : error == "payment error" || paymentVerified == false ? (
    <PaymentDetail />
  ) : (
    <Outlet />
  )
}

export default PaymentVerified
