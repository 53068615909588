// reducer.js
import {
  FETCH_EMAIL_BODIES_REQUEST,
  FETCH_EMAIL_BODIES_SUCCESS,
  SET_FIRST_EMAIL_BODY,
  FETCH_EMAIL_BODIES_FAILURE,
  SET_OnBording_EMAIL_BODY,
  SET_EMAIL_CADENCE,
} from "./actionTypes"

const initialState = {
  allEmailBodies: null,
  firstEmailBody: null,
  error: null,
  loading: false,
}

const emailBodiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMAIL_BODIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case SET_OnBording_EMAIL_BODY:
      let allMails = action.payload
      let firstmailBody = action.payload

      return {
        ...state,
        allEmailBodies: allMails,
        firstEmailBody: firstmailBody,
        error: null,
        loading: false,
      }

    case SET_EMAIL_CADENCE:
      return {
        ...state,
        firstEmailBody: {
          ...state.firstEmailBody,
          reminderInterval: parseInt(action.payload.reminderInterval),
          totalReminders: parseInt(action.payload.totalReminders),
        },
        error: null,
        loading: false,
      }

    case FETCH_EMAIL_BODIES_SUCCESS:
      let fistEmailBody = action.payload.emails[0]
      let allEmails = action.payload.emails[0]

      return {
        ...state,
        allEmailBodies: allEmails,
        firstEmailBody: fistEmailBody,
        error: null,
        loading: false,
      }
    case SET_FIRST_EMAIL_BODY:
      let updatedFirstEmailBody = { ...state.firstEmailBody }

      for (let key in action.payload) {
        if (updatedFirstEmailBody.hasOwnProperty(key)) {
          updatedFirstEmailBody[key] = action.payload[key]
        }
      }

      return {
        ...state,
        firstEmailBody: updatedFirstEmailBody,
      }
    case FETCH_EMAIL_BODIES_FAILURE:
      return {
        ...state,
        allEmailBodies: null,
        error: "An error occurred while fetching email bodies.",
        loading: false,
      }
    default:
      return state
  }
}

export default emailBodiesReducer
