//imports
import React, { lazy } from "react"
// Profile
const UserProfile = lazy(() => import("../pages/Authentication/user-profile"))
const Settings = lazy(() => import("../pages/Profile/Settings"))
// Dashboard
//Manage Pages
const ManageUser = lazy(() =>
  import("../pages/Dashboard/Manage/UserManagePage")
)
//Analytics Page
const Analytics = lazy(() => import("../pages/Dashboard/Analytics/Analytics"))
//Support Page
const Support = lazy(() => import("../pages/Dashboard/Support/Support"))
//Editing Page
const VideoEdit = lazy(() => import("../pages/videoEdit"))
//Library Page
const Library = lazy(() => import("../pages/Dashboard/Library/Library"))
//Advance Search
const AdvanceSearch = lazy(() => import("../pages/Dashboard/AdvanceSearch"))
//Invite Page
const Invite = lazy(() => import("../pages/Dashboard/Invite/Invite"))
//Welcome Page
const Welcome = lazy(() => import("../pages/Dashboard/Welcome/Welcome"))

//Subscriber Dashboard
const SubscriberDashboard = lazy(() =>
  import("../pages/Dashboard/SubscriberDashboard/SubscriberDashboard")
)
const LinkedInCallback = lazy(() =>
  import("../pages/Dashboard/Manage/Socials/LinkedIn/CallbackURI")
)

//Routes
const userRoutes = [
  //Welcome
  { path: "welcome", element: <Welcome /> },
  //Manage
  { path: "manage", element: <ManageUser /> },
  { path: "linkedInReturn", element: <LinkedInCallback /> },
  //Support
  { path: "support", element: <Support /> },
  //Library
  { path: "library", element: <Library /> },
  //AdvanceSearch
  { path: "advance-search", element: <AdvanceSearch /> },
  //Analytics
  { path: "analytics", element: <Analytics /> },
  //Invite
  { path: "invites", element: <Invite /> },
  //Editing
  { path: "video-edit", element: <VideoEdit /> },
  //Dashboard
  { path: "dashboard", element: <SubscriberDashboard /> },
  //Sidebar
  //User Profile
  { path: "profile", element: <UserProfile /> },
  //User Settings
  { path: "settings", element: <Settings /> },
]

export const userPermission = [2, 3, 4]

export default userRoutes
