import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  INIT_AUTH,
  INIT_AUTH_SUCCESS,
  INIT_AUTH_FAILURE,
  LOGIN_ADMIN,
  EMAIL_VERIFIED_SUCCESS,
  EMAIL_VERIFIED_FAILURE,
  PAYMENT_VERIFIED_SUCCESS,
  PAYMENT_VERIFIED_FAILURE,
  INIT_ADMIN_AUTH,
  INIT_ADMIN_AUTH_FAILURE,
  INIT_ADMIN_AUTH_SUCCESS,
  SWITCH_USER,
  LOGOUT_ADMIN_SUCCESS,
  LOGOUT_MULTI_SUCCESS,
  SWITCH_COMPANY,
  SWITCH_USER_SUCCESS,
  SWITCH_AGENCY,
} from "./actionTypes"

//Login User

export const loginUser = user => {
  return {
    type: LOGIN_USER,
    payload: user,
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}
// INIT AuthUser
export const initAuth = token => {
  return {
    type: INIT_AUTH,
    payload: token,
  }
}
// INIT AuthUser Success
export const initAuthSuccess = authUser => {
  return {
    type: INIT_AUTH_SUCCESS,
    payload: authUser,
  }
}
// INIT AuthUser Failure
export const initAuthFailure = error => {
  return {
    type: INIT_AUTH_FAILURE,
    payload: error,
  }
}
export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
    payload: {},
  }
}
//logut Success
export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

//Admin Login
export const loginAdmin = res => {
  return {
    type: LOGIN_ADMIN,
    payload: res,
  }
}

export const initAdminAuth = token => {
  return {
    type: INIT_ADMIN_AUTH,
    payload: token,
  }
}

export const initAdminAuthSuccess = user => {
  return {
    type: INIT_ADMIN_AUTH_SUCCESS,
    payload: user,
  }
}

export const initAdminAuthFailure = error => {
  return {
    type: INIT_ADMIN_AUTH_FAILURE,
    payload: error,
  }
}

export const logoutAdminSuccess = () => {
  return {
    type: LOGOUT_ADMIN_SUCCESS,
    payload: {},
  }
}

export const logoutMultiSuccess = () => {
  return {
    type: LOGOUT_MULTI_SUCCESS,
    payload: {},
  }
}
//Middleware

export const emailVerifiedSuccess = () => {
  return {
    type: EMAIL_VERIFIED_SUCCESS,
    payload: {},
  }
}

export const emailVerifiedFailure = () => {
  return {
    type: EMAIL_VERIFIED_FAILURE,
    payload: {},
  }
}

export const paymentVerifiedSuccess = () => {
  return {
    type: PAYMENT_VERIFIED_SUCCESS,
    payload: {},
  }
}

export const paymentVerifiedFailure = () => {
  return {
    type: PAYMENT_VERIFIED_FAILURE,
    payload: {},
  }
}

// Switch User
export const switchUserAction = token => {
  return {
    type: SWITCH_USER,
    payload: token,
  }
}

export const switchUserSuccess = user => {
  return {
    type: SWITCH_USER_SUCCESS,
    payload: user,
  }
}

//Switch Company
export const switchCompanyAction = token => {
  return {
    type: SWITCH_COMPANY,
    payload: token,
  }
}

//Switch Agency
export const switchAgencyAction = data => {
  return {
    type: SWITCH_AGENCY,
    payload: data,
  }
}

/////////// Add the following action ///////////

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
