import React, { useEffect } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { useSelector } from "react-redux"

function NonPaymentVerified() {
  const auth = useSelector(state => state.Auth)

  return (!JSON.parse(localStorage.getItem("authUser"))?.user
    ?.email_verified_at ||
    auth.error == "payment error") &&
    localStorage.getItem("authUser") &&
    auth.token ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  )
}

export default NonPaymentVerified
