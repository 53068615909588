import React from "react"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"

export const toTimeString = (sec, showMilliSeconds = true) => {
  sec = parseFloat(sec)
  let hours = Math.floor(sec / 3600) // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60) // get minutes
  let seconds = sec - hours * 3600 - minutes * 60 //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) {
    hours = "0" + hours
  }
  if (minutes < 10) {
    minutes = "0" + minutes
  }
  if (seconds < 10) {
    seconds = "0" + seconds
  }
  let maltissaRegex = /\..*$/ // matches the decimal point and the digits after it e.g if the number is 4.567 it matches .567

  let millisec = String(seconds).match(maltissaRegex)
  return (
    hours +
    ":" +
    minutes +
    ":" +
    String(seconds).replace(maltissaRegex, "") +
    (showMilliSeconds ? (millisec ? millisec[0] : ".000") : "")
  )
}

export const readFileAsBase64 = async file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export const cameraPermissionAlert = () => {
  const MySwal = withReactContent(Swal)
  MySwal.fire({
    title: "Camera permission denied or an error occurred",
    html: <i>Please allow camera and microphone access in your browser</i>,
    icon: "info",
    timer: 3500,
    timerProgressBar: true,
  })
}

export function handleCloseWindow() {
  window.open("about:blank", "_self")
  window.close()
}

import { isAndroid } from "react-device-detect"
export const NativeSupport = isAndroid ? true : false

//Remove Token from Local Storage
export const removeToken = () => {
  localStorage.removeItem("token")
  //dispatch local storage event
  window.dispatchEvent(new Event("storage"))
}

export const refreshSession = token => {
  if (token) localStorage.setItem("token", token)
  window.dispatchEvent(new Event("storage"))
}

export const closeSession = () => {
  removeToken()
}

export const closeAdminSession = () => {
  localStorage.removeItem("superToken")
  window.dispatchEvent(new Event("storage"))
}
