import React from "react"
import { Container, Row, Col } from "reactstrap"
import error from "../../../assets/images/error-img.png"

function SessionExpired401() {
  document.title = "MahaloHub | 401 Page Not Found"
  return (
    <div className="account-pages my-5 pt-5">
      <Container>
        <Row>
          <Col lg="12">
            <div className="text-center mb-5">
              <h1 className="display-2 fw-medium">
                44
                <i className="bx bx-buoy bx-spin text-primary display-3" />
              </h1>
              <h4 className="text-uppercase">Session Expired</h4>
              <div className="mt-5 text-center">
                <p className="h5">Please refresh the page to continue.</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8" xl="6">
            <div>
              <img src={error} alt="" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SessionExpired401
