import address from "services/address"
import axios from "axios"

const axiosApi = axios.create({
  baseURL: address,
})

export async function switchUser(id) {
  const { data } = await axiosApi.post(
    `/api/admin/subscriber/login`,
    {
      companyId: id,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("superToken")}`,
      },
    }
  )

  return data
}

export async function switchCompany(id) {
  const { data } = await axiosApi.get(
    `api/agency/company/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },
    {}
  )
  return data
}
