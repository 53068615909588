import React, { useState, useEffect, useRef } from "react"
//Dependencies
import {
  Modal,
  Button,
  Form,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
} from "reactstrap"
import Swal from "sweetalert2"
import ReactLoading from "react-loading"
import * as Yup from "yup"
import { useFormik } from "formik"
import axios from "axios"
import { Tooltip as ReactTooltip } from "react-tooltip"
//services
import { sendAskEmail } from "services/Emails/Email"
import { getEmailBody } from "services/Emails/Email"
//context redux
import { fetchEmailBodiesSuccess } from "../../../store/Emails/actions"
import {
  useRefreshUpdate,
  useRefresh,
  useImage,
  useUser,
} from "../ImageContextProvider"
import { useDispatch, useSelector } from "react-redux"
//assets
import "../Sidebar.css"

const InviteModal = props => {
  //useImports
  const user = useUser()
  const dispatch = useDispatch()
  //Redux
  const allEmailBodies = useSelector(state => state.Emails.firstEmailBody)
  //states

  const [isModalOpen, setIsModalOpen] = useState(false)
  const inviteBtn = "inviteBtn"
  const toggleModal = () => {
    setIsModalOpen(prevIsModalOpen => !prevIsModalOpen)
  }
  const [customActiveTab, setcustomActiveTab] = useState("1")
  //Use context
  const contextImage = useImage()
  const [refresh, setRefresh] = useState(false)
  const updateRefresh = useRefreshUpdate()
  const Refresh = useRefresh()
  const [modal_invite, setmodal_invite] = useState(false)
  const [invited, setInvited] = useState([])
  const [isEditing, setIsEditing] = useState(false)
  const [isUser, setIsUser] = useState(localStorage.getItem("isUser"))
  const [image, setImage] = useState()
  const [askMailPreviewModal, setAskModalPreview] = useState(false)
  const [emailFlage, setEmailFlage] = useState(false)

  const company = user?.activeCampaign?.companyName
  const [clientName, setClientName] = useState("")

  const [declinedClients, setDeclinedClients] = useState([])
  const [bodyText, setBodyText] = useState(
    allEmailBodies
      ? allEmailBodies?.askEmailBody?.replace(/<br\s*\/?>/gm, "\n")
      : ""
  )
  const [recoveredBodyText, setRecoveredBodyText] = useState(
    allEmailBodies
      ? allEmailBodies?.askEmailBody?.replace(/<br\s*\/?>/gm, "\n")
      : ""
  )

  const textareaRef = useRef(null)
  useEffect(() => {
    if (isEditing && textareaRef.current) {
      textareaRef.current.style.height = "auto"
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [isEditing, bodyText])

  useEffect(() => {
    if (!user) return
    let source = axios.CancelToken.source()

    const fetchEmailTemplates = async () => {
      try {
        await getEmailBody({
          cancelToken: source.token,
        }).then(res => {
          dispatch(fetchEmailBodiesSuccess(res.data))
          setBodyText(res.data.askEmailBody?.replace(/<br\s*\/?>/gm, "\n"))
        })
      } catch (e) {
        if (axios.isCancel(e)) {
          // console.log("unmounted")
        }
      }
    }
    fetchEmailTemplates()

    return () => {
      source.cancel()
    }
  }, [user])
  useEffect(() => {
    if (allEmailBodies) {
      setBodyText(allEmailBodies?.askEmailBody?.replace(/<br\s*\/?>/gm, "\n"))
      setRecoveredBodyText(
        allEmailBodies?.askEmailBody?.replace(/<br\s*\/?>/gm, "\n")
      )
    }
  }, [allEmailBodies])

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleSaveClick = () => {
    setIsEditing(false)
  }

  const handleBodyChange = event => {
    setBodyText(event.target.value)
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_askPreview() {
    setAskModalPreview(!askMailPreviewModal)
    removeBodyCss()
  }
  function tog_invite() {
    setmodal_invite(!modal_invite)
    removeBodyCss()
  }
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  const handleInputChange = e => {
    validation.handleChange(e)
    setClientName(e.target.value)

    // setClientName(e.nativeEvent.srcElement.defaultValue)
    // add your second onChange function here
  }
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      Name: "",
      email: "",
    },
    validationSchema: Yup.object({
      Name: Yup.string().required("Please Enter Full Name"),
      email: Yup.string().required("Please Enter a Valid Email"),
    }),
    onSubmit: values => {
      setEmailFlage(true)
      sendAskEmail({
        fullName: values.Name,
        email: values.email,
        askEmailBody: bodyText?.replace(/\n/g, "<br />"),
      }).then(res => {
        setEmailFlage(false)
        if (res.success == false) {
          Swal.fire(
            "Error",
            "Invitation already has been sent to this client",
            "error"
          )
        } else {
          Swal.fire("Success", "Customer Invited Successfully", "success")
          values.email = ""
          values.Name = ""
          //Reset
          setClientName("")
          setBodyText(recoveredBodyText)
          setIsEditing(false)
          resetForm()
          updateRefresh(!Refresh)
        }
      })
    },
  })

  const resetForm = () => {
    setClientName("")
    setBodyText(allEmailBodies?.askEmailBody?.replace(/<br\s*\/?>/gm, "\n"))
    setIsEditing(false)
    toggleModal()
  }

  return (
    <React.Fragment key={"inviteModal"}>
      <div className=" d-lg-inline-block ms-2">
        <ReactTooltip
          id={`rejectTooltip`}
          anchorId={inviteBtn}
          place="bottom"
          variant="info"
          content="Invite Customer"
        />
        <span id={inviteBtn}>
          <button
            type="button"
            onClick={toggleModal}
            className="btn header-item noti-icon p-1 p-sm-2 p-md-3"
          >
            <i
              className="bx bx-user-plus"
              style={{ color: "#41c7f2", fontSize: "25px" }}
            ></i>
          </button>
        </span>
      </div>
      <Modal
        style={{ overflowY: "hidden", padding: "0px" }}
        isOpen={isModalOpen}
        centered
        size="lg"
        toggle={() => {
          setClientName("")
          setBodyText(recoveredBodyText)
          setIsEditing(false)
          // toggleModal()
          resetForm()
        }}
      >
        <div className="modal-header">
          <h5
            className="modal-title mt-0"
            id="myModalLabel"
            style={{ fontWeight: "bolder" }}
          >
            Send a Request
          </h5>
          <button
            type="button"
            onClick={() => {
              setClientName("")
              setBodyText(recoveredBodyText)
              setIsEditing(false)
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
            }}
          >
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">
                    <b> Full Name</b>
                  </Label>
                  <Input
                    id="Name"
                    name="Name"
                    className="form-control"
                    placeholder="Enter Customer Full Name"
                    type="text"
                    onChange={handleInputChange}
                    value={validation.values.Name || ""}
                    invalid={
                      validation.touched.Name && validation.errors.Name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.Name && validation.errors.Name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.Name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">
                    <b> Email</b>
                  </Label>
                  <Input
                    name="email"
                    type="email"
                    placeholder="Enter Customer Email Address"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <br />

            <div className="mb-3">
              <Label className="form-label">
                <b>Hi {clientName.split(" ")[0]},</b>
              </Label>
              <Button
                className="btn-mahalo"
                size="sm"
                style={{ float: "right", marginTop: "-20px" }}
                onClick={isEditing ? handleSaveClick : handleEditClick}
              >
                {isEditing ? "Save" : "Customize"}
              </Button>
              <br />

              {isEditing ? (
                <div style={{ width: "100%" }}>
                  <textarea
                    ref={textareaRef}
                    value={bodyText}
                    onChange={handleBodyChange}
                    style={{
                      width: "100%",
                      overflow: "hidden",
                    }}
                  />
                </div>
              ) : (
                <div>
                  {bodyText?.split("\n").map((text, index) => (
                    <React.Fragment key={index}>
                      {text}
                      {index !== bodyText?.split("\n").length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>

            <div></div>
            <div id="wrong" style={{ color: "red" }}></div>

            <div className="mt-4 d-grid">
              <div className="d-flex justify-content-between">
                <div>
                  <Button
                    className="btn-rounded"
                    onClick={() => {
                      if (isEditing) {
                        setBodyText(recoveredBodyText)
                        setIsEditing(false)
                      } else {
                        setClientName("")
                        setBodyText(recoveredBodyText)
                        setIsEditing(false)
                        toggleModal()
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  {emailFlage ? (
                    <ReactLoading
                      type={"spin"}
                      color={"blue"}
                      height={35}
                      width={35}
                    />
                  ) : (
                    <>
                      {!isEditing ? (
                        <>
                          <Button
                            className="btn-mahalo-green btn-rounded"
                            onClick={() => {
                              tog_askPreview()
                              toggleModal()
                            }}
                          >
                            Preview
                          </Button>{" "}
                          <Button
                            className="btn-mahalo btn-rounded"
                            type="submit"
                            id="sendbtn"
                            style={{ padding: "7.5px 20px" }}
                            disabled={!user ? true : false}
                          >
                            Send
                          </Button>
                        </>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
      <Modal
        isOpen={askMailPreviewModal}
        centered
        size="lg"
        toggle={() => {
          tog_askPreview()
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5
            className="modal-title mt-0"
            id="myModalLabel"
            style={{ fontWeight: "bolder" }}
          >
            Request Mail Preview
          </h5>
          <button
            type="button"
            onClick={() => {
              setAskModalPreview(false)
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            <b>Subject: {company} Invites You To Share Your Experience</b>
          </p>
          <div className="d-flex justify-content-center">
            <img
              src={contextImage}
              alt=""
              className="img-fluid rounded"
              style={{
                maxWidth: "250px",
                maxHeight: "100px",
                padding: "5px",
              }}
            />
          </div>
          <p>Hi {clientName.split(" ")[0]},</p>

          <div>
            {bodyText?.split("\n").map((text, index) => (
              <React.Fragment key={index}>
                {text}
                {index !== bodyText?.split("\n").length - 1 && <br />}
              </React.Fragment>
            ))}
          </div>

          {/* <p>{username.user.fullName===undefined?(username.fullName):username.user.fullName}</p> */}
          <div className="d-flex justify-content-center">
            <Button
              className="mahalo-soft-green border-none btn-rounded"
              onClick={() => {}}
            >
              Yes, Absolutely
            </Button>
            &nbsp;
            <Button
              className="mahalo-soft-red border-none btn-rounded"
              onClick={() => {}}
            >
              Not Interested
            </Button>
            &nbsp;
            <Button
              className="mahalo-soft-blue border-none btn-rounded"
              onClick={() => {}}
            >
              Maybe Later
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default InviteModal
