import actionTypes from "./actionTypes"

export const setSearch = search => ({
  type: actionTypes.SET_SEARCH,
  payload: search,
})

export const getAdvanceSearch = search => ({
  type: actionTypes.GET_ADVANCE_SEARCH,
  payload: search,
})

export const getAdvanceSearchSuccess = search => ({
  type: actionTypes.GET_ADVANCE_SEARCH_SUCCESS,
  payload: search,
})

export const getAdvanceSearchFailure = search => ({
  type: actionTypes.GET_ADVANCE_SEARCH_FAILURE,
  payload: search,
})
export const getAdvanceSearchLoading = () => ({
  type: actionTypes.GET_ADVANCE_SEARCH_LOADING,
})
export const resetSearch = () => ({
  type: actionTypes.RESET_SEARCH,
})

const actions = {
  setSearch,
  getAdvanceSearch,
  getAdvanceSearchSuccess,
  getAdvanceSearchFailure,
  getAdvanceSearchLoading,
  resetSearch,
}
export default actions
