import React, { useState, useEffect } from "react"
//Component
//dependencies
import { Modal, Button, Form, FormGroup, Table, Alert } from "reactstrap"
import { useNavigate } from "react-router-dom"
import ReactDragListView from "react-drag-listview"
import ReactLoading from "react-loading"

//contextApi
import { useUser } from "components/VerticalLayout/ImageContextProvider"
//services
import { updateProfile } from "../../services/User/User"
import {
  getQuestions,
  addQuestion,
  updateQuestion,
  getUserQuestions,
  deleteQuestion,
  getQuestionBank,
} from "services/Questions/Questions"
import { setEmailBody } from "services/Emails/Email"

//Assets
import Logo from "../../assets/images/Site Logos/Logo.png"
import Toast from "services/toast"
import { setOnBordingBody } from "store/actions"
import { useDispatch } from "react-redux"
import { min } from "lodash"

function OnBoarding(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  //context
  const user = useUser()
  //data
  let userId = user?.id

  if (userId == undefined) {
    userId = user?.user?.id
  }
  //Boarding Control
  const [isFresh, setIsFresh] = useState(localStorage.getItem("isFresh"))
  const [modal_onboarding, setmodal_onboarding] = React.useState(
    isFresh == "1" || isFresh == "0"
  )
  useEffect(() => {
    //if isFresh==2
    const onLoad = () => {
      setIsFresh(localStorage.getItem("isFresh"))
    }
    window.addEventListener("storage", onLoad)
    if (localStorage.getItem("isFresh") == 1) {
      setmodal_onboarding(true)
    } else {
      setmodal_onboarding(false)
    }
  }, [isFresh])
  //states
  const [company, setCompany] = useState([])
  const [userdetails, setUserDetails] = useState([])
  const [templateQuestions, setTemplateQuestions] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [pgNo, setPgNo] = React.useState(1)
  const [selectedQuestions, setSelectedQuestions] = useState([])
  const [defaultChecked1, setDefaultChecked] = useState([])
  const [data, SetData] = useState([])
  const [ownQuestions, SetOwnQuestions] = useState([])
  const [addMoreFlage, setAddMoreFlage] = useState(false)
  const [nextClicked, setNextClicked] = useState(false)
  const [subComName, setSubComName] = useState("")
  const [inputFields, setInputFields] = useState([{ value: "" }])
  const [preloader, setPreloader] = useState(true)
  const [confettiDisplay, setConfettiDisplay] = useState(false)
  const [addInputFields, setAddInputFields] = useState([{ value: "" }])
  const [addMoreField, setAddMoreField] = useState("")
  //
  const [inviteBody, setInviteBody] = useState(
    "Thanks so much for agreeing to record your experience with us in a video testimonial! We really appreciate it.<br /><br />The process is very simple and will guide you step-by-step. It will not take longer than 10 minutes. When you’re ready, click the button below and it will fire up the recording session. <br /><br /> With gratitude."
  )
  const [askbody, setAskBody] = useState("")
  const [reminderBody, setReminderBody] = useState("")
  //Handlers
  const handleCheckboxChange = async event => {
    const value = event.target.value
    if (event.target.checked) {
      if (selectedQuestions.includes(value)) {
        setSelectedQuestions(selectedQuestions.filter(q => q !== value))
        setDefaultChecked(selectedQuestions.filter(q => q !== value))
      } else {
        setSelectedQuestions([...selectedQuestions, value])
        setDefaultChecked([...defaultChecked1, value])
      }
    } else {
      setSelectedQuestions(selectedQuestions.filter(q => q !== value))
      setDefaultChecked(defaultChecked1.filter(q => q !== value))
      setLoading(true)
      Promise.all(
        ownQuestions
          .filter(q => q.text == value)
          .map(async question => await deleteQuestion(question.id))
      )
        .then(responses => {
          SetOwnQuestions(ownQuestions.filter(q => q.text !== value))
          setSelectedQuestions(selectedQuestions.filter(q => q !== value))
          setRefresh(!refresh)
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const handleAddFields = () => {
    const values = [...inputFields]
    values.push({ value: "" })
    setAddInputFields(values)
    setInputFields(values)
  }
  const handleRemoveFields = index => {
    if (index >= 0) {
      const values = [...inputFields]
      values.splice(index, 1)
      setInputFields(values)
      setAddInputFields(values)
    }
  }
  const handleAddMoreField = event => {
    setAddMoreField(event.target.value)
  }
  const handleInputChange = (index, event) => {
    const values = [...inputFields]
    values[index].value = event.target.value
    setAddInputFields(values)
    setInputFields(values)
  }
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = [...ownQuestions]

      const item = modifiedData.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      SetOwnQuestions(modifiedData)
      modifiedData.map((item, index) => {
        updateQuestion(item.id, {
          text: item.text,
          preference: index,
        }).then(res => {})
      })
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }

  //Core Functions
  function tog_backdrop() {
    setmodal_onboarding(!modal_onboarding)
  }

  const [loading, setLoading] = useState()
  //Effects
  useEffect(() => {
    if (modal_onboarding) {
      if (!user) return
      ;(async () => {
        if (user != undefined && user.company != undefined) {
          setAskBody(
            `You have a great story to tell about ${user.activeCampaign.companyName}, and we’d love to be able to share it with our community in a short testimonial video. This would help other potential customers understand what they can expect working with us.<br /><br /> Would you be open to recording a video about your experience with ${user.activeCampaign.companyName}?<br /><br /> We’re collecting customer videos using a tool called MahaloHub – it’s super easy and will only take 5-10 minutes. We will ask 4 or 5 questions, and you can record from your phone or laptop whenever you’re ready.<br /> <br /> We want to share authentic stories of real people like you, and will use these videos for our website, social media, email campaigns, etc.<br /><br /> Regardless of your response, we greatly value our relationship with you.<br /><br /> With gratitude.`
          )

          setReminderBody(
            `I know you are busy so wanted to touch base to see when you might be able to carve out 5-10 mins to record a short video.<br /><br />Here's the link to record your video - you can record any time it’s convenient for you from your phone or laptop.<br /><br /> With gratitude.`
          )

          setUserDetails(user)
          setCompany({
            ...user.company,
            name: user.activeCampaign.companyName,
          })
        }
        // Old Company Name
        localStorage.setItem("comN", user.activeCampaign.companyName)
        setSubComName(user.activeCampaign.companyName)

        // Get User Questions
        if (pgNo == 1 || pgNo == 3) {
          console.log("pgNo", pgNo)
          setLoading(true)
          getUserQuestions(user.id)
            .then(res => {
              const sortedQuestions = res.subscriberQuestions.sort((a, b) => {
                return a.preference - b.preference
              })
              setAddMoreFlage(false)
              SetOwnQuestions(sortedQuestions)
              if (nextClicked == true) {
                setAddMoreFlage(false)
                setPgNo(pgNo + 1)
                setNextClicked(false)
              }
            })
            .catch(error => {
              console.error(error)
            })
            .finally(() => {
              setLoading(false)
            })
        } else if (nextClicked == true) {
          setAddMoreFlage(false)
          setPgNo(pgNo + 1)
          setNextClicked(false)
        }

        //Get Template Questions
        if (!(templateQuestions?.length > 0)) {
          await getQuestionBank().then(res => {
            if (!res || !res?.success) return
            setPreloader(false)
            const generalQuestions = res.data.categories.filter(
              category => category.id === 1
            )[0].questions
            const modifiedQuestions = generalQuestions.map(question => {
              if (question.text.includes("[company]")) {
                return {
                  ...question,
                  text: question.text.replace(
                    "[company]",
                    user.activeCampaign.companyName
                  ),
                }
              } else {
                return question
              }
            })

            setTemplateQuestions(modifiedQuestions)
            SetData(modifiedQuestions)
          })
        }
      })()
    }
    // }
  }, [refresh, user, pgNo])

  return (
    <>
      <Modal
        className="overflow-auto"
        isOpen={
          modal_onboarding && window.location.href.includes("/user/welcome")
        }
        toggle={() => {
          tog_backdrop()
        }}
        keyboard={false}
        centered
        backdrop={"static"}
        id="staticBackdrop"
        style={{
          width: pgNo == 0 ? "800px" : "600px",
          maxWidth: "93vw",
          transition: "width 0.5s ease",
        }}
      >
        <div className="modal-header" style={{ background: "#dff5f0" }}>
          <img
            src={Logo}
            alt=""
            height="32"
            style={{ display: "block", margin: "0 auto" }}
          />
        </div>
        <div
          className="modal-body bg-white"
          // style={{ background: "#f7fcfb" }}
        >
          {pgNo === 1 && (
            <div>
              <h5
                className="center text-center m-0 p-0"
                style={{ fontWeight: "bolder" }}
              >
                Welcome to MyMahaloHub!
              </h5>
              <h5
                className="center text-center m-0 p-0 py-3 mt-0"
                style={{ lineHeight: "1.7rem" }}
              >
                This is your control center where you get set-up, manage
                invitations, review and edit videos, share to your preferred
                channels, manage rewards, and track analytics
              </h5>

              {/* <p className="center">Lets get you started</p> */}
            </div>
          )}
          {pgNo === 2 && (
            <>
              {preloader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20vh",
                  }}
                >
                  <ReactLoading
                    type="spinningBubbles"
                    color="#234256"
                    height={250}
                    width={100}
                  />
                </div>
              ) : (
                <div>
                  <h5 style={{ fontSize: "1.1rem" }}>
                    What questions would you like your customers to answer?
                  </h5>
                  <p style={{ fontSize: "0.9rem" }}>
                    Here are some suggestions. We recommend five, but you can go
                    up to 10.
                  </p>
                  {ownQuestions.length + selectedQuestions.length >= 10 && (
                    <Alert color="info">
                      You have reached the maximum number of questions
                    </Alert>
                  )}
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                    }}
                  >
                    <FormGroup
                      className="mb-4 overflow-auto "
                      style={{ maxHeight: "46vh" }}
                      row
                    >
                      <div className="pt-3" style={{ background: "white" }}>
                        {templateQuestions.map((question, index) => (
                          <div key={index} style={{ wordSpacing: "3px" }}>
                            <label className="d-flex">
                              <div>
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  style={{
                                    border: "2px solid",
                                  }}
                                  disabled={
                                    (defaultChecked1.includes(question.text) ||
                                    ownQuestions.some(
                                      q => q.text === question.text
                                    )
                                      ? false
                                      : ownQuestions.length +
                                          selectedQuestions.length >=
                                        10) || loading
                                  }
                                  onChange={e => handleCheckboxChange(e)}
                                  value={question.text}
                                  defaultChecked={
                                    defaultChecked1.includes(question.text)
                                      ? true
                                      : ownQuestions.some(
                                          q => q.text === question.text
                                        )
                                  }
                                />
                              </div>
                              &nbsp;
                              <div className="h5">{question.text}</div>
                            </label>
                          </div>
                        ))}
                      </div>
                    </FormGroup>
                  </Form>
                  <p>
                    <b> Add your Own:</b>
                  </p>

                  {inputFields.map((field, index) => (
                    <div
                      key={index}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <button
                        onClick={() => {
                          if (
                            ownQuestions.length + selectedQuestions.length >=
                            10
                          )
                            return Toast.fire({
                              icon: "info",
                              title: "You can add only 10 questions",
                            })
                          if (field?.value?.trim() == "") return
                          handleAddFields(index)
                        }}
                        style={{
                          border: "none",
                          fontSize: "20px",
                          background: "none",
                          marginRight: "5px",
                        }}
                      >
                        +
                      </button>
                      <input
                        type="text"
                        className="form-control"
                        value={field.value}
                        onChange={event => handleInputChange(index, event)}
                        style={{ width: "87%", marginBottom: "2px" }}
                      />
                      {index > 0 && (
                        <button
                          onClick={() => handleRemoveFields(index)}
                          style={{
                            border: "none",
                            fontSize: "20px",
                            background: "none",
                            marginRight: "5px",
                          }}
                        >
                          -
                        </button>
                      )}
                      {index === 0 && inputFields.length > 1 && (
                        <button
                          onClick={() => handleRemoveFields(index)}
                          style={{
                            border: "none",
                            fontSize: "20px",
                            background: "none",
                            marginRight: "5px",
                          }}
                        >
                          -
                        </button>
                      )}
                      <br />
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
          {pgNo === 3 && (
            <div>
              <h5 style={{ fontSize: "1.1rem" }}>
                Review and reorder your Question
              </h5>
              <div
                className="table-responsive overflow-auto"
                style={{ background: "white", maxHeight: "46vh" }}
              >
                <ReactDragListView {...dragProps}>
                  <Table className="table mb-0 ">
                    <thead>
                      <tr>
                        <th style={{ fontSize: "0.9rem" }}>Question</th>
                        <th
                          style={{ fontSize: "0.9rem" }}
                          className="text-sm-end"
                        >
                          Reorder
                        </th>
                      </tr>
                    </thead>
                    {!loading && (
                      <tbody>
                        {(ownQuestions || []).map((item, index) => (
                          <tr key={index}>
                            <td className="h5" style={{ lineHeight: "1.5rem" }}>
                              {item.text}
                            </td>
                            <td
                              className="text-sm-end"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              &nbsp;
                              <i
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  padding: "0px",
                                }}
                                className="fas fa-bars"
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </Table>
                </ReactDragListView>
              </div>
              {loading && (
                <div className="d-flex align-items-center justify-content-center pt-2 ">
                  <ReactLoading
                    type={"spin"}
                    color={"var(--mahalo-primary)"}
                    height={25}
                    width={20}
                  />
                </div>
              )}
              <br />
              <p>
                <b> Add More:</b>
              </p>
              <div
                className="d-flex justify-content-center"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {addMoreFlage == false ? (
                  <button
                    type="button"
                    size="md"
                    style={{
                      border: "none",
                      fontSize: "20px",
                      background: "none",
                      marginRight: "5px",
                    }}
                    onClick={() => {
                      if (addMoreField?.trim() == "") return
                      setAddMoreFlage(true)
                      if (addMoreField !== "") {
                        addQuestion({
                          text: addMoreField,
                          status: "active",
                        }).then(res => {
                          setRefresh(!refresh)
                          setAddMoreField("")
                          if (!res?.success) {
                            Toast.fire({
                              icon: "info",
                              title: res?.message,
                            })
                          }
                        })
                      }
                    }}
                  >
                    +
                  </button>
                ) : (
                  <>
                    <ReactLoading
                      type={"spin"}
                      color={"blue"}
                      height={15}
                      width={15}
                    />
                    &nbsp;
                  </>
                )}
                <input
                  type="text"
                  className="form-control"
                  value={addMoreField}
                  onChange={event => handleAddMoreField(event)}
                  style={{ width: "80%", marginBottom: "2px" }}
                />
                &nbsp;
              </div>
            </div>
          )}
          {pgNo === 4 && (
            <div>
              <h5 className="text-center">
                <p>Fantastic! You’re on your way!</p>
                <p>
                  There are only a few more steps before you're ready to send
                  invitations.
                </p>
                <p>Let's do this!</p>
              </h5>
            </div>
          )}
        </div>
        {pgNo === 1 && (
          <div
            className="modal-footer d-flex justify-content-center"
            style={{ background: "#dff5f0" }}
          >
            <Button
              disabled={loading}
              style={{ fontSize: "1rem" }}
              className="btn-rounded btn-mahalo d-flex align-items-center justify-content-center"
              onClick={async () => {
                setPgNo(pgNo + 1)
                setRefresh(!refresh)
              }}
            >
              {loading ? (
                <span
                  className=" d-flex align-items-center justify-content-center"
                  style={{ minWidth: "90px" }}
                >
                  <ReactLoading
                    className="m-0 p-0"
                    type={"spin"}
                    color={"var(--mahalo-secondary)"}
                    height={25}
                    width={25}
                  />
                </span>
              ) : (
                "Get Started"
              )}
            </Button>
          </div>
        )}
        {pgNo == 2 && (
          <div
            className="modal-footer d-flex justify-content-end"
            style={{ background: "#dff5f0" }}
          >
            {addMoreFlage == false ? (
              <Button
                style={{
                  fontSize: "1rem",
                  background: "#02C6FF",
                  border: "none",
                  color: "white",
                }}
                onClick={() => {
                  setAddMoreFlage(true)
                  setLoading(true)
                  Promise.all(
                    selectedQuestions
                      .filter(question => !ownQuestions.includes(question))
                      .map(
                        async question =>
                          await addQuestion({
                            text: question,
                            status: "active",
                          })
                      )
                  )
                    .then(responses => {})
                    .catch(error => {
                      console.error(error)
                    })
                  Promise.all(
                    addInputFields
                      .filter(
                        fields =>
                          !ownQuestions.some(q => q.text === fields.value)
                      )
                      .map(async fields => {
                        if (!fields.value.trim() == "") {
                          await addQuestion({
                            text: fields.value,
                            status: "active",
                          })
                        }
                      })
                  ).then(response => {
                    setRefresh(!refresh)
                    setNextClicked(true)
                    setSelectedQuestions([])
                    setAddInputFields([])
                  })
                  setLoading(false)
                }}
                className="btn-rounded "
              >
                Next
              </Button>
            ) : (
              <ReactLoading
                type={"spin"}
                color={"blue"}
                height={35}
                width={35}
              />
            )}
          </div>
        )}
        {pgNo == 3 && (
          <div
            className="modal-footer d-flex justify-content-between"
            style={{ background: "#dff5f0" }}
          >
            <Button
              style={{
                fontSize: "1rem",
                background: "#02C6FF",
                border: "none",
                color: "white",
              }}
              onClick={() => {
                setPgNo(pgNo - 1)
              }}
              className="btn-rounded "
            >
              Back
            </Button>
            <Button
              style={{
                fontSize: "1rem",
                background: "#02C6FF",
                border: "none",
                color: "white",
              }}
              onClick={() => {
                setPgNo(pgNo + 1)
                setConfettiDisplay(true)
                setInputFields([{ value: "" }])
              }}
              className="btn-rounded "
            >
              Next
            </Button>
          </div>
        )}
        {pgNo === 4 && (
          <div
            className="modal-footer d-flex justify-content-between"
            style={{ background: "#dff5f0" }}
          >
            <Button
              style={{
                fontSize: "1rem",
                background: "#02C6FF",
                border: "none",
                color: "white",
              }}
              onClick={() => {
                setPgNo(pgNo - 1)
                setInputFields([{ value: "" }])
              }}
              className="btn-rounded "
            >
              Back
            </Button>
            <Button
              style={{
                fontSize: "1rem",
                background: "#02C6FF",
                border: "none",
                color: "white",
              }}
              onClick={() => {
                setConfettiDisplay(false)
                tog_backdrop()
                setPgNo(pgNo + 1)
                const formdata = new FormData()
                formdata.append("fullName", userdetails.user.fullName)
                formdata.append("email", userdetails.user.email)
                formdata.append("address", userdetails.user.address)
                formdata.append("webUrl", company.webUrl)
                formdata.append("companyName", company.name)
                formdata.append("isFresh", "2")
                formdata.append("aboutinfo", company.aboutInfo)
                formdata.append("phoneNo", company.phoneNo)
                formdata.append("contactPersonName", company.contactPersonName)
                formdata.append(
                  "contactPersonEmail",
                  company.contactPersonEmail
                )
                updateProfile(formdata).then(res => {
                  localStorage.setItem("isFresh", "2")
                })
                //SetTemplate Questions
                dispatch(
                  setOnBordingBody({
                    askEmailBody: `You have a great story to tell about ${user.activeCampaign.companyName}, and we’d love to be able to share it with our community in a short testimonial video. This would help other potential customers understand what they can expect working with us.<br /><br /> Would you be open to recording a video about your experience with ${user.activeCampaign.companyName}?<br /><br /> We’re collecting customer videos using a tool called MahaloHub – it’s super easy and will only take 5-10 minutes. We will ask 4 or 5 questions, and you can record from your phone or laptop whenever you’re ready.<br /> <br /> We want to share authentic stories of real people like you, and will use these videos for our website, social media, email campaigns, etc.<br /><br /> Regardless of your response, we greatly value our relationship with you.<br /><br /> With gratitude.`,
                    reminderEmailBody: `I know you are busy so wanted to touch base to see when you might be able to carve out 5-10 mins to record a short video.<br /><br />Here's the link to record your video - you can record any time it’s convenient for you from your phone or laptop: www.mahalohub.com/${user.activeCampaign.companyName}.<br /><br /> With gratitude.`,
                    inviteEmailBody: `Thanks so much for agreeing to record your experience with us in a video testimonial! We really appreciate it.<br /><br />The process is very simple and will guide you step-by-step. It will not take longer than 10 minutes. When you’re ready, click the button below and it will fire up the recording session. <br /><br /> With gratitude."`,
                    totalReminders: 3,
                    reminderInterval: 72,
                  })
                )
                setEmailBody({
                  askEmailBody: askbody,
                  inviteEmailBody: inviteBody,
                  reminderEmailBody: reminderBody,
                }).then(res => {
                  //SetTemplate Questions
                  navigate("/user/manage")
                  tog_backdrop()
                })
              }}
              className="btn-rounded "
            >
              Finish
            </Button>
          </div>
        )}
      </Modal>
      {props.children}
      {/* {pgNo === 4 && confettiDisplay == true ? (
        
      ) : null} */}
    </>
  )
}

export default OnBoarding
