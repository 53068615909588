// Agency actions

import {
  GET_AGENCY_DATA,
  GET_AGENCY_DATA_SUCCESS,
  GET_AGENCY_DATA_FAILURE,
  GET_AGENCY_REQUESTS,
  GET_AGENCY_REQUESTS_SUCCESS,
  GET_AGENCY_REQUESTS_FAILURE,
} from "./actionTypes"

//Setting Up Agency Data
export const getAgencyData = () => {
  return {
    type: GET_AGENCY_DATA,
  }
}
export const getAgencyDataSuccess = data => {
  return {
    type: GET_AGENCY_DATA_SUCCESS,
    payload: data,
  }
}
export const getAgencyDataFailure = error => {
  return {
    type: GET_AGENCY_DATA_FAILURE,
    payload: error,
  }
}

//Setting Up Agency Requests
export const getAgencyRequests = () => {
  return {
    type: GET_AGENCY_REQUESTS,
  }
}
export const getAgencyRequestsSuccess = data => {
  return {
    type: GET_AGENCY_REQUESTS_SUCCESS,
    payload: data,
  }
}
export const getAgencyRequestsFailure = error => {
  return {
    type: GET_AGENCY_REQUESTS_FAILURE,
    payload: error,
  }
}
