import { replace } from "lodash"
import { useNavigate } from "react-router-dom"

const useNavigateWithFromState = () => {
  const navigate = useNavigate()

  const customHookFunction = path => {
    const fromState = { from: window.location.pathname }
    navigate(path, { state: fromState }, (replace = true))
  }

  return customHookFunction
}

export default useNavigateWithFromState
