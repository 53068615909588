import actionTypes from "./actionTypes"
import { all, call, fork, put, takeLatest } from "redux-saga/effects"
import actions from "./actions"

//services
import AdvanceSearchService from "./services"

export function* getAdvanceSearch({ payload }) {
  try {
    if (payload == "" || payload?.trim() == "") {
      yield put(actions.getAdvanceSearchSuccess({ payload: [] }))
      return
    }
    yield put(actions.getAdvanceSearchLoading())
    const search = yield call(AdvanceSearchService.getAdvanceSearch, payload)
    if (search?.success) {
      yield put(
        actions.getAdvanceSearchSuccess({
          payload: search.data.queryResults,
          search: payload,
        })
      )
    } else {
      yield put(actions.getAdvanceSearchFailure(search?.message))
    }
  } catch (error) {
    yield put(actions.getAdvanceSearchFailure(error))
  }
}

export function* watchGetAdvanceSearch() {
  yield takeLatest(actionTypes.GET_ADVANCE_SEARCH, getAdvanceSearch)
}

// set search query
// export function* setSearch({ payload }) {
//   if (payload) {
//     yield put(actions.getAdvanceSearch(payload))
//   }
// }

export function* watchSetSearch() {
  // yield takeLatest(actionTypes.SET_SEARCH, setSearch)
}

export default function* rootSaga() {
  yield all([fork(watchGetAdvanceSearch), fork(watchSetSearch)])
}
