import axios from "axios"
import address from "./address"
import Swal from "sweetalert2"
import Toast from "services/toast"
import { isTypedArray } from "lodash"

const axiosInstance = axios.create()

const source = axios.CancelToken.source()

const axiosApi = axios.create({
  baseURL: address,
})

axiosInstance.interceptors.request.use(config => {
  config.cancelToken = source.token
  return config
})

const cancelAllRequests = () => {
  // alert("Cancel Api Requests")
  source.cancel("All requests cancelled")
}

export { axiosApi, cancelAllRequests }

export default async function RequestEndpoint(
  endpoint,
  method,
  formdata,
  url2,
  url3
) {
  const token = localStorage.getItem("token")

  if (url2 && !url3 && formdata?.v1 && !formdata.v2) {
    const { data } = await axiosApi[method](
      `${endpoint}/${formdata.v1}/${url2}`,
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return data
  }
  if (token) {
    if (url2 && !url3 && (method === "post" || method === "put")) {
      switch (method) {
        case "post": {
          const { data } = await axiosApi[method](
            `${endpoint}/${formdata}/${url2}`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          return data
        }
        case "put": {
          {
            const { data } = await axiosApi[method](
              `${endpoint}/${formdata.v1}/${url2}/${formdata.v2}`,
              formdata.formdata,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            return data
          }
        }
      }
    } else if (url2 && url3 && method === "get2") {
      const modifiedMethod = method.slice(0, -1) // Correct the method name
      const { data } = await axiosApi[modifiedMethod](
        `${endpoint}/${formdata.v1}/${url2}/${formdata.v2}/${url3}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return data
    } else if (method === "get") {
      const { data } = await axiosApi[method](endpoint, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    } else if (method === "gets") {
      const modifiedMethod = method.slice(0, -1)
      const { data } = await axiosApi[modifiedMethod](
        `${endpoint}/${formdata}${url2}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return data
    } else if (method === "search") {
      const modifiedMethod = method.slice(0, -1)
      const { data } = await axiosApi["get"](`${endpoint}?query=${formdata}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    } else if (method === "post") {
      const { data } = await axiosApi[method](endpoint, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    } else if (method === "delete") {
      const { data } = await axiosApi[method](`${endpoint}/${formdata}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    } else if (method === "put" || method == "patch") {
      const { data } = await axiosApi[method](endpoint, formdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    } else if (method === "puts") {
      const modifiedMethod = method.slice(0, -1)
      const { data } = await axiosApi[modifiedMethod](
        `${endpoint}/${formdata.id}`,
        formdata.formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return data
    } else if (method === "deletes") {
      const modifiedMethod = method.slice(0, -1) // Correct the method name
      const { data } = await axiosApi[modifiedMethod](endpoint, {
        data: formdata,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    } else if (method === "delete2" || method === "get2") {
      const modifiedMethod = method.slice(0, -1) // Correct the method name
      const { data } = await axiosApi[modifiedMethod](
        `${endpoint}/${formdata.v1}/${url2}/${formdata.v2}/${url3}`,
        {
          data: formdata,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return data
    }
  }
}

export const RequestEndpointWithoutToken = async (
  endpoint,
  method,
  formdata
) => {
  if (method === "get") {
    const { data } = await axiosApi[method](endpoint, {
      headers: {
        "Content-Type": "application/json",
      },
    })

    return data
  }
  if (method === "post") {
    const { data } = await axiosApi[method](endpoint, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })

    return data
  }
}

export async function silentRequest(asyncFunction) {
  var message = ""
  const data = await asyncFunction()
    .then(res => {
      if (!res.success) {
        message = res.message
        Toast.fire({
          icon: "info",
          title: "Oops...",
          text: message,
        })
      }
      return res
    })
    .catch(err =>
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      })
    )
  return data
}
// export async function dynamicSilentRequest(url, method, formData) {
//   const asyncFunction = async formData => {
//     const response = await RequestEndpoint(url, method, formData)
//     return response
//   }
//   var message = ""
//   const data = await asyncFunction(formData)
//     .then(res => {
//       if (!res.success) {
//         message = res.message
//         Swal.fire({
//           icon: "info",
//           title: "Oops...",
//           text: message,
//         })
//       }
//       return res
//     })
//     .catch(err =>
//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: "Something went wrong!",
//       })
//     )
//   return data
// }
export async function dynamicSilentRequest(
  url,
  method,
  formData,
  trueMessage,
  falseMessage,
  errorMessage,
  url2,
  url3
) {
  const asyncFunction = async formData => {
    const response = await RequestEndpoint(url, method, formData, url2, url3)
    return response
  }
  var message = ""
  const data = await asyncFunction(formData)
    .then(res => {
      if (!res.success) {
        if (!falseMessage) return res
        else if (falseMessage == "success") {
          message = res.message
          Toast.fire({
            icon: "success",
            text: message,
          })
        } else if (falseMessage == "default") {
          message = res?.message[0]?.message
            ? res.message[0].message
            : res.message
          Toast.fire({
            icon: "info",
            text: message,
          })
        } else if (falseMessage != "") {
          Toast.fire({
            icon: "info",
            text: falseMessage,
          })
        }
      } else {
        if (!trueMessage) return res
        else if (trueMessage == "default") {
          message = res.message

          Toast.fire({
            icon: "success",
            text: message,
          })
        } else if (trueMessage != "") {
          Toast.fire({
            icon: "success",
            text: trueMessage,
          })
        }
      }
      return res
    })
    .catch(err => {
      if (!errorMessage) return err
      else if (errorMessage == "default") {
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: "Something went wrong!",
        })
      } else if (errorMessage != "") {
        Toast.fire({
          icon: "warning",
          title: "Oops...",
          text: errorMessage,
        })
      }
    })
  return data
}

// Axios Request

export const axiosRequest = async endpoint => {
  try {
    const token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${token}`,
      ...endpoint?.headers,
    }
    let message = ""
    const res = await axiosApi({
      method: endpoint?.method,
      url: endpoint?.endpoint,
      data: endpoint?.data,
      headers: headers,
      params: endpoint?.params,
    })
      .then(res => {
        if (!res?.data?.success) {
          if (!endpoint?.falseMessage) return res
          else if (endpoint?.falseMessage == "success") {
            message = res?.data?.message
            Toast.fire({
              icon: "success",
              text: message,
            })
          } else if (endpoint?.falseMessage == "default") {
            message = isTypedArray(res?.data?.message)
              ? res?.data?.message[0].message
              : res?.data?.message
            Toast.fire({
              icon: "info",
              text: message?.charAt(0)?.toUpperCase() + message?.slice(1),
            })
          } else if (endpoint?.falseMessage != "") {
            Toast.fire({
              icon: "info",
              text: endpoint?.falseMessage,
            })
          }
        } else {
          if (!endpoint?.trueMessage) return res
          else if (endpoint?.trueMessage == "default") {
            message =
              res?.data?.message?.charAt(0)?.toUpperCase() +
              res?.data?.message?.slice(1)

            Toast.fire({
              icon: "success",
              text: message,
            })
          } else if (endpoint?.trueMessage != "") {
            Toast.fire({
              icon: "success",
              text: endpoint?.trueMessage,
            })
          }
        }
        return res
      })
      .catch(err => {
        console.log(err)
        if (err?.message == "Request failed with status code 401") {
          Toast.fire({
            icon: "warning",
            title: "Oops...",
            text: "Session expired, please login again",
          })
          localStorage.clear()
          return {
            success: false,
            message: "Session expired, please login again",
          }
        }
        if (!endpoint?.errorMessage) return err
        else if (endpoint?.errorMessage == "default") {
          Toast.fire({
            icon: "warning",
            title: "Oops...",
            text: "Something went wrong!",
          })
        } else if (endpoint?.errorMessage != "") {
          Toast.fire({
            icon: "warning",
            title: "Oops...",
            text: endpoint?.errorMessage,
          })
        }
      })
    return res?.data ?? res
  } catch (e) {
    console.log(e)
  }
}
