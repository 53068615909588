import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  INIT_AUTH,
  INIT_AUTH_SUCCESS,
  INIT_AUTH_FAILURE,
  LOGIN_ADMIN,
  EMAIL_VERIFIED_SUCCESS,
  PAYMENT_VERIFIED_SUCCESS,
  EMAIL_VERIFIED_FAILURE,
  PAYMENT_VERIFIED_FAILURE,
  INIT_ADMIN_AUTH,
  INIT_ADMIN_AUTH_FAILURE,
  INIT_ADMIN_AUTH_SUCCESS,
  SWITCH_USER,
  LOGOUT_ADMIN_SUCCESS,
  LOGOUT_MULTI_SUCCESS,
  SWITCH_COMPANY,
  SWITCH_USER_SUCCESS,
  SWITCH_AGENCY,
} from "./actionTypes"

const initialState = {
  loading:
    localStorage.getItem("token") || localStorage.getItem("superToken")
      ? true
      : false,
  error: "",

  emailVerified: null,
  paymentVerified: null,

  authUser: null,
  token: localStorage.getItem("token") ?? null,

  superUser: null,
  superToken: localStorage.getItem("superToken") ?? null,
}

const Auth = (state = initialState, action) => {
  switch (action.type) {
    //<!----------------- Login -------------------------!>/
    // Auth User
    case LOGIN_USER:
      state = {
        ...state,
        authUser: action.payload,
        token: action.payload.token,
      }
      break
    //Auth Admin
    case LOGIN_ADMIN:
      state = {
        ...state,
        superUser: action.payload.user,
        superToken: action.payload.token,
      }
      break

    // Login Success After Auth
    case LOGIN_SUCCESS:
      state = {
        ...state,
        authUser: action.payload.authUser,
        token: action.payload.token,
        superToken: action.payload.superToken,
        loading: true,
      }
      break

    //INIT Admin After Admin
    case INIT_ADMIN_AUTH:
      state = {
        ...state,
        superToken: action.payload.token,
        loading: true,
      }
      break

    // INIT Admin Success
    case INIT_ADMIN_AUTH_SUCCESS:
      state = {
        ...state,
        superUser: action.payload,
        loading: false,
      }
      break

    //Fail Admin
    case INIT_ADMIN_AUTH_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        superUser: null,
        superToken: null,
      }
      break

    // INIT AuthUser After Login
    case INIT_AUTH:
      state = {
        ...state,
        token: action.payload.token,
        loading: true,
        paymentVerified: null,
        emailVerified: null,
        error: null,
      }
      break
    //getUser data Success
    case INIT_AUTH_SUCCESS:
      state = {
        ...state,
        authUser: action.payload,
        loading: false,
      }
      break

    //auth error
    case INIT_AUTH_FAILURE:
      state = {
        ...state,
        error: action.payload,
        authUser: null,
        loading: false,
      }
      break

    //Logout Success
    case LOGOUT_USER_SUCCESS:
      state = {
        ...state,
        error: null,
        loading: false,
        authUser: null,
        token: null,
        emailVerified: null,
        paymentVerified: null,
      }
      break

    case LOGOUT_ADMIN_SUCCESS:
      state = {
        ...state,
        error: null,
        loading: false,
        superToken: null,
        superUser: null,
      }
      break

    case LOGOUT_MULTI_SUCCESS:
      state = {
        ...state,
        error: null,
        loading: false,
        authUser: null,
        token: null,
        emailVerified: null,
        paymentVerified: null,
      }
      break

    //<!----------------- Middlewares -------------------------!>/
    //Email Verified
    case EMAIL_VERIFIED_SUCCESS:
      state = {
        ...state,
        emailVerified: true,
      }
      break

    case EMAIL_VERIFIED_FAILURE:
      state = {
        ...state,
        emailVerified: false,
      }
      break

    case PAYMENT_VERIFIED_SUCCESS:
      state = {
        ...state,
        paymentVerified: true,
        loading: true,
      }
      break

    case PAYMENT_VERIFIED_FAILURE:
      state = {
        ...state,
        paymentVerified: false,
        loading: true,
      }
      break

    // Other Actions

    case SWITCH_USER_SUCCESS:
      state = {
        ...state,
        authUser: action.payload,
        token: action.payload.token,
        loading: true,
      }
      break

    case SWITCH_AGENCY:
      state = {
        ...state,
        authUser: action.payload,
      }
      break

    case SWITCH_COMPANY:
      state = {
        ...state,
        authUser: action.payload,
        token: action.payload.token,
        loading: true,
      }
      break

    //<!----------------- Add the following case -------------------------!>/

    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Auth
