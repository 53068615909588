import {
  GET_AGENCY_DATA,
  GET_AGENCY_DATA_SUCCESS,
  GET_AGENCY_DATA_FAILURE,
  GET_AGENCY_REQUESTS,
  GET_AGENCY_REQUESTS_SUCCESS,
  GET_AGENCY_REQUESTS_FAILURE,
} from "./actionTypes"

const INIT_STATE = {
  agency: undefined,
  requests: undefined,
  loading: false,
  error: "",
}

const Agency = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AGENCY_DATA:
      return { ...state, loading: true }
    case GET_AGENCY_DATA_SUCCESS:
      return { ...state, loading: false, agency: action.payload, error: null }
    case GET_AGENCY_DATA_FAILURE:
      return { state: [], loading: false, error: action.payload }
    //Requests
    case GET_AGENCY_REQUESTS:
      return { ...state, loading: false }
    case GET_AGENCY_REQUESTS_SUCCESS:
      return { ...state, loading: false, requests: action.payload, error: null }
    case GET_AGENCY_REQUESTS_FAILURE:
      return { state: [], loading: false, error: action.payload }

    default:
      return { ...state }
  }
}

export default Agency
