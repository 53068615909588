import React, { useState, useRef, useEffect } from "react"
//Components

//Dependencies
import { Row, Col, Button } from "reactstrap"
import { Link } from "react-router-dom"
import ReactLoading from "react-loading"
//Assests
import user1 from "assets/images/userAvatar.png"
import sitelogo from "assets/images/Site Logos/sitelogo.png"
import logo from "assets/images/Site Logos/Logo.jpg"
function ModalOptionSelection(props) {
  const { data, title, description, onClick } = props
  const [debouncing, setDebouncing] = useState(true)
  const handleOptionSelectionCall = async id => {
    await onClick(id)
      .then(() => {
        setDebouncing(false)
      })
      .catch(e => {
        setDebouncing(false)
      })
      .finally(() => {
        setDebouncing(false)
      })
  }

  const [activeId, setActiveId] = useState()
  useEffect(() => {
    setTimeout(() => {
      setActiveId(data[0].id)
      setDebouncing(false)
    }, 300)
  }, [])
  return (
    <Row>
      <Col md={12} lg={12} xl={12}>
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs={8}>
              <div className="text-primary p-4">
                <h6 className="text-primary">{title}</h6>
                <p>{description}</p>
              </div>
            </Col>
            <Col className="col-4 align-self-end">
              <img
                src={sitelogo}
                alt=""
                style={{ width: "70%", height: "70%" }}
                className="img-fluid"
              />
            </Col>
          </Row>
        </div>
        <div>
          <Link to="/" className="logo-light-element">
            <div className="avatar-md profile-user-wid mb-4">
              <span className="avatar-title rounded-circle bg-light">
                <img src={logo} alt="" className="rounded-circle" height="34" />
              </span>
            </div>
          </Link>
        </div>
        <div className="d-flex justify-content-center flex-column">
          <div
            className="p-2 mt-2 overflow-auto"
            style={{
              minHeight: "200px",
              maxHeight: "260px",
            }}
          >
            {data &&
              data.map(company => (
                <ModalOption
                  key={company.id}
                  company={company}
                  setActiveId={setActiveId}
                  activeId={activeId}
                />
              ))}
          </div>

          <button
            disabled={!activeId}
            className="btn btn-primary btn-block waves-effect waves-light d-flex justify-content-center align-items-center"
            onClick={() => {
              if (debouncing) return
              setDebouncing(true)
              handleOptionSelectionCall(activeId)
            }}
          >
            {!debouncing ? (
              "Continue"
            ) : (
              <ReactLoading
                type={"spin"}
                color={"white"}
                height={19}
                width={19}
              />
            )}
          </button>
        </div>
      </Col>
    </Row>
  )
}

export default ModalOptionSelection

//Context Api

export function ModalOption(props) {
  const { company, activeId, setActiveId } = props
  const [active, setActive] = useState(activeId == company.id)
  const RadioRef = useRef()
  //   <!----->
  useEffect(() => {
    if (activeId == company.id) {
      setActive(true)
    } else {
      setActive(false)
    }
  }, [activeId])

  return (
    <label
      onClick={() => {
        if (active) return
        setActive(true)
        setActiveId(company.id)
      }}
      htmlFor={company.id}
      className={`${
        company.status == "inactive" ? "disabled-div" : ""
      } bg-hover-light form-check form-radio-outline form-radio-primary | col-12 p-0 mb-1 px-2 p-1 | d-flex align-items-center justify-content-between`}
    >
      <span className="form-check-label d-flex align-items-center gap-2">
        <img
          src={user1}
          alt=""
          className="rounded-circle header-profile-user"
        />
        <h5 className="m-0 h6">{company.name ?? company.companyName}</h5>
      </span>
      <input
        checked={active}
        ref={RadioRef}
        onChange={e => {
          e.stopPropagation()
          setActive(true)
          setActiveId(company.id)
        }}
        type="radio"
        id={company.id}
        name={company.name ?? company.companyName}
        className="form-check-input"
      />
    </label>
  )
}
