import React, { useMemo } from "react"
import { useSelector } from "react-redux"

function isMultiUser() {
  const auth = useSelector(state => state.Auth)
  const multiUser = useMemo(() => {
    return !!auth.authUser && !!auth.superUser
  }, [auth.authUser, auth.superUser])
  return multiUser
}

export default isMultiUser
