import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router"
// import useSearchQuery from "helpers/hooks/useSearchquery"
//redux
import { useSelector, useDispatch } from "react-redux"

function SearchBoxMobile() {
  //imports
  // const searchQuery = useSearchQuery("search")
  // const dispatch = useDispatch()
  // redux
  const AdvanceSearch = useSelector(state => state.AdvanceSearch)
  //states
  const [search, setSearch] = useState("")
  const [show, setShow] = useState(false)
  const navigate = useNavigate()
  //helpers
  const inputRef = useRef(null)
  //
  const handleSearch = e => {
    e.preventDefault()
    navigate(`/user/advance-search?search=${search}`)
    setSearch(search)
    //remove search parameter
    const url = new URL(window.location.href)
    url.searchParams.delete("search")
    window.history.replaceState({}, "", url)
    //unfocus the input
    if (inputRef.current) {
      inputRef.current.blur()
    }
  }
  //______________________USE EFFECT_______________________________
  useEffect(() => {
    setSearch("")
  }, [AdvanceSearch])

  //helpers
  const toggleShow = () => {
    setShow(!show)
  }
  return (
    <div className="dropdown d-inline-block d-md-none">
      <button
        onClick={() => {
          toggleShow()
        }}
        type="button"
        className="btn header-item noti-icon p-1 p-sm-2 p-md-3"
        id="page-header-search-dropdown"
      >
        <i className="mdi mdi-magnify" />
      </button>
      <div
        className={` dropdown-menu dropdown-menu-sm dropdown-menu-right p-0 ${
          show ? "show" : ""
        } 
        `}
        aria-labelledby="page-header-search-dropdown"
        style={{
          width: "100%",
          height: "auto",
          left: "0",
        }}
      >
        <form
          className="p-3"
          onSubmit={e => {
            handleSearch(e)
            toggleShow()
          }}
        >
          <div className="form-group m-0">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder="Search ..."
                aria-label="Recipient's username"
              />
              <div className="input-group-append">
                <button className="btn btn-primary" type="submit">
                  <i className="mdi mdi-magnify" />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SearchBoxMobile
