import React from "react"
import { Button } from "reactstrap"
import ReactLoading from "react-loading"

function MahaloBtn({ text, handleSubmit, ...props }) {
  return (
    <Button
      id={props?.id}
      type={props?.type ?? "button"}
      disabled={!!props?.disabled || !!props?.preloader}
      onClick={handleSubmit}
      className={`${
        props?.rounded ?? "rounded-pill"
      } btn justify-content-center align-items-center d-flex ${
        props?.className
      } ${!props.variant ? `btn-mahalo` : `btn-${props?.variant}`}`}
      style={props?.style}
    >
      <>
        {props?.preloader ? (
          <ReactLoading type="spin" color="white" height={20} width={20} />
        ) : (
          <>{props?.children ?? text}</>
        )}
      </>
    </Button>
  )
}

export default MahaloBtn
