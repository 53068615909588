import React, { useMemo, useState } from "react"
import { loadStripe } from "@stripe/stripe-js"
import ReactLoading from "react-loading"

import { Elements } from "@stripe/react-stripe-js"

function StripePromise(props) {
  //
  const [initStripe, setInitStripe] = useState({
    load: false,
    error: null,
    data: null,
  })

  //Resolve Stripe Payment Promise
  useMemo(async () => {
    if (initStripe.load) return
    //Simulate Stripe Load in 3sec using timeout
    // setTimeout(async () => {
    await loadStripe(process.env.REACT_APP_STRIPE_KEY)
      .then(res => {
        //Stripe Loaded SuccessFully and saving res in state
        setInitStripe(s => {
          return { ...s, load: true, error: null, data: res }
        })
      })
      .catch(e => {
        //Fix where the catching error casuse infinite catch loop
        if (initStripe.error) return
        //Stripe Failed to load
        setInitStripe(s => {
          return {
            ...s,
            load: false,
            error: "Stripe failed to load! Please try again later",
          }
        })
      })
      .finally(e => {})
    // }, 100000)
  })
  return (
    <>
      {!initStripe.load ? (
        <div
          style={{ height: "inherit" }}
          className="align-self-stretch d-flex justify-content-center align-items-center"
        >
          <div className="d-flex flex-row flex-wrap gap-2">
            {initStripe.error ? (
              <h5>{initStripe.error}</h5>
            ) : (
              <>
                <h5>
                  Setting up secure connection to continue payment process
                </h5>
                <div className="d-flex justify-content-center align-items-center flex-grow-1">
                  <ReactLoading
                    color="var(--mahalo-primary)"
                    height={30}
                    width={30}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <Elements stripe={initStripe.data}>{props.children}</Elements>
      )}
    </>
  )
}

export default StripePromise
