import LoginPage from "pages/Subscription/Subscription"
const Page404 = lazy(() => import("../Modules/AuthKit/pages/Page404"))

const PublicRoutes = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
]
export default PublicRoutes

//imports
import React, { lazy } from "react"
