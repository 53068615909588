import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { initAuth } from "../store/actions"
import { useLocation } from "react-router-dom"

function InitializeAuthenticatedUser(props) {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.Auth)
  const location = useLocation()
  const from = location.state?.from || "/user/dashboard"
  useEffect(() => {
    if (!auth) return
    let token = auth.token
    dispatch(initAuth({ token: token }))
  }, [])
  return props.children
}

export default InitializeAuthenticatedUser
