// saga.js
import axios from "axios"
import address from "../../services/address"
import { takeLatest, call, put, all } from "redux-saga/effects"
import { FETCH_EMAIL_BODIES_REQUEST } from "./actionTypes"
import {
  fetchEmailBodiesSuccess,
  fetchEmailBodiesFailure,
  setFirstEmailBody,
} from "./actions"

// Create an Axios instance with a baseURL
const axiosApi = axios.create({
  baseURL: address,
})

function* fetchEmailBodiesSaga() {
  try {
    const token = localStorage.getItem("token")
    const response = yield call(
      axiosApi.get,
      "/api/subscriber/getAllEmailBodies",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
   
    yield put(fetchEmailBodiesSuccess(response.data.data))

  
  } catch (error) {
    console.error("Error in fetchEmailBodiesSaga:", error)
    yield put(
      fetchEmailBodiesFailure("An error occurred while fetching email bodies.")
    )
  }
}

function* emailBodiesSagas() {
  yield takeLatest(FETCH_EMAIL_BODIES_REQUEST, fetchEmailBodiesSaga)
}

export default emailBodiesSagas
