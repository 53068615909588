import address from "../../services/address"
import axios from "axios"

const axiosApi = axios.create({
  baseURL: address,
})

export async function getQuestions() {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.get(`/api/questions/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    return data
  }
}

export async function getAllCategories() {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.get(`/api/admin/categories/getAll`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    return data
  }
}
export async function getQuestionBank() {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.get(`/api/questionBank/templateQuestions`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    return data
  }
}
export async function deleteVideo(id) {
  const token = localStorage.getItem("token")
  if (token) {
    await axiosApi.delete(
      `/api/questions/video/${id}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}
export async function deleteSproutVideo(id) {
  const token = localStorage.getItem("token")
  if (token) {
    await axiosApi.delete(
      `/api/subscriber/email/video/${id}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}

export async function getUserQuestions(id) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.get(`/api/questions/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    return data
  }
}
export async function addCategory(formdata) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.post(
      "/api/admin/categories/create",
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}
export async function addQuestion(formdata) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.post("/api/questions", formdata, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    return data
  }
}
export async function addCategoryQuestion(formdata) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.post(
      "/api/admin/questionBank/create",
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}
export async function updateQuestion(id, formdata) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.put(`/api/questions/${id}`, formdata, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    return data
  }
}
export async function deleteQuestion(id) {
  const token = localStorage.getItem("token")
  if (token) {
    const response = await axiosApi.delete(`/api/questions/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  }
}
