import React, { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom"
import { MahaloLoader } from "../components/MahaloLoader"

import UnAuthorized from "../pages/UnAuthorized"
import Show from "components/Shared/Show"
import { ADMIN_ROLE } from "Shared/constants/rolesConstants"
import InitializeAuthenticatedAdmin from "../pages/InitializeAuthenticateAdmin"
import useNavigateWithFromState from "Shared/hooks/useNavigateWithState"
import adminRoutes from "routes/adminRoutes"
import InitializeAuthenticatedUser from "../pages/InitializeAuthenticatedUser"

function RequireAdmin({ allowedRole }) {
  const location = useLocation()
  const auth = useSelector(state => state.Auth)
  const navigate = useNavigateWithFromState()

  const authRoutes = useMemo(() => {
    return adminRoutes.map(route => route.path).includes(location.pathname)
  }, [])
  useEffect(() => {
    if (!auth.token || !auth.authUser) return
    if (authRoutes) {
      navigate("/user/dashboard")
    }
  }, [auth.token, auth.authUser])

  return (
    <Show
      //When auth is loading to get user data
      when={auth.loading || (auth.token && authRoutes)}
      show={
        <Show
          when={auth.token}
          show={
            <InitializeAuthenticatedUser token={auth.token}>
              <MahaloLoader centered={true} fullscreen={true} />
            </InitializeAuthenticatedUser>
          }
          or={
            <InitializeAuthenticatedAdmin token={auth.token}>
              <MahaloLoader centered={true} fullscreen={true} />
            </InitializeAuthenticatedAdmin>
          }
        />
      }
      //when user authourized
      or={
        <Show
          when={auth.superToken}
          show={
            <Show
              //when user is  admin / allowed Role
              when={auth.superUser || auth.superToken}
              show={<Outlet />}
              or={<UnAuthorized />}
            />
          }
          or={<Navigate to="/" state={{ from: location }} replace />}
        />
      }
    />
  )
}

export default RequireAdmin
