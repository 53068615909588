import React, { useEffect, useState } from "react"

import withRouter from "components/Common/withRouter"

//Components

import { changeLayout, changeSidebarTheme } from "../../store/actions"
import "./index.css"

// Layout Related Components
import Header from "./Header"
import AdminHeader from "./AdminHeader"
import Sidebar from "./Sidebar"
import Footer from "./Footer"

//redux
import { useSelector, useDispatch } from "react-redux"
import OnBoarding from "./OnBoarding"
const Layout = props => {
  const dispatch = useDispatch()
  //context
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const [isAdminHeader, setIsAdminHeader] = useState(
    localStorage.getItem("isUser") === "admin"
  )

  // useEffect(() => {
  //   dispatch(changeLayout("vertical"))
  // }, [dispatch])

  // Collapse Sidebar on Windows Size
  useEffect(() => {
    dispatch(changeSidebarTheme("dark"))
  }, [])

  //Service Workder
  useEffect(() => {
    const updateLastActivity = () => {
      localStorage.setItem("lastActvity", new Date())
    }
    document.addEventListener("mousemove", () => {
      updateLastActivity()
    })
    document.addEventListener("click", () => {
      updateLastActivity()
    })
    let timeInterval = setInterval(() => {
      let lastAcivity = localStorage.getItem("lastActvity")
      var diffMs = Math.abs(new Date(lastAcivity) - new Date())
      var seconds = Math.floor(diffMs / 1000)
      var minute = Math.floor(seconds / 60)
      if (minute === 10) {
        clearInterval(timeInterval)
        var logoutAnchor = document.createElement("a")
        logoutAnchor.href = "/logout"
        logoutAnchor.click()
      }
    }, 1000)
    return () => {
      document.removeEventListener("mousemove", () => {
        updateLastActivity()
      })
      document.removeEventListener("click", () => {
        updateLastActivity()
      })
      clearInterval(timeInterval)
    }
  }, [])

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        {props.isUser == "admin" ? (
          <AdminHeader {...props} />
        ) : (
          <Header {...props} />
        )}

        <Sidebar
          theme={"dark"}
          type={"default"}
          isMobile={isMobile}
          isUser={props.isUser}
        />
        <OnBoarding>
          <div className="main-content">{props.children}</div>
        </OnBoarding>
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default withRouter(Layout)
