export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const INIT_AUTH = "INIT_AUTH"
export const INIT_AUTH_SUCCESS = "INIT_AUTH_SUCCESS"
export const INIT_AUTH_FAILURE = "INIT_AUTH_FAILURE"

export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const LOGOUT_USER = "LOGOUT_USER"

//Admin

export const LOGIN_ADMIN = "LOGIN_ADMIN"
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS"
export const INIT_ADMIN_AUTH = "INIT_ADMIN_AUTH"

export const INIT_ADMIN_AUTH_SUCCESS = "INIT_ADMIN_AUTH_SUCCESS"
export const INIT_ADMIN_AUTH_FAILURE = "INIT_ADMIN_AUTH_FAILURE"

export const LOGOUT_ADMIN_SUCCESS = "LOGOUT_ADMIN_SUCCESS"

export const LOGOUT_MULTI_SUCCESS = "LOGOUT_MULTI_SUCCESS"

//MiddleWares

export const EMAIL_VERIFIED_SUCCESS = "EMAIL_VERIFIED_SUCCESS"
export const EMAIL_VERIFIED_FAILURE = "EMAIL_VERIFIED_FAILURE"
export const PAYMENT_VERIFIED_SUCCESS = "PAYMENT_VERIFIED_SUCCESS"
export const PAYMENT_VERIFIED_FAILURE = "PAYMENT_VERIFIED_FAILURE"

//Switch User
export const SWITCH_USER = "SWITCH_USER"
export const SWITCH_USER_SUCCESS = "SWITCH_USER_SUCCESS"

//Switch Company

export const SWITCH_COMPANY = "SWITCH_COMPANY"
export const SWITCH_COMPANY_SUCCESS = "SWITCH_COMPANY_SUCCESS"
export const SWITCH_AGENCY = "SWITCH_AGENCY"
