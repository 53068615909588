import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom"
import { MahaloLoader } from "../components/MahaloLoader"
import InitializeAuthenticatedUser from "../pages/InitializeAuthenticatedUser"
import UnAuthorized from "../pages/UnAuthorized"
import Show from "components/Shared/Show"
import { ADMIN_ROLE } from "Shared/constants/rolesConstants"

function RequireAuth({ allowedRole, ...props }) {
  const location = useLocation()
  const auth = useSelector(state => state.Auth)

  return (
    <Show
      //When auth is loading to get user data
      when={auth.loading || (auth.authUser && !auth.authUser?.campaignUserRole)}
      show={
        <InitializeAuthenticatedUser token={auth.token}>
          <MahaloLoader centered={true} fullscreen={true} />
        </InitializeAuthenticatedUser>
      }
      //when user authourized
      or={
        <Show
          when={auth.authUser}
          show={
            <Show
              //when user is  admin / allowed
              when={
                (auth.authUser?.role == ADMIN_ROLE ||
                  allowedRole == undefined ||
                  allowedRole?.includes(auth?.authUser?.campaignUserRole)) &&
                (props?.isAllowed || props?.isAllowed == undefined)
              }
              show={<Outlet />}
              or={<UnAuthorized />}
            />
          }
          or={<Navigate to="/" state={{ from: location }} replace />}
        />
      }
    />
  )
}

export default RequireAuth
