import { axiosRequest } from "services"

const apis = {
  getAdminDetails: (token, config) => ({
    endpoint: `api/admin/categories/getAll`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      ...config,
    },
    trueMessage: null,
    falseMessage: "default",
    errorMessage: "default",
  }),
}

//Requests
export const getAdminDetails = async (token, config = {}) => {
  return await axiosRequest(apis.getAdminDetails(token, config))
}
