import React, { lazy } from "react"
//Sub Domains
const Private = lazy(() => import("../pages/SubDomains/Private"))
const Public = lazy(() => import("../pages/SubDomains/Public"))
const PrivateLogin = lazy(() => import("../pages/SubDomains/PrivateLogin"))
//External Approve View
const ExternalApproveView = lazy(() =>
  import("../pages/Public/ExternalApproveView")
)

const subdomainRoutes = [
  { path: "/:companyName/:campaignId/privatesite", element: <Private /> },
  { path: "/:companyName/:companyId/public", element: <Public /> },
  { path: "/:companyName/:campaignId/private", element: <PrivateLogin /> },
  { path: "testimonial/review", element: <ExternalApproveView /> },
]

export default subdomainRoutes
