import React, { useEffect } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import LoginPage from "pages/Subscription/Subscription"
import {
  emailVerifiedFailure,
  emailVerifiedSuccess,
  initAuth,
} from "../store/actions"
import EmailVerify from "pages/Subscription/emailVerify"

function EmailVerified({ children }) {
  const auth = useSelector(state => state.Auth.authUser)
  const Auth = useSelector(state => state.Auth)
  const emailVerified = useSelector(state => state.Auth.emailVerified)

  const dispatch = useDispatch()
  useEffect(() => {
    if (!auth) return
    if (auth?.user?.email_verified_at) {
      dispatch(emailVerifiedSuccess())
    } else {
      dispatch(emailVerifiedFailure())
    }
  }, [auth])

  return emailVerified == false ? <EmailVerify /> : <Outlet />
}

export default EmailVerified
