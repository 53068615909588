import React, { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import {
  useImage,
  useImageUpdate,
  usePlaylistUpdate,
  useWidgetlistUpdate,
} from "./ImageContextProvider"
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap"
import { Link } from "react-router-dom"
import "../ComponentStyle/componentStyle.css"
import "./index.css"
import "react-tooltip/dist/react-tooltip.css"

// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import InviteModal from "./InviteModal/InviteModal"
// import images
import logosm from "assets/images/logosm.png"

import {
  usePlanUpdate,
  useSelectedPlanIdUpdate,
  useUserUpdate,
  useCompanyNameUpdate,
  useTempRefresh,
  useUser,
} from "./ImageContextProvider"
//services
import { getUserDetail, updateUser } from "services/User/User"
//assets
import avatar from "../../assets/images/user.png"
import address from "services/address"
import SearchBox from "./components/SearchBox/SearchBox"
import { getPlaylistVideos } from "services/Testimonials/Testimonials"
import axios from "axios"
import SearchBoxMobile from "./components/SearchBox/SearchBoxMobile"
import { plans } from "components/Shared/constants/plans"
import { useSelector } from "react-redux"
import isAdmin from "Shared/utils/isAdmin"

const Header = props => {
  //
  const contextImage = useImage()
  const updateCompanyName = useCompanyNameUpdate()
  const userDetails = useUser()
  const updateUserDetail = useUserUpdate()
  const updateContext = useImageUpdate()
  const updatePlan = usePlanUpdate()
  const updateSelectedPlanId = useSelectedPlanIdUpdate()
  const tempRefresh = useTempRefresh()
  const globalWidgetlistUpdate = useWidgetlistUpdate()
  const globalPlaylistUpdate = usePlaylistUpdate()

  const navigate = useNavigate()
  const isAdminUser = isAdmin()
  const updatePlaylist = usePlaylistUpdate()
  const updateWidgetlist = useWidgetlistUpdate()
  const reduxAuthUser = useSelector(state => state.Auth.authUser)

  //Popover
  const [popover1, setpopover1] = useState(false)
  const [isFresh, setisFresh] = useState(localStorage.getItem("isFresh"))

  const ref = useRef(null)
  //Helpers
  function listUrlToId(url) {
    const regex = /playlist\/([^/]+)/
    const matches = url?.match(regex)
    const Id = matches[1]
    return Id
  }
  async function fetchArrayList(id, type) {
    await getPlaylistVideos(id)
      .then(res => {
        if (type == "playlist") {
          globalPlaylistUpdate(res.message.videos ?? [])
          updatePlaylist(res.message.videos ?? [])
        } else {
          globalWidgetlistUpdate(res.message.videos ?? [])
          updateWidgetlist(res.message.videos ?? [])
        }
        window.dispatchEvent(new Event("storage"))
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (props.lazyLoading) return
    let source = axios.CancelToken.source()

    const fetchData = async () => {
      if (props.isUser === "Loading") return
      try {
        if (!userDetails && reduxAuthUser) {
          if (!isAdminUser) {
            const response = reduxAuthUser
            //OnBoarding
            // Playlist and WidgetList
            const playlistId = response.activeCampaign.playlistId
            const widgetlistId = listUrlToId(response.activeCampaign.widgetUrl)
            // localStorage.setItem("playlistId", playlistId)
            // localStorage.setItem("widgetlistId", widgetlistId)
            if (
              response.campaignUserRole == 3 ||
              response.campaignUserRole == 4
            ) {
              localStorage.setItem("isFresh", "10")
            } else {
              if (response.company.user.isFresh == 1) {
                navigate("/user/welcome")
                updateUserDetail(response)
                // localStorage.setItem("playlistIdsArray", JSON.stringify([]))
                // localStorage.setItem("widgetlistIdsArray", JSON.stringify([]))
              }
            }
            if (playlistId) {
              await fetchArrayList(playlistId, "playlist")
            }
            if (widgetlistId) {
              await fetchArrayList(widgetlistId, "widgetlist")
            }

            localStorage.setItem("isFresh", response.company.user.isFresh)
            window.dispatchEvent(new Event("storage"))

            //Company Details
            updateCompanyName(response.activeCampaign.companyName)
            //Update Logo
            if (response.activeCampaign.logoUrl == null) {
              updateContext(avatar)
            } else {
              updateContext(
                `${address}/storage/logos/${response.activeCampaign.logoUrl}`
              )
            }
            //Plan
            const plan = response.company.plan
            //Selected Plan
            const companySelectedPlan = plans.indexOf(plan) + 1
            updateSelectedPlanId(companySelectedPlan)
            // updateSelectedPlanId(response.user.selectedPlanId)
            if (plan) {
              if (plan.includes("Starter")) {
                updatePlan(1)
              } else if (plan.includes("Plus")) {
                updatePlan(2)
              } else if (plan.includes("Pro")) {
                updatePlan(3)
              } else {
                updatePlan(4)
              }
            }
            localStorage.removeItem("authUser")
            //UserDetails
            updateUserDetail(response)
            //LocalData
          }
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          // console.log("Request cancelled:", error.message)
        } else {
          // Handle other errors
          console.log("Error:", error)
        }
      }
    }
    fetchData()

    // Cleanup function to cancel the request on component unmount or before the next effect
    return () => {
      source.cancel("Header Unmounted")
    }
  }, [])

  useEffect(() => {
    //if isFresh==2
    if (localStorage.getItem("isFresh") == 3) {
      setpopover1(true)
    } else {
      setpopover1(false)
    }
  }, [isFresh])

  useEffect(() => {
    const onLoad = () => {
      setisFresh(localStorage.getItem("isFresh"))
    }
    window.addEventListener("storage", onLoad)

    if (!contextImage && localStorage.getItem("isFresh") == 3) {
      setpopover1(true)
    }

    return () => {
      window.removeEventListener("storage", onLoad)
    }

    // }
  }, [tempRefresh])

  const [url, setUrl] = useState()
  useEffect(() => {
    const isUser = localStorage.getItem("isUser")
    if (isUser === "admin") {
      setUrl("/manage")
    } else if (isUser === "user") {
      setUrl("/user/manage")
    }
  }, [])

  /* The above code is a React functional component that defines two state variables using the useState
 hook: socialDrp and image. It also defines a function called handleFileChange that is triggered
 when a file is selected using an input element. */
  const [socialDrp, setsocialDrp] = useState(false)
  const [image, setImage] = useState("")
  /**
   * This function handles a file change event, reads the file, appends form data, and updates a user's
   * information with the new file.
   */
  const handleFileChange = event => {
    if (isFresh == 3 && window.location.href.includes("/user/manage")) {
      localStorage.setItem("isFresh", 4)
      window.dispatchEvent(new Event("storage"))
    }
    if (event.target.files.length !== 0) {
      const file = event.target.files[0]

      const reader = new FileReader()
      reader.onload = () => {
        updateContext(reader.result)
      }

      var newName = file.name.replace(/\s/g, "")
      var newFile = new File([file], newName, { type: file.type })
      reader.readAsDataURL(event.target.files[0])

      const formdata = new FormData()
      formdata.append("_method", "PUT")
      formdata.append("name", userDetails.activeCampaign.name)
      formdata.append("companyName", userDetails.activeCampaign.companyName)
      formdata.append("description", userDetails.activeCampaign.description)
      formdata.append("widgetUrl", userDetails.activeCampaign.widgetUrl)
      formdata.append("playlistUrl", userDetails.activeCampaign.playlistUrl)
      formdata.append("playlistId", userDetails.activeCampaign.playlistId)
      formdata.append("logo", newFile)
      updateUser(userDetails.activeCampaign.id, formdata)
        .then(res => {
          navigate("/user/manage")
        })
        .catch(err => {})
    }
  }
  /**
   * The function toggles the fullscreen mode of the current document.
   */
  /**
   * The function toggles between fullscreen mode and normal mode in a web browser.
   */

  /* The above code is defining a function component in JavaScript that uses the `useRef` hook to create
 a reference to a file input element. The `handleClick` function is then defined to trigger a click
 event on the file input element when called. This is typically used to open the file selection
 dialog box when a user clicks on a button or other element that triggers the `handleClick`
 function. */
  const fileInputRef = useRef(null)
  const handleClick = () => {
    fileInputRef.current.click()
  }
  /**
   * The function toggles classes on the body element based on the screen width to enable or collapse the
   * sidebar.
   */

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        {isFresh == 3 && window.location.href.includes("/user/manage") && (
          //Black background
          <div
            className="position-absolute"
            style={{
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0,0,0,0.7)",
              top: "0",
              left: "0",
              zIndex: "10",
            }}
          ></div>
        )}
        <>
          {" "}
          {props.isUser == "Loading" ? (
            <div
              className="navbar-header"
              style={{ minHeight: "max-content", height: "5.5rem" }}
            >
              {/* Campapaign Logo  */}
              <div
                className="d-flex justify-content-center headerImage"
                style={{
                  zIndex: `${popover1 ? "10000" : "0"}`,
                  backgroundColor: `${popover1 ? "white" : "transparent"}`,
                }}
              >
                <img
                  src={contextImage}
                  alt=""
                  className="img-fluid rounded "
                  style={{
                    maxWidth: "250px",
                    maxHeight: "100px",
                    padding: "5px",
                    width: "20vw",
                    minWidth: "120px",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          ) : (
            <div
              className="navbar-header"
              style={{ minHeight: "max-content", height: "5.5rem" }}
            >
              {/* Burger Menu and Advance Search Bar */}
              <div className="d-flex">
                <div className="navbar-brand-box bg-white d-none d-lg-none d-sm-block">
                  <Link to={url} className="logo logo-dark">
                    <span className="logo-sm">
                      <img src={logosm} alt="" height="22" />
                    </span>
                  </Link>

                  <Link to={url} className="logo logo-light">
                    <span className="logo-sm">
                      <img src={logosm} alt="" height="22" />
                    </span>
                  </Link>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    tToggle()
                  }}
                  className="btn btn-sm px-3 font-size-16 header-item "
                  id="vertical-menu-btn"
                >
                  <i className="fa fa-fw fa-bars" />
                </button>
                {/* Search bar */}
                <SearchBox />
              </div>
              {/* Campapaign Logo  */}
              <div
                className="d-flex justify-content-center headerImage"
                style={{
                  zIndex: `${popover1 ? "10000" : "0"}`,
                  backgroundColor: `${popover1 ? "white" : "transparent"}`,
                }}
              >
                <img
                  src={contextImage}
                  alt=""
                  className="img-fluid rounded "
                  style={{
                    maxWidth: "250px",
                    maxHeight: "100px",
                    padding: "5px",
                    width: "20vw",
                    minWidth: "120px",
                    objectFit: "contain",
                  }}
                />
                {window.location.href.includes("/user/manage") &&
                userDetails?.campaignUserRole != 4 ? (
                  <>
                    <Button
                      style={{
                        background: "#02C6FF",
                        border: "none",
                        color: "white",
                        position: "absolute",
                        bottom: "-8px",
                        right: "-15px",
                      }}
                      disabled={!userDetails?.activeCampaign}
                      className="btn-rounded uploadBtnSm "
                      onClick={() => {
                        handleClick()
                      }}
                    >
                      <i ref={ref} className="bx bx-upload"></i>
                    </Button>
                    {/* setTimeout of 3sec to show popover */}
                    {popover1 ? (
                      <Popover
                        style={{ minWidth: "200px", cursor: "pointer" }}
                        placement="bottom"
                        isOpen={popover1}
                        target={ref}
                      >
                        <PopoverHeader>Step 1</PopoverHeader>
                        <PopoverBody style={{ minWidth: "200px" }}>
                          <div>
                            Add your logo by clicking the blue button above.
                          </div>
                          <div className="d-flex justify-content-between gap-2 mt-2">
                            <button
                              className="btn-mahalo btn-rounded"
                              onClick={() => {
                                localStorage.setItem("isFresh", 8)
                                window.dispatchEvent(new Event("storage"))
                              }}
                            >
                              Dismiss
                            </button>

                            <button
                              className="btn-mahalo btn-rounded"
                              onClick={() => {
                                setpopover1(!popover1)
                                localStorage.setItem("isFresh", 4)
                                window.dispatchEvent(new Event("storage"))
                              }}
                            >
                              <i className="bx bx-right-arrow-alt"></i>
                            </button>
                          </div>
                        </PopoverBody>
                      </Popover>
                    ) : null}
                  </>
                ) : null}
                <div style={{ display: "none" }}>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                    }}
                    encType="multipart/form-data"
                    id="imageForm"
                  >
                    <FormGroup>
                      <div className="flex-grow-1 align-self-center">
                        <input
                          type="file"
                          accept="image/jpeg, image/png"
                          id="fileInput"
                          ref={fileInputRef}
                          className="form-control"
                          style={{ display: "none" }}
                          onChange={e => {
                            handleFileChange(e)
                          }}
                        />
                      </div>
                    </FormGroup>
                  </Form>
                </div>
              </div>
              {/* Header RIght Menus */}
              <div className="d-flex">
                <InviteModal {...props} />
                <SearchBoxMobile />
                <NotificationDropdown {...props} />
                <ProfileMenu {...props} />
              </div>
            </div>
          )}
        </>
      </header>
    </React.Fragment>
  )
}

export default Header
