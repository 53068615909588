import React, { useState, useEffect } from "react"

import { Link } from "react-router-dom"

// import images
import logosm from "assets/images/logosm.png"
import ProfileMenu from "components/CommonForBoth/TopbarDropdown/ProfileMenu"

const Header = props => {
  const [url, setUrl] = useState()
  useEffect(() => {
    const isUser = props.isUser
    if (isUser === "admin") {
      setUrl("/manage")
    } else if (isUser === "user") {
      setUrl("/user/manage")
    }
  }, [])

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div
              className="navbar-brand-box d-lg-none d-md-block"
              style={{ background: "#65C5B2" }}
            >
              <Link to={url} className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logosm} alt="" height="22" />
                </span>
              </Link>

              <Link to={url} className="logo logo-light">
                <span className="logo-sm">
                  <img src={logosm} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default Header
