import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import { useNavigate } from "react-router-dom"

import { getTestimonialNotification } from "services/Testimonials/Testimonials"
import {
  useNotifications,
  useNotificationsUpdate,
  useRefresh,
  useRefreshUpdate,
  useTempRefresh,
  useTempRefreshUpdate,
  useTestimonialChangeFlagUpdate,
  useUser,
} from "components/VerticalLayout/ImageContextProvider"
import { markAsRead } from "services/Testimonials/Testimonials"
import axios from "axios"
import { useSelector } from "react-redux"

const NotificationDropdown = props => {
  const token = useSelector(state => state.Auth.token)
  const user = useUser()
  const [firstTime, setFirstTime] = useState(true)
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const notificationContext = useNotifications()
  const tRefresh = useTempRefresh()
  const updateNotifications = useNotificationsUpdate()
  const refreshData = useRefresh()
  const updateRefresh = useRefreshUpdate()
  const updateTestimonialFlag = useTestimonialChangeFlagUpdate()

  const [notifications, setNotifications] = useState([])
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate()
  let source = axios.CancelToken.source()
  const fetchTestimonial = async () => {
    if (localStorage.getItem("isUser") !== "admin") {
      try {
        await getTestimonialNotification({
          cancelToken: source.token,
        }).then(res => {
          if (res !== undefined && res) {
            const filteredNotifications = res.unreadNotifications?.filter(
              notification => {
                if (notification.data?.testimonial) return notification
                else if (notification.data?.reviewDetails) return notification
                else if (
                  notification.data?.campaign &&
                  user?.campaignUserRole != 4
                )
                  return notification
              }
            )
            if (notifications.length < filteredNotifications.length) {
              updateTestimonialFlag(true)
              updateRefresh(!refreshData)
            }
            setNotifications(filteredNotifications)
            updateNotifications(filteredNotifications)
          }
        })
      } catch (error) {
        if (axios.isCancel(error)) {
          // console.log("cancelled")
        }
      }
    }
  }

  useEffect(() => {
    setMenu(false)
  }, [notificationContext])

  useEffect(() => {
    if (props.isUser == "Loading") {
      return
    }
    let interval = null
    if (token) {
      fetchTestimonial()
      interval = setInterval(() => {
        fetchTestimonial()
      }, 15000)
    }
    return () => {
      source.cancel()
      if (interval) clearInterval(interval)
    }
  }, [])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative p-1 p-sm-2 p-md-3"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i
            className={`bx bx-bell ${
              notificationContext.length > 0 ? "bx-tada" : ""
            } `}
          />
          <span className="badge bg-danger rounded-pill">
            {notificationContext.length}
          </span>
        </DropdownToggle>

        <DropdownMenu
          onMouseLeave={() => {
            setMenu(!menu)
          }}
          className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
        >
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">{"Notifications"}</h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notificationContext.map((notification, index) => (
              <div className="text-reset notification-item" key={index}>
                <div className="d-flex">
                  <div className="avatar-xs me-3">
                    <span
                      className={`avatar-title ${
                        notification.data?.testimonial
                          ? " bg-primary "
                          : notification.data?.reviewDetails
                          ? " bg-info "
                          : " bg-warning"
                      }  rounded-circle font-size-16`}
                    >
                      <i
                        className={`${
                          notification.data?.testimonial
                            ? "bx bx-play"
                            : notification.data?.reviewDetails
                            ? "bx bxs-star"
                            : "bx bx-info-circle"
                        }`}
                      />
                    </span>
                  </div>

                  <Link
                    to={
                      notification?.data?.reviewDetails
                        ? `/user/library?tId=${
                            notification.data?.reviewDetails?.id
                          }#${
                            notification.data?.reviewDetails?.currentStatus ==
                            "approved"
                              ? "approved"
                              : "archived"
                          }`
                        : `/user/dashboard?testimonialId=${notification.data?.testimonial?.id}`
                    }
                    onClick={() => {
                      const updatedNotifications = notifications.filter(
                        n => n.id != notification.id
                      )
                      updateNotifications(updatedNotifications)
                      markAsRead(notification?.id).then(res => {
                        setRefresh(!refresh)
                      })
                    }}
                  >
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">
                        {notification?.data?.testimonial && (
                          <>
                            {notification.data.client.name} added a new
                            testimonial
                          </>
                        )}
                        {notification?.data?.campaign && (
                          <>
                            {notification.data.campaign} has low balance for
                            gift cards
                          </>
                        )}
                        {notification?.data?.reviewDetails && (
                          <>Testimonial has been reviewed</>
                        )}
                      </h6>
                      <div className="font-size-12 text-muted"> </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </SimpleBar>

          <div className="p-2 border-top d-grid"></div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default NotificationDropdown

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
