import React, { useEffect, useLayoutEffect, useMemo, useState } from "react"
// <!-- End Extracting data from Url -->
const url = new URL(window.location.href)
//Get UUID
const uuid = url.searchParams.get("uuid")
// <!-- End Extracting data from Url -->
//dependencies
import {
  Container,
  Row,
  Col,
  Modal,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Badge,
} from "reactstrap"
import {
  CardElement,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
//components
import { useNavigate } from "react-router-dom"
import ReactLoading from "react-loading"
import { loadStripe } from "@stripe/stripe-js"
import ModalFetching from "services/ModalFetching"
//services
import { getUserByToken } from "services/User/User"
import {
  createSubscription,
  verifyEmail,
} from "../../services/Subscription/Subscription"
import mahalobg from "../../assets/images/backgroundoverlay.jpg"
import logo from "../../assets/images/Site Logos/Logo.png"
//styles
import "./payment.css"
import "components/VerticalLayout/Sidebar.css"
import Toast from "services/toast"
import Swal from "sweetalert2"
import { plans } from "components/Shared/constants/plans"
import BacktoAgency from "./components/BacktoAgency"
import StripePromise from "components/Shared/hooks/useStripePromise"
import { useDispatch, useSelector } from "react-redux"
import { queryClient } from "index"
import { useClearData } from "components/VerticalLayout/ImageContextProvider"
import { initAuth } from "store/actions"
// <!-- Payment Details -->
function PaymentForm(props) {
  const navigate = useNavigate()
  const clearData = useClearData()
  const { authUser } = props
  useEffect(() => {
    if (!authUser) {
      localStorage.clear()
      navigate("/logout")
      queryClient.clear()
      clearData()
    }
  }, [])
  //data
  function getPlan(planId) {
    let price = 0
    switch (planId) {
      case 1:
      case 2:
        price = paymentPeriod === "Quarterly" ? 297.0 : 1188.0
        return { name: "Starter", price }
      case 3:
      case 4:
        price = paymentPeriod === "Quarterly" ? 447.0 : 1788.0
        return { name: "Plus", price }
      case 5:
      case 6:
        price = paymentPeriod === "Quarterly" ? 747.0 : 2988.0
        return { name: "Pro", price }
      default:
        return { name: "", price: "" }
    }
  }
  //processing plans
  const [plan, setPlan] = useState()
  const [user, setUser] = useState()
  const [paymentPeriod, setPaymentPeriod] = useState("Quarterly")
  //proccessing Stripe
  const [successMessage, setSuccessMessage] = useState("Processing...")
  //processing api requests
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  //processing pageload
  const [preloader, setPreloader] = useState(true)
  //modals
  const [modal_terms, setModal_terms] = useState(false)
  const tog_modal_terms = () => {
    setModal_terms(!modal_terms)
  }
  //Effects

  useEffect(() => {
    if (!uuid) {
      setPreloader(false)
    } else {
      localStorage.clear()
      // <! -- URL DATA -- !>
      const apiUrl = url.search.split("email_verify_url=")
      const verifyEmailLink = new URL(apiUrl[1])
      const verifyEmailEndpoint =
        verifyEmailLink.pathname + verifyEmailLink.search
      // <! -- GET PAYMENT TOKEN -- !>
      getUserByToken(uuid).then(res => {
        if (res?.success) {
          setUser(res)
          localStorage.setItem("authUser", JSON.stringify(res))
          if (!res.token) {
            if (uuid && !localStorage.getItem("token")) {
              localStorage.clear()
              return navigate("/pages-404")
            }
          }
          localStorage.setItem("token", res.token)
          verifyEmail(verifyEmailEndpoint).then(res => {
            if (res.message) {
              Toast.fire({
                icon: "success",
                title: "Email Verified",
                text: "You can now proceed to payment",
              })
            }
            // remove token from location
            const windowLocation = window.location.href.split("?")[0]
            window.history.pushState(
              { path: windowLocation },
              "",
              windowLocation
            )
            setPreloader(false)
          })
          setPlan(res.user.selectedPlanId)
        } else {
          setPreloader(false)
          Swal.fire({
            icon: "info",
            title: "Oops...",
            text: res?.message ?? "Something went wrong!",
            timer: 13000,
            timerProgressBar: true,
          }).then(() => {
            navigate("/logout")
          })
        }
      })
    }
  }, [])

  useEffect(() => {
    if (props.plan) {
      setPlan(props.plan)
    }
  }, [props.plan])

  const cardStyle = {
    base: {
      color: "#32325d",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      border: "1px solid #ccc", // Add a border
      "::placeholder": {
        color: "#aab7c4",
      },
      margin: "0",
      paddin: "0",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
    showIcon: true,
  }
  //meta title
  document.title = "MahaloHub | Payment Details"
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const guidelinesUrl = process.env.REACT_APP_AWS_S3_DOMAIN_URL
  const handleSubmit = async event => {
    event.preventDefault()

    setSuccess(true)
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    })
    if (error) {
      setSuccess(false)
      setError(true)
    } else {
      setLoading(true)

      //<!--------Handle Good Card---------!>
      const formData = new FormData()
      formData.append("stripeToken", paymentMethod.id)
      formData.append("cardholder_name", event.target.name.value)
      if (event.target.couponCode.value)
        formData.append("couponCode", event.target.couponCode.value)
      let companyId = ""
      if (uuid) {
        if (!user?.company?.id) {
          setLoading(false)
          return Swal.fire({
            icon: "info",
            title: "Create Subscription",
            text: "Company not found! Please create a company first",
            timer: 13000,
            timerProgressBar: true,
          })
        }
        companyId = user?.company?.id
      } else {
        if (!authUser?.company?.id) {
          setLoading(false)
          return Swal.fire({
            icon: "info",
            title: "Create Subscription",
            text: "Company not found! Please create a company first",
            timer: 13000,
            timerProgressBar: true,
          })
        }
        companyId = authUser?.company?.id
      }
      const response = await createSubscription(plan, companyId, formData)
      if (response.success === true) {
        localStorage.setItem("spdT", "p1")
        let totalTimer = 1
        let timer = totalTimer
        setSuccess(true)
        //Updating plan in User
        const authUser = JSON.parse(localStorage.getItem("authUser"))
        //verify email
        authUser.user.email_verified_at = new Date()
        //setting plan
        authUser.company.plan = plans[plan - 1]
        localStorage.setItem("authUser", JSON.stringify(authUser))
        //Redirecting
        const countdown = setInterval(() => {
          timer--
          if (timer === totalTimer - 1) {
            setSuccessMessage("Success")
            if (uuid) localStorage.setItem("isFresh", "1")
          } else if (timer === 1) {
            setSuccessMessage("Done")
          } else {
            setSuccessMessage(`Redirecting in ${timer - 1} seconds...`)
          }
          if (timer === 0) {
            dispatch(
              initAuth({
                token: authUser.token ?? localStorage.getItem("token"),
              })
            )
            clearInterval(countdown)
            // if (uuid)
            if (localStorage.getItem("superAgency")) {
              return navigate("/user/dashboard")
            } else {
              return navigate("/user/welcome")
            }
            // else navigate("/")
          }
        }, 1000)
        //Response Failed
      } else {
        setSuccess(false)
        setError(true)
        setLoading(false)
        setTimeout(() => {
          setError(false)
        }, 2000)
      }
    }
  }
  const labelStyles = { opacity: "0.8", fontSize: "0.7rem" }

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          backgroundImage: `url(${mahalobg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <ModalFetching
          preloader={preloader}
          title="Verifying Email"
          text="Please wait! while we are verifying your account"
        />
        <br />
        <div className="d-flex justify-content-center">
          <img
            id="paymentPgLogo"
            src={logo}
            className="align-center"
            style={{ maxHeight: "70px" }}
          />{" "}
        </div>
        <br />
        <section className="d-flex justify-content-center align-items-center">
          <Container className="bg-white">
            <Row className="">
              <Col md={{ size: 7 }} className="bg-white p-5">
                <h1
                  className="text-start"
                  style={{
                    color: "#33346D",
                    fontWeight: "600",
                    fontFamily: "Raleway , sans-serif",
                  }}
                >
                  Payment Details
                </h1>

                <div className="d-flex justify-content-center h-100 flex-column">
                  <div
                    className=" py-4 w-100 pb-5"
                    style={{ maxWidth: "480px" }}
                  >
                    <div className="border-bottom border-2">
                      <Col
                        md={6}
                        style={{ marginTop: "-7vh", marginBottom: "3vh" }}
                      >
                        <div>
                          <Label className="pt-4" style={labelStyles}>
                            Payment Period
                          </Label>
                          <div className="form-check mb-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="paymentPeriod"
                              id="quarterly"
                              value="Quarterly"
                              checked={paymentPeriod === "Quarterly"}
                              onChange={() => {
                                setPaymentPeriod("Quarterly")
                                if (plan === 2) {
                                  setPlan(1)
                                }
                                if (plan === 4) {
                                  setPlan(3)
                                }
                                if (plan === 6) {
                                  setPlan(5)
                                }
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="quarterly"
                            >
                              Quarterly
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="paymentPeriod"
                              id="yearly"
                              value="Yearly"
                              checked={paymentPeriod === "Yearly"}
                              onChange={() => {
                                setPaymentPeriod("Yearly")
                                if (plan === 1) {
                                  setPlan(2)
                                }
                                if (plan === 3) {
                                  setPlan(4)
                                }
                                if (plan === 5) {
                                  setPlan(6)
                                }
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="yearly"
                            >
                              Yearly
                            </label>
                          </div>
                        </div>
                      </Col>
                      <div className="d-flex justify-content-between h5">
                        <p style={{ fontWeight: "normal" }}>
                          {getPlan(plan).name}
                          {" Plan Subscription"}
                        </p>
                        <span className="font-weight-bold">
                          $
                          {getPlan(plan).price.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </div>
                      {paymentPeriod != "Quarterly" ? (
                        <div className="d-flex justify-content-between h5">
                          <p style={{ fontWeight: "normal" }}>Discount </p>

                          <span className="font-weight-bold">10%</span>
                        </div>
                      ) : null}
                      <div className="d-flex justify-content-between h5">
                        <p style={{ fontWeight: "normal" }}>Tax </p>
                        <span className="font-weight-bold">$00.00</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between h5 pt-3">
                      <p style={{ fontWeight: "normal" }}>Amount to pay</p>
                      <span className="font-weight-bold text-primary h3">
                        {" $"}
                        {paymentPeriod == "Quarterly"
                          ? (getPlan(plan).price * 1).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : (getPlan(plan).price * 0.9).toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                      </span>
                    </div>
                    <div className="text-center small">
                      For more information on our T&Cs, please visit our{" "}
                      <span
                        className="text-mahalo"
                        onClick={() => {
                          // tog_modal_terms()
                          window.open(
                            `${guidelinesUrl}/files/documents/static/platform-terms/platorm-terms-of-use.pdf`,
                            "_blank"
                          )
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Terms and Conditions
                      </span>
                      .
                    </div>
                  </div>
                  <Modal
                    centered={true}
                    className="modal-fullscreen modal-dialog-centered"
                    keyboard={false}
                    backdrop="static"
                    isOpen={modal_terms}
                    toggle={() => {
                      tog_modal_terms()
                    }}
                  >
                    <div className="modal-header d-block">
                      <div className="d-flex justify-content-center">
                        <img
                          src={logo}
                          alt="Logo"
                          style={{
                            maxWidth: "220px",
                            maxHeight: "80px",
                            width: "20vw",
                            minWidth: "120px",
                          }}
                        />
                      </div>
                      <button
                        type="button"
                        onClick={() => {
                          tog_modal_terms()
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body terms">
                      <section id="terms-of-service">
                        <div className="card">
                          <h1 className="primary-heading">
                            Platform Terms of Service
                          </h1>
                          <span className="paragraph pt-2">
                            Last Updated: May 12, 2023
                          </span>
                          <p className="paragraph">
                            MahaloHub LLC (“
                            <b className="bold">MahaloHub</b>”, “{" "}
                            <b className="bold">we</b>”, “{" "}
                            <b className="bold">our</b>”, or “{" "}
                            <b className="bold">us</b>”) provides the online
                            platform located at https://mahalohub.com, including
                            all of its webpages and subpages (the “Website”).
                            Through our Website, maintains a web-based
                            application service that allows Brands to request
                            video testimonials from third parties, and provides
                            other capabilities, as further discussed below (the
                            “Platform”). These Terms of Service (these “Terms”)
                            are a legal agreement between you as the subscriber
                            (“you” or “Brand”) and MahaloHub governing your
                            access to and use of the Platform. For information
                            regarding how MahaloHub uses your information,
                            please see our Privacy Policy.
                            <br /> <br />
                            BY CREATING AN ACCOUNT OR USING THE PLATFORM, YOU
                            AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT
                            AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR USE THE
                            PLATFORM. IF YOU ARE A REVIEWER, PLEASE SEE THE
                            REVIEWER TERMS OF USE, FOUND HERE.
                            <br /> <br />
                            <b className="bold">
                              1. &nbsp;&nbsp;&nbsp;&nbsp;Account Registration.
                            </b>{" "}
                            <br />
                            You may be required to create a MahaloHub user
                            account (your “<b>Account</b>”) in order to access
                            and use certain features of the Website. To do so,
                            you must provide an email address and a valid
                            payment method. You may log into your Account to use
                            the Website using a unique username and password.
                            You are responsible for safeguarding your password
                            and you agree not to disclose your password to any
                            third party. You are solely responsible for any
                            activities or actions taken under your Account,
                            whether or not you have authorized such activities
                            or actions. You agree that the information that you
                            provide to MahaloHub about yourself upon
                            registration of your Account, and at all other
                            times, will be correct, current, and complete, and
                            you further agree that you will maintain and
                            promptly update such information to ensure that it
                            remains correct, current, and complete. You may not
                            impersonate any other person or use a username or
                            password that you are not authorized to use.
                            MahaloHub reserves the right to require you to
                            change your password for any reason at any time. If
                            you become aware of any unauthorized use of your
                            Account, you agree to notify MahaloHub immediately
                            at info@mahalohub.com. MahaloHub is not liable for
                            any loss or damage arising from your failure to
                            comply with the above requirements.
                            <br /> <br />
                            <b className="bold">
                              2. &nbsp;&nbsp;&nbsp;&nbsp;Eligibility.
                            </b>{" "}
                            <br />
                            Access and use of the Platform is intended for use
                            by users who are at least 18 years of age and able
                            to form legally binding contracts. You represent and
                            warrant that you are not: (a) a citizen or resident
                            of (or located in) any jurisdiction where use of the
                            Platform is prohibited by law; (b) a citizen or
                            resident of (or located in) any country that is
                            currently subject to sanctions or embargoes by the
                            United States or any other country; (c) an
                            individual who is, or who is employed by or
                            associated with a Business Entity that is,
                            identified on the U.S. Department of Commerce’s
                            Denied Persons or Entity List, the U.S. Department
                            of Treasury’s Specially Designated Nationals or
                            Blocked Persons Lists, or the U.S. Department of
                            State’s Debarred Parties List or is otherwise
                            ineligible to receive items subject to U.S. export
                            control laws and regulations or other economic
                            sanction rules of any sovereign nation.
                            <br /> <br />
                            <b className="bold">
                              3. &nbsp;&nbsp;&nbsp;&nbsp;Platform.
                            </b>{" "}
                            <br />
                            <br />
                            <b className="bold">
                              a.&nbsp;&nbsp;&nbsp;&nbsp;
                            </b>{" "}
                            Via the Website, MahaloHub offers a web-based
                            application that allows customers of the Platform
                            (“Brands”) to request from third parties who receive
                            a request from a Brand (“Reviewers”) to submit
                            testimonial and marketing content (“Reviewer
                            Content”), create marketing content using Reviewer
                            Content (“Testimonial Content”), host and stream the
                            Testimonial Content, and embed Testimonial Content
                            into Brand’s websites. Subject to these Terms,
                            MahaloHub grants you a personal, limited, revocable,
                            non-exclusive, non-transferable right to use the
                            Platform solely for your personal, non- commercial
                            purposes.
                            <br />
                            <b className="bold">b. &nbsp;&nbsp;&nbsp;&nbsp;</b>
                            In addition to the foregoing and subject to these
                            Terms, MahaloHub shall provide Brand with a unique
                            page within the Website that will host Testimonial
                            Content (a “Microsite”) during the term of the
                            Subscription (defined below).The Microsite shall be
                            branded with Brand’s logos and marks and Brand may
                            link to the Microsite on Brand’s websites and social
                            media accounts. Brand acknowledges and agrees that
                            it will be responsible for providing all terms of
                            use and privacy notices on the Microsite, and
                            MahaloHub disclaims all liability with regards to
                            content hosted on the Microsite. MahaloHub reserves
                            the right to remove any Testimonial Content from the
                            Microsite, and to cease hosting of the microsite
                            upon any violation of these Terms by Brand.
                            MahaloHub will not be required to include in the
                            Microsite any SSL encryption, reporting, or
                            tracking.
                            <br /> <br />
                            <b className="bold">
                              4. &nbsp;&nbsp;&nbsp;&nbsp;Limitations.
                            </b>{" "}
                            <br />
                            You expressly agree that you are solely responsible
                            for any and all acts and omissions that occur
                            through your use of the Platform, and you agree not
                            to engage in any unacceptable uses of the Platform.
                            In connection with your access to or use of the
                            Platform, you shall not: <br />
                            <b className="bold">(a)</b> share your Account
                            information with another person or otherwise permit
                            another person to access the Platform through your
                            Account; <br />
                            <b className="bold">(b)</b> impersonate any person
                            or entity, including, or falsely state or otherwise
                            misrepresent your affiliation with any person or
                            entity; <br />
                            <b className="bold">(c)</b> act in a manner that
                            negatively affects the ability of other users to
                            access or use the Platform; <br />
                            <b className="bold">(d)</b> take any action that
                            imposes an unreasonable or disproportionately heavy
                            load on the Platform or its infrastructure; <br />
                            <b className="bold">(e)</b> interfere with or
                            disrupt the Platform or servers or networks
                            connected to the Platform, or disobey any
                            requirements, procedures, policies or regulations of
                            networks connected to the Platform; <br />
                            <b className="bold">(f)</b> use spiders, crawlers,
                            robots, scrapers, automated tools or any other
                            similar means to access the Platform or
                            substantially download, reproduce or archive any
                            portion of the Platform; <br />
                            <b className="bold">(g)</b> access the Platform in
                            order to build a similar or competitive service;
                            <br />
                            <b className="bold"> (h)</b> sell, share, transfer,
                            trade, loan or exploit for any commercial purpose
                            any portion of the Platform, including, but not
                            limited to, your Account and password; <br />
                            <b className="bold">(i)</b> violate any applicable
                            local, state, provincial, federal or international
                            law or regulation; or <br />
                            <b className="bold">(j)</b> directly or acting
                            through a third party under its control, reverse
                            engineer, decompile, disassemble or otherwise
                            attempt to derive source code of the Website or
                            Platform. You further agree to cease contact of any
                            Reviewer upon the Reviewer’s request (including by
                            Reviewer “unsubscribing” to any email requests for
                            Reviewer Content)
                            <br /> <br />
                            <b className="bold">
                              5. &nbsp;&nbsp;&nbsp;&nbsp;Brand Materials.
                              Advertisers.
                            </b>{" "}
                            <br />
                            <br />
                            <b className="bold">
                              a. &nbsp;&nbsp;&nbsp;&nbsp;
                            </b>{" "}
                            During your use of the Platform, you may provide
                            MahaloHub with (i) Brand information, including, but
                            not limited to, Brand corporate names, trademarks,
                            logos, and service marks (“
                            <b>Brand Marks</b>”), and other audio, video
                            recordings, or images; (ii) Reviewer contact
                            information; and (iii) any other information you
                            decide to submit to MahaloHub or upload to the
                            Website (collectively, (i), (ii), and (iii) are the
                            “<b>Brand Materials</b>”).
                            <br />
                            <br />
                            <b className="bold">b. &nbsp;&nbsp;&nbsp;&nbsp;</b>
                            You may not provide any Brand Materials to MahaloHub
                            that are illegal, harmful, or hazardous, or that
                            infringe or violate any third party’s rights,
                            including any copyright, patent, trademark, or other
                            proprietary or contractual right. MahaloHub reserves
                            the right to remove or exclude Brand Materials that
                            it, in its sole discretion, deems abusive,
                            defamatory, obscene, in violation of the law, or
                            otherwise harmful. You represent and warrant that
                            all Brand Materials uploaded by you are either
                            created and owned by you, or you have the necessary
                            licenses, rights, and permissions to use the Brand
                            Materials as you intend to use them and as
                            contemplated by the Platform, and to grant MahaloHub
                            the rights described herein. Without limiting the
                            foregoing, you represent and warrant that you have
                            obtained all necessary consents, and made all
                            necessary disclaimers, to provide to MahaloHub all
                            Reviewer contact information. Unless required by law
                            or the terms of this Agreement, MahaloHub shall
                            delete any and all Brand Materials thirty (30) days
                            after the termination or cancelation of your
                            Subscription.
                            <br /> <br />
                            <b className="bold">
                              6. &nbsp;&nbsp;&nbsp;&nbsp;Ownership and License.
                            </b>{" "}
                            <br />
                            <b className="bold">
                              a. &nbsp;&nbsp;&nbsp;&nbsp;Platform and
                              Testimonial Content Ownership.
                            </b>{" "}
                            Except for the limited license granted in these
                            Terms, all intellectual property rights, including
                            without limitation copyrights, patents, trademarks,
                            services marks, and trade secrets in the Platform
                            (including all content and functionalities contained
                            therein), Testimonial Content, and all other
                            services provided by MahaloHub are owned by
                            MahaloHub and its licensors. Copying, distributing,
                            modifying, or creating derivative works of the
                            Platform, any of its Content, or the Testimonial
                            Content without MahaloHub’s written consent is
                            strictly prohibited. Other than with respect to your
                            Brand Materials, Mahalohub retains full and complete
                            title to all content on the Platform.
                            <br />
                            <br />
                            <b className="bold">
                              b. &nbsp;&nbsp;&nbsp;&nbsp;License to Testimonial
                              Content.
                            </b>{" "}
                            <br />
                            <b className="bold">
                              &nbsp;&nbsp;&nbsp;&nbsp;a.
                              &nbsp;&nbsp;&nbsp;&nbsp;Non-Pro Subscribers.
                            </b>{" "}
                            Subject to your compliance with these Terms and upon
                            payment of all applicable fees, MahaloHub grants to
                            you a non-exclusive, worldwide, transferable, and
                            sublicensable license to reproduce, distribute,
                            publicly display, publicly perform the Testimonial
                            Content during the term of your Subscription. To
                            download and continue to use the Testimonial Content
                            outside of your Subscription, you will need to
                            upgrade to the MahaloHub Pro subscription (please
                            see below).
                            <br />
                            <b className="bold">
                              &nbsp;&nbsp;&nbsp;&nbsp;b.
                              &nbsp;&nbsp;&nbsp;&nbsp;Pro Tier Subscribers.
                            </b>{" "}
                            Subject to your compliance with these Terms and upon
                            payment of all applicable fees, MahaloHub grants to
                            you a non-exclusive, perpetual, worldwide,
                            transferable, and sublicensable license to
                            reproduce, distribute, publicly display, publicly
                            perform the Testimonial Content. In order to
                            continue use of the Testimonial Content beyond your
                            Subscription Term, you must download all such
                            content before your Subscription expires. MahaloHub
                            is under no obligation to host, stream, or store the
                            Testimonial Content after the expiration of your
                            Subscription or termination of your use of the
                            Platform. You must download the Testimonial Content
                            before the Subscription period expires or is
                            terminated if you wish to use such material outside
                            the term of your Subscription. <br />
                            <b className="bold">
                              c. &nbsp;&nbsp;&nbsp;&nbsp;Limited Brand Materials
                              License.
                            </b>{" "}
                            As between you and MahaloHub, you retain ownership
                            of your Brand Materials. You hereby grant MahaloHub,
                            a non-exclusive, fully transferable, sublicensable,
                            worldwide, fully-paid right and license to use,
                            reproduce, modify, create derivative works from,
                            distribute, perform, transmit, and display your
                            Brand Materials, as necessary to provide the
                            Platform to you and as necessary to use the
                            Testimonial Content. <br />
                            <b className="bold">
                              d. &nbsp;&nbsp;&nbsp;&nbsp;Limited MahaloHub
                              Proprietary Content License.
                            </b>{" "}
                            MahaloHub has developed proprietary video, audio,
                            and image content, and licenses certain audio and
                            image content from third parties, including musical
                            recordings, photos, and/or video clips (referred to
                            as “MahaloHub Proprietary Content”). MahaloHub makes
                            this MahaloHub Proprietary Content available to its
                            Brands for use in making Testimonial Content.
                            Ownership of all intellectual property rights in
                            MahaloHub Proprietary Content remains with MahaloHub
                            and/or any third-party licensor, and your right to
                            use such MahaloHub Proprietary Content is subject to
                            these Terms. You may only use and modify MahaloHub
                            Proprietary Content in connection with making a
                            Testimonial Content via the Platform, and may only
                            copy, distribute, or display the MahaloHub
                            Proprietary Content as incorporated within the
                            Testimonial Content. <br />
                            <b className="bold">
                              e. &nbsp;&nbsp;&nbsp;&nbsp;Usage Data.
                            </b>{" "}
                            "<b>Usage Data</b>" means any content, data, or
                            information that is collected or produced by the
                            Platform in connection with Brand’s use of the same
                            that does not Brand, and may include, but is not
                            limited to, usage patterns, traffic logs, and user
                            conduct associated with the Platform. MahaloHub
                            retains ownership of all right, title, and interest
                            in and to the Usage Data. MahaloHub may use Usage
                            Data for any other lawful business purpose,
                            including, but not limited to, benchmarking, data
                            analysis, and to improve MahaloHub’s services,
                            systems, and algorithms.
                            <br /> <br />
                            <b className="bold">
                              7. &nbsp;&nbsp;&nbsp;&nbsp;Use of Testimonial
                              Content.
                            </b>{" "}
                            <br />
                            MahaloHub does not endorse the contents of any
                            Reviewer Content and does not pre-screen or monitor
                            Reviewer Content. However, MahaloHub retains the
                            right, at its sole discretion, to remove any content
                            of any kind that does not comply with these Terms.
                            You agree to immediately cease use all materials
                            containing Reviewer Materials (including Testimonial
                            Content) if so requested by the subject Reviewer,
                            either as communicated directly to you by the
                            Reviewer or if MahaloHub informs you it has received
                            such request from such Reviewer. Further, you agree
                            to cease use of the Testimonial Content upon request
                            by MahaloHub if MahaloHub determines, in its sole
                            discretion, that the Testimonial Content, or use
                            thereof, violates these Terms. MahaloHub shall not
                            be responsible for any inability to use Reviewer
                            Content and/or Testimonial Content that MahaloHub
                            removes pursuant to these Terms. If you use the
                            Testimonial Content or Reviewer Content in violation
                            of these Terms, including in excess of what your
                            Subscription permits, you may be subject to
                            additional fees based on your use.
                            <br /> <br />
                            <b className="bold">
                              8. &nbsp;&nbsp;&nbsp;&nbsp;Payment of Fees.
                            </b>{" "}
                            <br />
                            <b className="bold">
                              a. &nbsp;&nbsp;&nbsp;&nbsp;
                            </b>{" "}
                            If you select one of our paid subscription plans,
                            you agree to pay all per-use fees or fees associated
                            with your subscription (“<b>Fees</b>”). Where
                            applicable, you will be billed using the billing
                            method you select through your account management
                            page. If you have elected to pay the fees by credit
                            card, you represent and warrant that the credit card
                            information you provide is correct and you will
                            promptly notify MahaloHub of any changes to such
                            information. MahaloHub may provide you with the
                            ability to pay the Fees through a third-party
                            service. All subscriptions paid through these third
                            parties are subject to the third party’s Terms of
                            Service, and we will not be responsible for anything
                            contained therein. Except in case you terminate
                            these Terms and/or your Subscription due to
                            MahaloHub’s breach of these Terms, all fees are
                            non-refundable. You are responsible for payment of
                            any sales or use taxes associated with the Fees or
                            your use of the Platform.
                            <br />
                            <b className="bold">b. &nbsp;&nbsp;&nbsp;&nbsp;</b>
                            Access to the Platform is on a subscription basis
                            (“Subscription”). You will be billed in advance on a
                            recurring, annual basis (each period is called a
                            “Billing Cycle”). To the extent permitted by law,
                            your Subscription will automatically renew at the
                            end of each annual Billing Cycle unless you cancel
                            auto-renewal through your online account management
                            page, or by contacting our Customer Success team.
                            You may cancel auto-renewal on your Subscription at
                            any time, in which case your Subscription will
                            continue until the end of that annual subscription
                            cycle before terminating.
                            <br /> <br />
                            <b>
                              By signing up for a subscription, you acknowledge
                              that your purchase of the subscription:
                              <br />
                              <br />
                              <b className="bold">
                                (a)&nbsp;&nbsp;&nbsp;&nbsp;
                              </b>
                              Is subject to automatic renewal; <br />
                              <b className="bold">
                                (b)&nbsp;&nbsp;&nbsp;&nbsp;
                              </b>
                              Will continue until you cancel the subscription;
                              and <br />
                              <b className="bold">
                                (c)&nbsp;&nbsp;&nbsp;&nbsp;
                              </b>
                              Is subject to automatic charges on your method of
                              payment. <br />
                              <br />
                              You may cancel your subscription at any time by
                              going to the “Settings” page, selecting the
                              “Subscription” tab, and selecting “Change or
                              Cancel.” Alternatively, you may email
                              info@mahalohub.com to cancel your subscription.
                            </b>
                            <br />
                            <br />
                            <b className="bold">c. &nbsp;&nbsp;&nbsp;&nbsp;</b>
                            MahaloHub may change the Fees charged for the
                            Platform at any time, provided that, for
                            Subscriptions billed on a subscription basis, the
                            change will become effective only at the end of the
                            then- current Billing Cycle. MahaloHub will provide
                            you with advance notice of any change in Fees.{" "}
                            <br />
                            <b className="bold">
                              d. &nbsp;&nbsp;&nbsp;&nbsp;
                            </b>{" "}
                            Each Subscription level has certain limitations as
                            to, among other things, number of Account users and
                            hours of Testimonial Content that MahaloHub will
                            store for Brand. If you near the limitations set
                            forth by your Subscription, MahaloHub will contact
                            you to notify you of the same, at which time, you
                            may elect to upgrade your Subscription.
                            <br />
                            <br />
                            <b className="bold">
                              9. &nbsp;&nbsp;&nbsp;&nbsp;Suspension and
                              Termination.
                            </b>{" "}
                            These Terms are effective upon your first use of the
                            Platform and shall continue until you cease use of
                            the Platform. MahaloHub reserves the right to
                            suspend or terminate your Account and your access to
                            and use of the Platform, at any time, without
                            notice, if MahaloHub has reason to believe you have
                            violated these Terms. MahaloHub reserves the right
                            at any time and from time to time to modify or
                            discontinue, temporarily or permanently, the
                            Platform (or any part thereof) with or without
                            notice. You agree that MahaloHub shall not be liable
                            to you or to any third party for any modification,
                            suspension, or discontinuance of the Platform.
                            <br /> <br />
                            <b className="bold">
                              10. &nbsp;&nbsp;&nbsp;&nbsp;Third parties;
                              Advertisers.
                            </b>{" "}
                            <br />
                            <b className="bold">
                              a. &nbsp;&nbsp;&nbsp;&nbsp;Third Party Sites.
                            </b>{" "}
                            The Platform may contain links to other websites
                            operated by third parties (“Third Party Sites”).
                            Such Third Party Sites are not under our control.
                            MahaloHub provides these links only as a convenience
                            and does not review, approve, monitor, endorse,
                            warrant, or make any representations with respect to
                            such Third Party Sites. Your use of these Third
                            Party Sites may be subject to separate terms and
                            conditions and is at your own risk.
                            <br />
                            <b className="bold">
                              b. &nbsp;&nbsp;&nbsp;&nbsp;Advertisers and
                              Marketers
                            </b>
                            Your dealings with advertisers and other third
                            parties who market, sell, buy, or offer to sell or
                            buy any goods or services on the Platform, including
                            payment for or delivery of such goods or services
                            and any other terms, conditions, warranties, or
                            representations associated with such dealings, are
                            solely between you and the advertiser or other third
                            party. You agree that MahaloHub shall not be liable
                            for any damage or loss of any kind incurred as a
                            result of any such dealings.
                            <br /> <br />
                            <b className="bold">
                              11. &nbsp;&nbsp;&nbsp;&nbsp;Linking of Testimonial
                              Content.
                            </b>{" "}
                            <br />
                            Subject to these Terms, MahaloHub will provide Brand
                            with an integration code (“Integration Code”) to
                            permit Brand to embed the Testimonial Videos on
                            Brand’s websites and social media pages. By using an
                            Integration Code, you release MahaloHub from, and
                            waive all claims to, all potential damages,
                            including but not limited to, interruption in
                            service to key business systems, data loss or
                            corruption, and/or inadvertent video distribution
                            using the Integration Code. By linking or embedding
                            any Testimonial Content on your webpages, you agree
                            as follows:
                            <br />
                            <b className="bold">
                              a. &nbsp;&nbsp;&nbsp;&nbsp;
                            </b>{" "}
                            screenshots of the Website, quotes taken from our
                            text, video, or audio content, and any references to
                            our brand, domain, or web pages must include a
                            search-engine-followable HTML link;
                            <br />
                            <b className="bold">
                              b. &nbsp;&nbsp;&nbsp;&nbsp;
                            </b>{" "}
                            the link may not use, defame, or tarnish any logo,
                            graphic, tagline, service mark, trademark, or
                            copyrighted information of MahaloHub or any other
                            third party
                            <br />
                            <b className="bold">
                              c. &nbsp;&nbsp;&nbsp;&nbsp;
                            </b>{" "}
                            the link and surrounding materials must not deliver
                            the Website content in a framed environment, or
                            alter the layout, content, look, or feel of the
                            Website;
                            <br />
                            <b className="bold">
                              d. &nbsp;&nbsp;&nbsp;&nbsp;
                            </b>{" "}
                            the link and surrounding materials must not imply
                            affiliation or falsely represent any relationship
                            between the linking site and MahaloHub;
                            <br />
                            <b className="bold">
                              e. &nbsp;&nbsp;&nbsp;&nbsp;
                            </b>{" "}
                            the link and surrounding materials must not portray
                            MahaloHub, Testimonial Content or services available
                            on the Site, or the Site itself, in a false,
                            misleading, derogatory, defamatory or otherwise
                            offensive manner; and
                            <br />
                            <b className="bold">
                              f. &nbsp;&nbsp;&nbsp;&nbsp;
                            </b>{" "}
                            Integration Codes provided by MahaloHub to link the
                            Testimonial Content must be used solely to embed
                            Testimonial Content and you may not attempt to
                            dissemble, modify, or otherwise tamper with them.
                            <br /> <br />
                            <b className="bold">
                              12. &nbsp;&nbsp;&nbsp;&nbsp;Disclaimer of
                              Warranty.
                            </b>{" "}
                            <br />
                            <b className="bold">
                              a. &nbsp;&nbsp;&nbsp;&nbsp;
                            </b>{" "}
                            THE PLATFORM IS PROVIDED ON AN “AS IS” AND “AS
                            AVAILABLE” BASIS AND WITHOUT WARRANTIES OF ANY KIND.
                            MAHALOHUB EXPRESSLY DISCLAIMS ALL WARRANTIES,
                            WHETHER EXPRESS OR IMPLIED, WITH RESPECT TO THE
                            PLATFORM, INCLUDING, BUT NOT LIMITED TO, IMPLIED
                            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                            PARTICULAR PURPOSE, AND NON-INFRINGEMENT. NO ADVICE
                            OR INFORMATION GIVEN BY MAHALOHUB, ITS EMPLOYEES OR
                            AFFILIATES SHALL CREATE A WARRANTY.
                            <br />
                            <b className="bold">b. &nbsp;&nbsp;&nbsp;&nbsp;</b>
                            MAHALOHUB MAKES NO WARRANTY THAT USE OF THE PLATFORM
                            WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR FREE,
                            THAT THE PLATFORM IS FREE OF VIRUSES OR OTHER
                            HARMFUL COMPONENTS, THAT THE PLATFORM WILL BE
                            AVAILABLE, OR THAT DATA WILL BE SECURE FROM
                            UNAUTHORIZED ACCESS.
                            <br /> <br />
                            <b className="bold">
                              13. &nbsp;&nbsp;&nbsp;&nbsp;Indemnification.
                            </b>{" "}
                            <br />
                            Unless prohibited by state or federal laws to which
                            you are subject, you will indemnify, defend (at
                            MahaloHub’s option), and hold MahaloHub, its
                            officers, directors, employees and agents, harmless
                            from and against any claims, liabilities, damages,
                            losses, and expenses, including without limitation
                            reasonable attorney’s fees and costs, arising out of
                            or in any way connected with (a) your access to or
                            use of the Platform; (b) any Brand Materials, (c)
                            your violation of these Terms; or (d) your violation
                            of any third party right, including without
                            limitation any intellectual property right or any
                            publicity, confidentiality, property or privacy
                            right. MahaloHub reserves the right, at your
                            expense, to assume the exclusive defense and control
                            of any matter for which you are required to
                            indemnify MahaloHub and you agree to cooperate with
                            our defense of such claims. You agree not to settle
                            any such claim without MahaloHub’s prior written
                            consent. MahaloHub will use reasonable efforts to
                            notify you of any such claim, action or proceeding
                            upon becoming aware of it.
                            <br /> <br />
                            <b className="bold">
                              14. &nbsp;&nbsp;&nbsp;&nbsp;Limitation of
                              Liability.
                            </b>{" "}
                            <br />
                            <b className="bold">
                              A. &nbsp;&nbsp;&nbsp;&nbsp;
                            </b>{" "}
                            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW,
                            UNDER NO CIRCUMSTANCES SHALL MAHALOHUB BE LIABLE FOR
                            ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL
                            DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR
                            LOSS OF PROFITS, USE, IMAGES, DATA OR OTHER
                            INTANGIBLES, EVEN IF MAHALOHUB HAS BEEN ADVISED OF
                            THE POSSIBILITY OF SUCH DAMAGES, THAT RESULT FROM
                            THE USE OR THE INABILITY TO USE THE PLATFORM, FROM
                            ANY CHANGES TO THE PLATFORM OR FROM UNAUTHORIZED
                            ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR
                            BRAND MATERIALS. YOU SPECIFICALLY ACKNOWLEDGE THAT
                            DOWN-TIME AND COMPUTER VIRUSES ARE RISKS INHERENT IN
                            THE USE OF THE INTERNET AND SOFTWARE PRODUCTS, AND
                            YOU AGREE TO ASSUME RESPONSIBILITY FOR ANY HARM OR
                            DAMAGES OF ANY KIND OR CHARACTER WHATSOEVER
                            RESULTING FROM THESE POSSIBLE HARMS. YOU AGREE TO
                            ASSUME RESPONSIBILITY FOR ANY HARM OR DAMAGES OF ANY
                            KIND OR CHARACTER WHATSOEVER RESULTING FROM YOUR
                            RELEASE OF ANY BRAND MATERIALS.
                            <br />
                            <b className="bold">
                              B. &nbsp;&nbsp;&nbsp;&nbsp;
                            </b>{" "}
                            IF YOU ARE DISSATISFIED WITH THE PLATFORM OR WITH
                            ANY OF THESE TERMS, OR FEEL MAHALOHUB HAS BREACHED
                            THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
                            DISCONTINUE USING THE PLATFORM. THE TOTAL LIABILITY
                            OF MAHALOHUB TO YOU FOR ANY CLAIM ARISING FROM OR
                            RELATING TO THESE TERMS OR USE OF PLATFORM SHALL NOT
                            EXCEED THE AMOUNT PAID BY YOU FOR THE PLATFORM
                            DURING THE SIX MONTHS PRECEDING THE EVENT GIVING
                            RISE TO THE LIABILITY. IT IS THE INTENTION OF YOU
                            AND MAHALOHUB THAT THIS PROVISION BE CONSTRUED BY A
                            COURT AS BEING THE BROADEST LIMITATION OF LIABILITY
                            CONSISTENT WITH APPLICABLE LAW.
                            <br />
                            <b className="bold">
                              C. &nbsp;&nbsp;&nbsp;&nbsp;
                            </b>{" "}
                            SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR
                            EXCLUSION OF INCIDENTAL, CONSEQUENTIAL OR OTHER
                            TYPES OF DAMAGES, SO SOME OF THE ABOVE LIMITATIONS
                            MAY NOT APPLY TO YOU. NOTHING IN THESE TERMS AND IN
                            PARTICULAR WITHIN THIS “LIMITATION OF LIABILITY”
                            CLAUSE SHALL ATTEMPT TO EXCLUDE LIABILITY THAT
                            CANNOT BE EXCLUDED UNDER APPLICABLE LAW.
                            <br /> <br />
                            <b className="bold">
                              15. &nbsp;&nbsp;&nbsp;&nbsp;Release.
                            </b>{" "}
                            <br />
                            You hereby release and forever discharge us (and our
                            officers, employees, agents, successors, and
                            assigns) from, and hereby waive and relinquish, each
                            and every past, present, and future dispute, claim,
                            controversy, demand, right, obligation, liability,
                            action, and cause of action of every kind and nature
                            (including personal injuries, death, and property
                            damage), that has arisen or arises directly or
                            indirectly out of, or relates directly or indirectly
                            to, any interactions with, or act or omission of,
                            other users of the Platform (including Reviewers),
                            or any Third Party Site. IF YOU ARE A CALIFORNIA
                            RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE
                            SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH
                            STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS
                            WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST
                            IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE
                            RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE
                            MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
                            DEBTOR.”
                            <br /> <br />
                            <b className="bold">
                              16. &nbsp;&nbsp;&nbsp;&nbsp;General.
                            </b>{" "}
                            <br />
                            <b className="bold">
                              a. &nbsp;&nbsp;&nbsp;&nbsp;Modification.
                            </b>{" "}
                            MahaloHub may modify these Terms at any time.
                            Modifications become effective immediately upon your
                            first access to or use of the Platform after the
                            “Last Revised” date at the top of these Terms. Your
                            continued access to or use of the Platform after the
                            modifications have become effective will be deemed
                            your conclusive acceptance of the modified Terms. If
                            you do not agree with the modifications, do not
                            access or use the Platform.
                            <br />
                            <b className="bold">
                              b. &nbsp;&nbsp;&nbsp;&nbsp;Governing Law.
                            </b>{" "}
                            These Terms shall be governed by the laws of the
                            State of Illinois without giving effect to any
                            conflict of laws principles that may require the
                            application of the law of another jurisdiction.
                            <br />
                            <b className="bold">
                              c. &nbsp;&nbsp;&nbsp;&nbsp;Dispute Resolution.
                            </b>{" "}
                            If you believe that MahaloHub has not adhered to
                            these Terms, please contact us by e-mail
                            info@mahalohub.com. We will do our best to address
                            your concerns. If you feel that your complaint has
                            been addressed incompletely, we invite you to let us
                            know for further investigation.
                            <br />
                            <b className="bold">
                              &nbsp;&nbsp;&nbsp;&nbsp;i.
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </b>
                            If you and MahaloHub are unable to reach a
                            resolution to the dispute, you and MahaloHub will
                            settle the dispute exclusively under the rules of
                            the American Arbitration Association (www.adr.org)
                            at its Chicago, Illinois office. Any election to
                            arbitrate by one party will be final and binding on
                            the other. YOU UNDERSTAND THAT IF EITHER PARTY
                            ELECTS TO ARBITRATE, NEITHER PARTY WILL HAVE THE
                            RIGHT TO SUE IN COURT OR HAVE A JURY TRIAL. YOU AND
                            MAHALOHUB AGREE THAT ANY ARBITRATION WILL BE LIMITED
                            TO THE DISPUTE BETWEEN MAHALOHUB AND YOU
                            INDIVIDUALLY. YOU ACKNOWLEDGE AND AGREE THAT YOU AND
                            MAHALOHUB ARE EACH WAIVING THE RIGHT TO PARTICIPATE
                            AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
                            CLASS ACTION OR REPRESENTATIVE PROCEEDING.
                            <br />
                            <b className="bold">
                              &nbsp;&nbsp;&nbsp;&nbsp;ii.
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </b>
                            Further, unless both you and MahaloHub otherwise
                            agree in writing, an arbitrator may not consolidate
                            more than one person’s claims and may not otherwise
                            preside over any form of any class or representative
                            proceeding. Regardless of any statute or law to the
                            contrary, any claim or cause of action arising out
                            of or related to the use of the Platform or these
                            Terms must be brought, if at all, within one year
                            from the accrual of the claim or cause of action or
                            be forever barred.
                            <br />
                            <b className="bold">
                              &nbsp;&nbsp;&nbsp;&nbsp;iii.
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </b>
                            Notwithstanding the foregoing, MahaloHub may seek
                            injunctive or other equitable relief to protect its
                            confidential information and intellectual property
                            rights or to prevent loss of data or damage to its
                            servers in any court of competent jurisdiction.
                            <br />
                            <b className="bold">
                              &nbsp;&nbsp;&nbsp;&nbsp;iv.
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </b>
                            If you are subject to any state or federal law
                            requiring that the laws of a different jurisdiction
                            apply or that prohibit dispute resolution in the
                            manner required in this Section, then this Section
                            shall be deemed revised to conform with such laws.
                            <br />
                            <b className="bold">
                              d. &nbsp;&nbsp;&nbsp;&nbsp;Compliance with Laws.
                            </b>{" "}
                            If you believe that MahaloHub has not adhered to
                            these Terms, please contact us by e-mail
                            info@mahalohub.com. We will do our best to address
                            your concerns. If you feel that your complaint has
                            been addressed incompletely, we invite you to let us
                            know for further investigation.
                            <br />
                            <br />
                            <b className="bold">
                              e. &nbsp;&nbsp;&nbsp;&nbsp;International Use.
                            </b>{" "}
                            MahaloHub controls and operates the Platform from
                            the state of California. MahaloHub does not
                            represent that the Platform or any materials on the
                            Platform are appropriate or available for use in
                            other locations outside of the United States.
                            Persons who choose to access the Platform from other
                            locations do so on their own initiative, and are
                            responsible for compliance with local laws, if and
                            to the extent local laws are applicable. Without
                            limiting the generality of the foregoing, you agree
                            to comply with all applicable laws regarding the
                            transmission or transfer of any data or technical
                            information in connection with your access to or use
                            of the Platform.
                            <br />
                            <b className="bold">
                              f. &nbsp;&nbsp;&nbsp;&nbsp;Survivability.
                            </b>{" "}
                            Sections 6, 7, 8, 9, 12, 13, 14, 15, and 16 will
                            survive any expiration or termination of these
                            Terms.
                            <br />
                            <b className="bold">
                              g. &nbsp;&nbsp;&nbsp;&nbsp;Contact.
                            </b>{" "}
                            MahaloHub is located in Glenview, Illinois. Any
                            questions, comments, or suggestions, including any
                            report of violation of these Terms should be
                            provided MahaloHub as follows:
                            <br />
                            <br />
                            <div className="text-center">
                              <b className="bold">
                                By Email: &nbsp;&nbsp;&nbsp;&nbsp;
                              </b>{" "}
                              <br />
                              <a
                                className="text-white"
                                href="mailto:info@mahalohub.com"
                              >
                                info@mahalohub.com
                              </a>
                              <br />
                              <b className="bold">
                                By Postal Mail: &nbsp;&nbsp;&nbsp;&nbsp;
                              </b>{" "}
                              <br />
                              2516 Waukegan Road, Ste 218 Glenview, IL
                              60025-1774
                            </div>
                            <br />
                            <b className="bold">
                              h. &nbsp;&nbsp;&nbsp;&nbsp;Entire Agreement.
                            </b>{" "}
                            These Terms constitute the entire agreement between
                            you and MahaloHub with respect to the Platform,
                            superseding any prior agreements between you and
                            MahaloHub. The failure of MahaloHub to exercise or
                            enforce any right or provision of these Terms shall
                            not constitute a waiver of such right or provision.
                            If any provision of these Terms is found by a court
                            of competent jurisdiction to be invalid, you and
                            MahaloHub nevertheless agree that the court should
                            endeavor to give effect to the parties’ intentions
                            as reflected in the provision, and the other
                            provisions of these Terms shall remain in full force
                            and effect. The parties hereto are independent
                            parties, not agents, employees or employers of the
                            other or joint venturers, and neither acquires
                            hereunder any right or ability to bind or enter into
                            any obligation on behalf of the other.
                            <br /> <br />
                          </p>
                          {/* Accept Term and condition */}
                        </div>
                      </section>

                      <footer>
                        <p className="footer-heading">
                          © Copyright 2023 MahaloHub LLC. All rights reserved.
                        </p>
                      </footer>
                    </div>
                  </Modal>

                  <small
                    className="d-flex gap-1 align-items-baseline"
                    style={{ color: "#33346D" }}
                  >
                    <i className="bx bx-info-circle m-0 p-0 mr-3" />
                    Your security is important to us. We do not share your
                    credit card information and use it only for billing
                    purposes.
                    <br />
                    Subscriptions are annual and billed quarterly or annually,
                    depending on your preference. If you would like to cancel,
                    we would hate to see you go. You can cancel your annual
                    subscription at least 15 days before your auto renewal.
                  </small>
                </div>
              </Col>
              <Col md={{ size: 5 }} className="bg-light p-5">
                <h2>Card Details</h2>
                <Form
                  onSubmit={handleSubmit}
                  className="custom-payments-fields "
                >
                  <FormGroup className=" pt-4">
                    <Label className="pt-3" style={labelStyles} for="name">
                      Cardholder's Name
                    </Label>
                    <Input
                      className="bg-light rounded-0 p-0 pb-2 input"
                      type="text"
                      name="name"
                      id="name"
                      placeholder="First and Last Name"
                      required
                    />
                    {/* <Label className="pt-3" style={labelStyles} for="email">
                  Cardholder's Email
                </Label>
                <Input
                  className="bg-light rounded-0 p-0 pb-2 input"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  required
                /> */}
                    <Label className="pt-4" style={labelStyles} for="number">
                      Card Number
                    </Label>
                    <div className="input">
                      <CardNumberElement
                        id="number"
                        className="pb-2"
                        options={cardStyle}
                      />
                    </div>
                    <Row>
                      <Col md={6}>
                        <Label className="pt-4" style={labelStyles} for="date">
                          Card Expiry Date
                        </Label>
                        <div>
                          <CardExpiryElement id="date" className="pb-2 input" />
                        </div>
                      </Col>
                      <Col md={6}>
                        <Label className="pt-4" style={labelStyles} for="code">
                          Security Code CVC / CVV
                        </Label>
                        <div className="input">
                          <CardCvcElement id="code" className="pb-2" />
                        </div>
                      </Col>
                    </Row>
                    <Label
                      className="pt-3"
                      style={labelStyles}
                      for="couponCode"
                    >
                      Coupon Code
                    </Label>
                    <Input
                      className="bg-light rounded-0 p-0 pb-2 input"
                      type="text"
                      name="couponCode"
                      id="couponCode"
                      placeholder=""
                    />

                    {/* <script>$(".alert").alert();</script> */}

                    <Col sm="12">
                      <div className="text-sm-start mt-4">
                        {error || success ? (
                          <div
                            className={`alert alert-dismissible fade show ${
                              success ? "alert-success" : "alert-danger"
                            }`}
                            role="alert"
                          >
                            {error ? (
                              <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                                onClick={() => {
                                  setError(false)
                                }}
                              ></button>
                            ) : null}
                            <strong>{`${success ? "" : "Error"}  ${
                              success ? successMessage : "Card Declined"
                            } `}</strong>
                          </div>
                        ) : null}

                        {loading ? (
                          <Button
                            disabled={true}
                            style={{
                              background: "#02C6FF",
                              border: "none",
                              color: "white",
                            }}
                            size="lg"
                            block
                            className="d-flex justify-content-center align-items-center"
                          >
                            <ReactLoading
                              type={"spin"}
                              color={"white"}
                              height={30}
                              width={30}
                            />
                          </Button>
                        ) : (
                          <Button
                            type="submit"
                            style={{
                              background: "#02C6FF",
                              border: "none",
                              color: "white",
                            }}
                            size="lg"
                            block
                            className="rounded-1 mt-4 d-flex justify-content-around align-items-center"
                          >
                            Complete Payment
                          </Button>
                        )}
                      </div>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  )
}
// <!-- End Payment Details -->
function paymentDetail() {
  const navigate = useNavigate()
  const authUser =
    useSelector(state => state.Auth.authUser) ??
    JSON.parse(localStorage.getItem("authUser"))
  const [isOpen, setIsOpen] = useState(false)
  const [plan, setPlan] = useState()
  const [selectedPlan, setSelectedPlan] = useState()
  const toggle = () => setIsOpen(!isOpen)
  // var stripePromise = useMemo(async () => {
  //   return await loadStripe(process.env.REACT_APP_STRIPE_KEY)
  // }, [])

  useLayoutEffect(() => {
    //Not Email Payment Link
    if (uuid) return
    //User Not Logged In
    if (!localStorage.getItem("token")) return navigate("/logout")

    // <!--------------------------!>
    //User Logged In  - Checking User plan

    const selectedPlanId = authUser?.user?.selectedPlanId
    const userPlan = authUser?.company?.plan

    //User Logged In  - User has plan
    // if (userPlan) return navigate("/")

    //User Logged In  - User has no plan
    if (selectedPlanId) {
      setSelectedPlan(selectedPlanId)
      setPlan(selectedPlanId)
    }
    // Open Modal to Select Plan
    setIsOpen(true)
    // <!--------------------------!>
  }, [])

  return (
    <div className="" style={{ height: "100vh" }}>
      <StripePromise>
        <PaymentForm plan={plan} authUser={authUser} />
      </StripePromise>
      <Modal
        keyboard={false}
        backdrop="static"
        isOpen={isOpen}
        size="xl"
        centered
        toggle={toggle}
      >
        <div className="modal-body" style={{ backgroundColor: "#ebfcfc" }}>
          <div className="d-flex justify-content-center">
            <div style={{ width: "95%" }}>
              <Row>
                <Col className="col-12 d-flex">
                  <BacktoAgency />
                </Col>
                <Col className="col-12 my-3">
                  <h1 className="text-center" style={{ fontSize: "2.2rem" }}>
                    <b>Select Plan</b>
                  </h1>
                </Col>
                <Col
                  style={{ cursor: "pointer" }}
                  className="mb-4 d-flex col-md-12 col-lg-4 col-12"
                >
                  <div
                    className="card payment-card round-card flex-grow-1"
                    style={{ borderRadius: "20px" }}
                  >
                    <div className="card-body text-center d-flex flex-column align-items-center">
                      <h1>
                        <b>Basic</b>
                      </h1>
                      <h3 className="card-subtitle text-muted mb-4">
                        $99 /Month
                      </h3>
                      <button
                        onClick={() => {
                          setSelectedPlan(1)
                          setPlan(1)
                          toggle()
                        }}
                        className="mb-4 mt-3 col-10 btn btn-lg btn-rounded rounded-pill bg-outline-mahalo"
                      >
                        <h4 className="m-0">
                          <b>Select</b>
                        </h4>
                      </button>
                      <p className="fLarge">$297 Billed Quarterly</p>
                      <p className="fLarge">$1,069 Billed Annually</p>
                      <p className="fLarge">(10% Discount)</p>
                      {(selectedPlan == 1 || selectedPlan == 2) && (
                        <Badge
                          pill
                          color="primary"
                          className="mb-3 mt-3 custom-badge"
                        >
                          <p className="m-0" style={{ fontSize: "15px" }}>
                            Current Plan
                          </p>
                        </Badge>
                      )}
                    </div>
                  </div>
                </Col>
                <Col
                  style={{ cursor: "pointer" }}
                  className="mb-4 d-flex col-md-12 col-lg-4 col-12"
                >
                  <div
                    className="card payment-card round-card flex-grow-1"
                    style={{
                      borderRadius: "20px",
                      backgroundColor: "#20394A",
                      color: "white",
                    }}
                  >
                    <div className="card-body d-flex flex-column align-items-center">
                      <h1>
                        <b>Plus</b>
                      </h1>
                      <h3 className="card-subtitle mb-4">$149 /Month</h3>
                      <button
                        onClick={() => {
                          setSelectedPlan(3)
                          setPlan(3)
                          toggle()
                        }}
                        className="mb-4 mt-3 col-10 btn btn-lg btn-rounded rounded-pill bg-outline-mahalolight"
                      >
                        <h4 className="m-0">
                          <b>Select</b>
                        </h4>
                      </button>
                      <p className="fLarge">$447 Billed Quarterly</p>
                      <p className="fLarge">$1,609 Billed Annually</p>
                      <p className="fLarge">(10% Discount)</p>
                      {selectedPlan == 3 || selectedPlan == 4 ? (
                        <Badge
                          pill
                          color="primary"
                          className="mb-3 mt-3 custom-badge"
                        >
                          <p className="m-0" style={{ fontSize: "15px" }}>
                            Current Plan
                          </p>
                        </Badge>
                      ) : (
                        <Badge
                          pill
                          color="warning"
                          className="mb-3 mt-3 custom-badge"
                        >
                          <p className="m-0" style={{ fontSize: "15px" }}>
                            Most Popular
                          </p>
                        </Badge>
                      )}
                    </div>
                  </div>
                </Col>
                <Col
                  style={{ cursor: "pointer" }}
                  className="mb-4 d-flex col-md-12 col-lg-4 col-12"
                >
                  <div
                    className="card payment-card round-card flex-grow-1"
                    style={{ borderRadius: "20px" }}
                  >
                    <div className="card-body d-flex flex-column align-items-center">
                      <h1>
                        <b>Pro</b>
                      </h1>
                      <h4 className="card-subtitle mb-4 text-muted">
                        $249 /Month
                      </h4>
                      <button
                        onClick={() => {
                          setSelectedPlan(5)
                          setPlan(5)
                          toggle()
                        }}
                        className="mb-4 mt-3 col-10 btn btn-lg btn-rounded rounded-pill bg-outline-mahalo"
                      >
                        <h4 className="m-0">
                          <b>Select</b>
                        </h4>
                      </button>
                      <p className="fLarge">$747 Billed Quarterly</p>
                      <p className="fLarge">$2,689 Billed Annually</p>
                      <p className="fLarge">(10% Discount)</p>
                      {(selectedPlan == 5 || selectedPlan == 6) && (
                        <Badge
                          pill
                          color="primary"
                          className="mb-3 mt-3 custom-badge"
                        >
                          <p className="m-0" style={{ fontSize: "15px" }}>
                            Current Plan
                          </p>
                        </Badge>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="text-center mb-4 d-flex gap-2 justify-content-center col-12">
                {!localStorage.getItem("superAgency") && (
                  <Button
                    className="btn-mahalo"
                    onClick={() => {
                      navigate("/logout")
                    }}
                  >
                    Logout
                  </Button>
                )}
                <Button
                  className="btn-mahalo"
                  onClick={() => {
                    window.open("https://www.mahalohub.com/pricing", "_blank")
                  }}
                >
                  Plan Details
                </Button>
                {selectedPlan && (
                  <Button
                    className="btn-mahalo"
                    onClick={() => {
                      setIsOpen(false)
                      setPlan(selectedPlan)
                    }}
                    disabled={!selectedPlan}
                  >
                    Confirm
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default paymentDetail
