import React, { useEffect } from "react"
import { useCacheBuster } from "react-cache-buster"
import { useSelector } from "react-redux"

function checkCacheVersion() {
  const { checkCacheStatus } = useCacheBuster()
  const auth = useSelector(state => state.Auth)
  useEffect(() => {
    if (!auth.token) return
    if (!auth.token == localStorage.getItem("token")) return
    //Check cache status in timer seconds
    let timer = 0
    const interval = setInterval(() => {
      if (timer === 0) {
        console.log("Checking for new Version...")
        checkCacheStatus()
        clearInterval(interval)
      } else {
        console.log(`Checking cache status in ${timer} seconds...`)
        timer--
      }
    }, 1000)
  }, [auth.token])
  return null
}

export default checkCacheVersion
