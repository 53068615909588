import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  INIT_ADMIN_AUTH,
  INIT_AUTH,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  SWITCH_AGENCY,
  SWITCH_USER,
} from "./actionTypes"
import {
  apiError,
  initAdminAuthFailure,
  initAdminAuthSuccess,
  initAuthFailure,
  initAuthSuccess,
  loginSuccess,
  logoutUserSuccess,
  paymentVerifiedFailure,
  switchUserSuccess,
} from "./actions"
import { getUserDetails } from "Modules/User/api"
import { getAdminDetails } from "Modules/Admin/api"
import SwitchUser from "pages/Dashboard/Subscriber/SubscriberDataTable/components/Columns/Actions/SwitchUser"
import { closeAdminSession, closeSession, refreshSession } from "helpers/utils"

// function* loginUserSaga({ payload: { user } }) {
//   try {
//     if (user) {
//       console.log("saga", user)
//       // yield put(loginSuccess(user))
//     } else {
//       // yield put(apiError("User not found"))
//     }
//   } catch (error) {
//     // yield put(apiError(error))
//   }
// }

function* logoutUserSaga() {
  closeSession()
  yield put(logoutUserSuccess())
}

function* InitializeAuthenticatedUserData({ payload: { token } }) {
  try {
    const response = yield call(getUserDetails, token)
    if (response?.data) {
      refreshSession(token)
      yield put(initAuthSuccess(response.data))
    } else {
      // if token is valid but user's payment is failed
      if (response?.response_type) {
        yield put(initAuthFailure(response.response_type))
      } else {
        console.log("response", response)
        yield put(initAuthFailure(response?.message))
        closeSession()
      }
    }
  } catch (error) {
    yield put(initAuthFailure(error))
  }
}

function* InitializeAuthenticatedAdminData({ payload: { token } }) {
  try {
    const response = yield call(getAdminDetails, token)
    if (response.data) {
      const data = {
        success: true,
        message: "Logged in successfully",
        user: {
          id: 1,
          temp_token: null,
          selectedPlanId: null,
          fullName: "Admin",
          email: "admin@mahalohub.com",
          isFresh: "1",
          selectedCampaign: null,
          address: null,
          email_verified_at: "2024-04-30T07:47:59.000000Z",
          isSystemAdmin: 1,
          created_at: "2024-04-30T07:47:59.000000Z",
          updated_at: "2024-04-30T07:47:59.000000Z",
          stripe_id: null,
          pm_type: null,
          pm_last_four: null,
          trial_ends_at: null,
        },
        token: "31|nltuGz8ompXEXmWSaXK7HcM21GRs0efknF1xRF2895d08312",
      }
      yield put(initAdminAuthSuccess(data))
    } else {
      // if token is not valid
      yield put(initAdminAuthFailure(response.message))
      closeAdminSession()
    }
  } catch (error) {
    yield put(initAdminAuthFailure(error))
  }
}

function* switchUserSaga({ payload }) {
  try {
    // const response = yield call(getUserDetails, token)
    if (payload) {
      localStorage.setItem("authUser", JSON.stringify(payload))
      refreshSession(payload.token)
      yield put(switchUserSuccess(payload))
    }
  } catch (error) {
    yield put(initAuthFailure(error))
  }
}

function* switchAgencySaga({ payload }) {
  try {
    if (!payload?.company?.plan) {
      refreshSession(payload.token)
      localStorage.setItem("authUser", JSON.stringify(payload))
    }
  } catch (error) {
    yield put(initAuthFailure(error))
  }
}

function* authSaga() {
  yield all([
    // takeEvery(LOGIN_USER, loginUserSaga),
    takeEvery(INIT_AUTH, InitializeAuthenticatedUserData),
    takeEvery(INIT_ADMIN_AUTH, InitializeAuthenticatedAdminData),
    takeEvery(SWITCH_USER, switchUserSaga),
    takeEvery(LOGOUT_USER, logoutUserSaga),
    takeEvery(SWITCH_AGENCY, switchAgencySaga),
  ])
}

export default authSaga
