import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { resendEmail } from "services/auth/auth"
import { useNavigate } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap"
import logo from "../../assets/images/Site Logos/Logo.png"
// import images
import ReactLoading from "react-loading"

import Swal from "sweetalert2"
import withTitle from "Shared/helpers/withTitle"
import { useDispatch, useSelector } from "react-redux"
import { logoutUser } from "store/actions"
import { toast } from "react-toastify"

const EmailVerify = () => {
  //meta title
  const dispatch = useDispatch()
  const [isSubmitting, setIsSubmitting] = useState(false)

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-5 text-muted">
                <Link to="/" className="d-block auth-logo">
                  <img
                    src={logo}
                    alt=""
                    height="50"
                    className="auth-logo-dark mx-auto"
                  />
                  <img
                    src={logo}
                    alt=""
                    height="50"
                    className="auth-logo-light mx-auto"
                  />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Verify your email</h4>
                        <p>
                          We’ve sent you an email to verify it’s really you.
                        </p>
                        <div className="mt-4">
                          {/* <Button
                            style={{
                              border: "none",
                              color: "white",
                              fontSize: "0.75rem",
                            }}
                            className="btn-rounded btn-mahalo"
                          >
                            Check Email
                          </Button> */}
                        </div>
                        <div className="mt-4 d-flex justify-content-center">
                          <p className="m-0">Didn't receive the link? </p>
                          <button
                            className={`btn-clear ${
                              !isSubmitting ? "text-mahalo" : "text-mahalo-red"
                            }`}
                            disabled={isSubmitting}
                            onClick={e => {
                              e.preventDefault()
                              setIsSubmitting(true)
                              toast
                                .promise(resendEmail(), {
                                  pending: "Sending verification email...",
                                  success:
                                    "Verification email sent successfully.",
                                  error:
                                    "An error occurred while sending verification email. Please try again later.",
                                })
                                .finally(() => {
                                  setIsSubmitting(false)
                                })
                            }}
                          >
                            Resend
                          </button>
                        </div>
                        <div className="mt-3 d-flex justify-content-center">
                          <button
                            className="btn btn-sm btn-mahalo-green btn-rounded text-white"
                            onClick={e => {
                              e.preventDefault()
                              dispatch(logoutUser())
                            }}
                          >
                            Back to Login
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} MahaloHub LLC</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withTitle(EmailVerify, "Email Verification")
