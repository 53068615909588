import { axiosRequest } from "services"

const apis = {
  getAgencyClients: config => ({
    endpoint: `api/agency/clients`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      ...config,
    },
    trueMessage: null,
    falseMessage: "default",
    errorMessage: "default",
  }),
  createCompanyClient: (formdata, config) => ({
    endpoint: `api/agency/create-company`,
    method: "post",
    data: formdata,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      ...config,
    },
    trueMessage: "default",
    falseMessage: "default",
    errorMessage: "default",
  }),
  requestCompanyOwnership: (formdata, config) => ({
    endpoint: `api/agency/request-company-ownership`,
    method: "post",
    data: formdata,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      ...config,
    },
    trueMessage: "default",
    falseMessage: "default",
    errorMessage: "default",
  }),
  getAgencyRequests: config => ({
    endpoint: `api/agency/transfer-ownership-requests`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      ...config,
    },
    trueMessage: null,
    falseMessage: null,
    errorMessage: null,
  }),
}

export const getAgencyClients = async (config = {}) => {
  return await axiosRequest(apis.getAgencyClients(config))
}

export const createCompanyClient = async (formdata, config = {}) => {
  return await axiosRequest(apis.createCompanyClient(formdata, config))
}
//Request Workflow
export const requestCompanyOwnership = async (formdata, config = {}) => {
  return await axiosRequest(apis.requestCompanyOwnership(formdata, config))
}
//Requests
export const getAgencyRequests = async (config = {}) => {
  return await axiosRequest(apis.getAgencyRequests(config))
}
