import React from "react"

function AnimatedLoader() {
  return (
    <div id="preloader" style={{ display: "block" }}>
      <div id="status" style={{ display: "block" }}>
        <div className="spinner-chase">
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
        </div>
      </div>
    </div>
  )
}

export default AnimatedLoader
