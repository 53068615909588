// import "../../src/elements/VerticalLayout/index.css"
//imports
import React, { lazy } from "react"

//Manage Pages
const Manage = lazy(() => import("../pages/Dashboard/Manage/AdminManage"))
//Subscribers
const Subscribers = lazy(() =>
  import("../pages/Dashboard/Subscriber/Subscribers")
)

//Routes
const adminRoutes = [
  //Manage
  { path: "/manage", element: <Manage /> },
  //Subscribers
  { path: "/subscribers", element: <Subscribers /> },
]

export default adminRoutes
