import address from "../../services/address"
import axios from "axios"

const axiosApi = axios.create({
  baseURL: address,
})

export async function cancelSubscription() {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.post(
      `/api/subscriber/subscription/cancel`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}

export async function AssignRoles(
  campaignId,
  subuserId,
  role,
  currentRoleName,
  currentRoleId
) {
  const token = localStorage.getItem("token")

  if (token) {
    if (currentRoleId === "4") {
      let url =
        currentRoleName === "Manager"
          ? `/api/subscriber/campaigns/${campaignId}/manager/${subuserId}/remove`
          : `/api/subscriber/campaigns/${campaignId}/subscriber/${subuserId}/remove`

      try {
        const { data } = await axiosApi.delete(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })

        return data
      } catch (error) {
        console.error("Error deleting manager:", error)

        return false
      }
    } else {
      try {
        const { data } = await axiosApi.post(
          `/api/subscriber/campaigns/${role}/assign`,
          { campaignId, subuserId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )

        return data
      } catch (error) {
        console.error("Error assigning role:", error)
        // Handle error if needed
        return null
      }
    }
  }

  // Handle case when token is not available
  console.error("Token not available")
  return null
}

export async function getUserDetail(config = {}) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.get(`/api/profile/user/details`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      ...config,
    })
    if (data.activeCampaign) {
      localStorage.setItem("playlistId", data.activeCampaign.playlistId)
      localStorage.setItem("widgetlistId", data.activeCampaign.widgetlistId)
    }

    return data
  }
}
export async function getUserByToken(token) {
  const { data } = await axiosApi.get(`/api/user/${token}/get`, {
    headers: {
      "Content-Type": "application/json",
    },
  })
  return data
}
export async function updateProfile(formdata) {
  const token = localStorage.getItem("token")

  if (token) {
    const { data } = await axiosApi.post(
      `/api/profile/user/0?_method=PUT`,
      formdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}
export async function updateUser(id, formdata) {
  const token = localStorage.getItem("token")

  if (token) {
    const { data } = await axiosApi.post(
      `/api/subscriber/campaigns/update/${id}?_method=PUT`,
      formdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",

          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}
export async function updatePassword(formdata) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.put(
      `/api/profile/user/updatePassword`,
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}
const allapis = {
  getUserDetail,
  updateUser,
}
export default allapis

export async function deleteVideo(id) {
  const token = localStorage.getItem("token")
  if (token) {
    const response = await axiosApi.delete(
      `/api/subscriber/client/testimonial/delete/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.status === 204
  }
}
