import React, { useState, useEffect, useMemo } from "react"
//components
import { CampaignViewer, AssignedCampaignsMenu } from "./CampaignViewAccess"
//dependencies
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
} from "reactstrap"
//services
import {
  useImage,
  useImageUpdate,
  useUser,
} from "../../VerticalLayout/ImageContextProvider"
// users
import user1 from "../../../assets/images/userAvatar.png"
// Redux
import { Link } from "react-router-dom"

//css
import "./ProfileMenu.css"

import isMultiUser from "Shared/utils/isMultiUser"
import isAdmin from "Shared/utils/isAdmin"
import isUser from "Shared/utils/isUser"

const ProfileMenu = props => {
  const user = useUser()
  const multi = isMultiUser()
  const isAdminUser = isAdmin()
  const isUserUser = isUser()
  const [menu, setMenu] = useState(false)
  const [campaignMenu, setCampaignMenu] = useState(false)
  const [username, setusername] = useState()

  useEffect(() => {
    if (user) {
      setusername(user?.activeCampaign?.name)
    } else {
      setusername("")
    }
  }, [user])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <span className=" ms-2 me-1 d-none d-md-inline-block">
            {username}
          </span> */}
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className=" ms-2 me-1  d-block d-md-inline-block">
            {username?.length > 15 ? `${username?.slice(0, 15)}...` : username}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>

        <DropdownMenu
          className={`profile-dropdown overflow-hidden ${
            campaignMenu ? "active" : ""
          } dropdown-menu-end `}
        >
          <div className="body d-flex flex-row">
            <div className="menu">
              {!isAdminUser && (
                <CampaignViewer setCampaignMenu={setCampaignMenu} />
              )}
              {!isAdminUser && (
                <>
                  <Link to="/user/profile">
                    <DropdownItem>
                      {" "}
                      <i className="bx bx-user font-size-16 align-middle me-1" />
                      {multi ? " User Profile" : "Profile"}
                    </DropdownItem>
                  </Link>
                  {user?.campaignUserRole !== 3 &&
                  user?.campaignUserRole !== 4 ? (
                    <Link to="/user/settings">
                      <DropdownItem>
                        <i className="bx bx-cog font-size-16 align-middle me-1" />
                        {multi ? " User Settings" : "Settings"}
                      </DropdownItem>
                    </Link>
                  ) : null}
                </>
              )}

              <div className="dropdown-divider" />
              <Link to={"/logout"} className="dropdown-item">
                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                <span> {multi ? " User Logout" : "Logout"}</span>
              </Link>
            </div>
            {campaignMenu && (
              <div className="menu px-2">
                <div className="d-flex flex-row align-items-center gap-3 p-1 px-2">
                  <button
                    className=" text-dark btn btn-sm btn-rounded rounded-circle btn-outline-light border-0"
                    onClick={() => {
                      setCampaignMenu(!campaignMenu)
                    }}
                  >
                    <i
                      style={{ fontSize: "12" }}
                      className="fas fa-arrow-left m-0 p-0"
                    />
                  </button>
                  <h4 className="m-0 h5 m-0">
                    <b>Select Campaigns</b>
                  </h4>
                </div>
                <AssignedCampaignsMenu />
              </div>
            )}
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default ProfileMenu
