import address from "../../services/address"
import axios from "axios"
import { companies } from "assets/images/companies"
import Success from "./../../pages/Utility/askResponse"
import Swal from "sweetalert2"
import services, {
  selectCampaignForUser,
} from "components/CommonForBoth/TopbarDropdown/services"
import Toast from "services/toast"

const axiosApi = axios.create({
  baseURL: address,
})

export async function login(formdata) {
  //data variable
  const companyId = formdata.companyId
  let loginFormData = ""
  let res = ""
  //data variable
  try {
    if (!companyId) {
      res = await axiosApi.post("/api/login/user/companies", formdata)
      if (res.data.token) return res.data
      if (!res.data.success) return res.data
      const totalCompanies = res.data.companies.length
      if (totalCompanies > 1) {
        return res.data
      } else {
        loginFormData = {
          email: formdata.email,
          password: formdata.password,
          companyId: res.data.companies[0].id,
        }
      }
    } else {
      loginFormData = formdata
    }
    // <!-Login to Campaign-->

    ///
    const { data } = await axiosApi.post("/api/login", loginFormData)
    //Check in which Campaign to Login in
    if (
      data.success
      //&& data.assignedCampaigns?.length
    ) {
      return data
    } else {
      if (
        data.message ===
        "Your campaign is inactive kindly contact administrator."
      ) {
        Swal.fire({
          icon: "info",
          title: "No Active Campaign!",
          text: "Please contact your admin to activate your campaign.",
        })
      } else {
        if (data.message === "These credentials do not match our records") {
          Swal.fire({
            icon: "info",
            title: "Credentials not matched!",
            text: "Please set up your account via the link sent to you by Email.",
          })
        } else {
          Swal.fire({
            icon: "info",
            text: data.message,
          })
        }
      }
      return false
    }
  } catch (error) {
    // Handle any errors here
    if (error?.message) {
      Toast.fire({
        title: error?.message ?? "Something went wrong",
        text: "Please try again later",
        icon: error?.message ? "info" : "warning",
      })
      return error
    } else {
      console.log("Login Error:", error)
    }
  }
}

export async function register(formdata) {
  const { data } = await axiosApi.post("/api/register", formdata)
  if (data.token) {
    localStorage.setItem("token", data.data["token"])
    localStorage.setItem("isFresh", "1")
    localStorage.setItem("isUser", "user")
  }
  return data
}
export async function sendResetEmail(formdata) {
  const { data } = await axiosApi.post("/api/forgot-password", formdata)
  return data
}
export async function resetPassword(token, email, formdata) {
  const { data } = await axiosApi.post(
    `/api/password/reset?token=${token}&email=${email}`,
    formdata,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  )

  return data
}

export async function ResetPassword(id, email, formdata) {
  const { data } = await axiosApi.post(
    `/api/subscriber/campaigns/user/password/set/${id}/${email}`,
    formdata,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  )

  return data
}

export async function logout() {
  const { data } = await axiosApi.post(
    `/api/logout`,

    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  )

  return data
}

export async function resendEmail() {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.post(
      "/api/email/verification-notification",
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}

const allapis = {
  resendEmail,
  register,
  logout,
  login,
}
export default allapis
