// import "../../src/elements/VerticalLayout/index.css"
//imports
import React, { lazy } from "react"
//CompanyUsers
const CompanyUsers = lazy(() => import("../pages/Dashboard/CompanyUsers"))
//Agency
const Agency = lazy(() => import("../pages/Dashboard/Agency"))
//Campaigns Page
const Campaign = lazy(() => import("../pages/Dashboard/Campaign"))
//Rewards Page
const Rewards = lazy(() => import("../pages/Dashboard/Rewards/Rewards"))

//Routes
const subscriberManagerRoutes = [
  //Company Users
  { path: "users", element: <CompanyUsers /> },
  //Reward
  { path: "reward", element: <Rewards /> },
]

export const subscriberManagerPermission = [2, 3]

export default subscriberManagerRoutes
