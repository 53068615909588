import Show from "components/Shared/Show"
import React from "react"
import { Navigate, Outlet } from "react-router-dom"

function PersistantUser({ children }) {
  const superToken = localStorage.getItem("superToken")
  const token = localStorage.getItem("token")
  return (
    <Show
      when={!superToken && !token}
      show={<Outlet />}
      or={
        <Show
          when={superToken && !token}
          show={<Navigate to="/manage" />}
          or={
            <Show
              when={token && !superToken}
              show={<Navigate to="/user/dashboard" />}
              or={
                <Show
                  when={token && superToken}
                  show={<Navigate to="/user/dashboard" />}
                />
              }
            />
          }
        />
      }
    />
  )
}

export default PersistantUser
