import { dynamicSilentRequest } from "services"
const endpoints = {
  getAllAssignedCampaigns: {
    url: "/api/subscriber/campaigns/user/assigned-campaigns",
    method: "get",
    trueMessage: "",
    falseMessage: "default",
    errorMessage: "default",
  },
  selectCampaignForUser: {
    url: "/api/subscriber/campaigns/user/campaigns",
    v1: "select",
    method: "post",
    trueMessage: "Campaign Selected Successfully",
    falseMessage: "default",
    errorMessage: "default",
  },
}

export const { getAllAssignedCampaigns, selectCampaignForUser } = endpoints

export default function services(endpoint, formData) {
  return dynamicSilentRequest(
    endpoint.url,
    endpoint.method,
    formData,
    endpoint.trueMessage,
    endpoint.falseMessage,
    endpoint.errorMessage,
    endpoint.v1
  )
}
