import { useClearData } from "components/VerticalLayout/ImageContextProvider"
import { queryClient } from "index"

function clearUserData() {
  const clearData = useClearData()
  const hook = () => {
    clearData()
    queryClient.clear()
  }

  return hook
}

export default clearUserData
