import React, { useEffect, useState } from "react"
//dependencies
import { useNavigate } from "react-router"
import ReactLoading from "react-loading"
import { Button } from "reactstrap"
//services
import { switchUser } from "services/admin/Subscriber/subscriber"
import { useDispatch } from "react-redux"
import { initAuth, switchUserAction } from "store/actions"
import { useSelector } from "react-redux"
import { useClearData } from "components/VerticalLayout/ImageContextProvider"
import { queryClient } from "index"
function SwitchUser({ id }) {
  const navigate = useNavigate()
  const [isSwitching, setIsSwitching] = useState(false)
  const [activeUser, setActiveUser] = useState()
  const dispatch = useDispatch()
  const clearData = useClearData()

  const auth = useSelector(state => state.Auth)

  return (
    <Button
      className="btn-mahalo"
      size="sm"
      onClick={async () => {
        if (isSwitching) return
        setActiveUser(id)
        setIsSwitching(true)
        await switchUser(id)
          .then(res => {
            setIsSwitching(false)
            if (res?.token) {
              //dispatch
              clearData()
              queryClient.clear()
              dispatch(switchUserAction(res))
            }
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            setIsSwitching(false)
            setActiveUser(null)
          })
      }}
    >
      {isSwitching && activeUser == id ? (
        <ReactLoading type={"spin"} color={"white"} height={20} width={20} />
      ) : (
        <i className="fas fa-sign-in-alt"></i>
      )}
    </Button>
  )
}

export default SwitchUser
