import address from "../../services/address"
import axios from "axios"

const axiosApi = axios.create({
  baseURL: address,
})

export async function addTestimonial(token, formdata) {
  const { data } = await axiosApi.post(
    `/api/record/${token}/createTestimonial`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )

  return data
}
export async function replaceTestimonial(id, apiKey, formdata) {
  const { data } = await axios.post(
    `https://api.sproutvideo.com/v1/videos/${id}/replace?api_key=${apiKey}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )

  return data
}
export async function getClientTestimonials(token) {
  const { data } = await axiosApi.get(
    `/api/record/client/${token}/getClientTestmonials`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  )

  return data
}
export async function updateTestimonial(id, token, formdata) {
  const { data } = await axiosApi.put(
    `/api/record/${token}/updateTestimonial/${id}`,
    formdata,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return data
}
export async function getTestimonialVideo(id, config = {}, apiToken) {
  const token = apiToken || localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.get(
      `/api/subscriber/client/testimonial/video/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        ...config,
      }
    )
    return data
  }
}
export async function downTestimonialVideo(id, config = {}, apiToken) {
  const token = apiToken || localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.get(
      `/api/subscriber/client/testimonial/video/download/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        ...config,
      }
    )
    return data
  }
}

export async function getAllClientsTestimonials() {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.get(`/api/subscriber/client/testimonials`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    return data
  }
}
export async function getTestimonialNotification(config = {}) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.get(`/api/subscriber/unreadNotifications`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      ...config,
    })
    return data
  }
}
export async function markAsRead(id) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.post(
      `/api/subscriber/markReadNotifications/${id}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}

export async function getPlaylistVideos(playlistId) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.get(
      `/api/subscriber/testimonials/playlist/${playlistId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}

export async function editPlaylist(formData) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.put(
      `/api/subscriber/testimonials/playlist/update`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}
export async function deleteVideo(id) {
  const token = localStorage.getItem("token")
  if (token) {
    const response = await axiosApi.delete(
      `/api/subscriber/client/testimonial/delete/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.status === 204
  }
}
export async function QRCodeToken(formdata) {
  const { data } = await axiosApi.post(
    `/api/record/client/ask/qrcode`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )

  return data
}

export async function uploadThumbnail(formdata, token) {
  const url = `/api/record/${token}/testimonial/thumbnail/upload`
  const { data } = await axiosApi.post(url, formdata, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  return data
}

export async function publishPoster(token) {
  const url = `/api/record/${token}/testimonial/thumbnail/upload/sprout`
  const { data } = await axiosApi.post(url, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  return data
}
function getFormattedFileName(file) {
  const date = new Date()
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, "0")
  const day = date.getDate().toString().padStart(2, "0")
  const hours = date.getHours().toString().padStart(2, "0")
  const minutes = date.getMinutes().toString().padStart(2, "0")
  const seconds = date.getSeconds().toString().padStart(2, "0")
  const formattedDateTime = `${year}_${month}_${day}_${hours}_${minutes}_${seconds}`
  const extension = file.type.toString().includes("webm") ? ".webm" : ".mp4"
  return `${formattedDateTime}_o${extension}`
}

export async function UploadVideoToLibrary(formdata, questionid) {
  return new Promise(async (resolve, reject) => {
    try {
      //data to request presigned Url
      const token = localStorage.getItem("token")
      const { file, full_name, email } = formdata
      const fileName = getFormattedFileName(file)
      let clientId
      const url = `/api/subscriber/client/testimonial/upload-url`
      const { data } = await axiosApi.post(
        url,
        {
          questionId: questionid,
          clientName: full_name,
          clientEmail: email,
          filename: fileName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if (data?.success) {
        clientId = data.client
        const uploadUrl = data.videoUploadUrl
        try {
          const response = await axiosApi.put(uploadUrl, file, {
            headers: {
              "Content-Type": file.type,
            },
          })
          if (response.status === 200) {
            await UploadTestimonialtoLibrary(formdata, questionid)
          } else {
            reject("Failed to upload file to S3")
          }
        } catch (error) {
          return reject(error)
        }
      } else {
        return reject(data?.message || "Failed to get video upload URL")
      }

      async function uploadFiletoS3(uploadUrl, file) {}
      async function UploadTestimonialtoLibrary(formdata, questionid) {
        const url = `/api/subscriber/client/testimonial/upload`
        try {
          const { data } = await axiosApi.post(
            url,
            {
              questionId: questionid,
              clientName: full_name,
              clientEmail: email,
              filename: fileName,
              clientId: clientId,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          if (data?.success) {
            return resolve(data)
          } else {
            return reject(data.message || "Failed to upload video to library")
          }
        } catch (error) {
          return reject(error)
        }
      }
    } catch (error) {
      return reject(error)
    }
  })
}
