import React, { useEffect, useState } from "react"
//Components
import SidebarContent from "./SidebarContent"
import UserSideBarContent from "./UserSideBarContent"
//Dependencies
import { Link } from "react-router-dom"
//Assets
import "./Sidebar.css"
import logosm from "assets/images/logosm.png"
import mahalologo from "../../assets/images/Site Logos/Logo.png"

const Sidebar = props => {
  const [url, setUrl] = useState()
  useEffect(() => {
    const isUser = localStorage.getItem("isUser")
    if (isUser === "admin") {
      setUrl("/manage")
    } else if (isUser === "user") {
      setUrl("/user/manage")
    }
  }, [])

  return (
    <React.Fragment>
      <div className="vertical-menu navbar-mahalo">
        <div className="navbar-brand-box">
          <Link to={url} className="logo logo-dark">
            <span className="logo-sm">
              <img src={logosm} alt="" height="28" />
            </span>
            <span className="logo-lg">
              <img src={logosm} alt="" height="32" />
            </span>
          </Link>

          <Link to={url} className="logo logo-light">
            <span className="logo-sm">
              <img src={logosm} alt="" height="28" />
            </span>
            <span className="logo-lg">
              <img src={mahalologo} alt="" height="34" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100 nav-body">
          {props.isUser == "admin" ||
          (!window.location.href.includes("/user/") &&
            localStorage.getItem("isUser") != "user" &&
            !window.location.href.includes("/profile")) ? (
            <SidebarContent />
          ) : (
            <UserSideBarContent />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Sidebar
