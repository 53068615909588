import { all, call, fork, put, takeLatest } from "redux-saga/effects"

import { GET_AGENCY_DATA } from "./actionTypes"
import {
  getAgencyDataSuccess,
  getAgencyDataFailure,
  getAgencyRequestsSuccess,
  getAgencyRequestsFailure,
} from "./actions"
import { getAgencyClients } from "./apis"

function* getAgency() {
  try {
    const response = yield call(getAgencyClients)
    yield put(getAgencyDataSuccess(response))
  } catch (error) {
    yield put(getAgencyDataFailure(error))
  }
}
function* getAgencyRequests() {
  try {
    const response = yield call(getAgencyClients)
    yield put(getAgencyRequestsSuccess(response))
  } catch (error) {
    yield put(getAgencyRequestsFailure(error))
  }
}

export function* watchGetAgency() {
  yield takeLatest(GET_AGENCY_DATA, getAgency)
}

export function* watchGetAgencyRequests() {
  yield takeLatest(GET_AGENCY_DATA, getAgencyRequests)
}

export default function* rootSaga() {
  yield all([fork(watchGetAgency), fork(watchGetAgencyRequests)])
}
