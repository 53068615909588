import { all, fork } from "redux-saga/effects"

//auth
import authSaga from "../Modules/AuthKit/store/saga"

//public
import LayoutSaga from "./layout/saga"

import dashboardSaga from "./dashboard/saga"

import EmailSaga from "./Emails/saga"

import AdvanceSearchSaga from "pages/Dashboard/AdvanceSearch/saga"

import AgencySaga from "pages/Dashboard/Agency/saga"

export default function* rootSaga() {
  yield all([
    //auth
    fork(authSaga),
    //public
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(EmailSaga),
    fork(AdvanceSearchSaga),
    fork(AgencySaga),
  ])
}
