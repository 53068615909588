import address from "../../services/address"
import axios from "axios"

const axiosApi = axios.create({
  baseURL: address,
})

export async function getAllClients() {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.get(`/api/client/getSubscriberClients`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    return data
  }
}

export async function sendReminderEmail(id, formdata) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.post(
      `/api/client/${id}/sendManualReminder`,
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}
export async function getInviteDetails(token) {
  const { data } = await axiosApi.get(`/api/record/${token}`, {
    headers: {
      "Content-Type": "application/json",
    },
  })

  return data
}
export async function getAskDetails(token) {
  const { data } = await axiosApi.get(`/api/askClientMailResponse/${token}`, {
    headers: {
      "Content-Type": "application/json",
    },
  })

  return data
}
export async function updateClient(token, formdata) {
  const { data } = await axiosApi.put(
    `/api/record/${token}/updateClientConsent`,
    formdata,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return data
}

export async function updateClientStatus(token, formdata) {
  const { data } = await axiosApi.put(
    `/api/record/${token}/updateClientStatus`,
    formdata,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return data
}

export async function sendDeclineEmail(id) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.post(
      `/api/client/${id}/consentDeclineMail`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )

    return data
  }
}
