import React, { lazy } from "react"

const MultipleOwnership = lazy(() => import("./MultipleOwnership"))
const TransferOwnership = lazy(() => import("./TransferOwnership"))
const FinalizeTransfer = lazy(() => import("./FinalizeTransfer"))

const pages = [
  {
    path: "/ownership-transfer-request",
    element: <TransferOwnership />,
  },
  {
    path: "/finalize-transfer",
    element: <FinalizeTransfer />,
  },
  {
    path: "/multiple-ownership-requests",
    element: <MultipleOwnership />,
  },
]

export default pages
