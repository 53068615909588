import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import { getAskDetails } from "services/Clients/Clients"
import mahalologo from "../../assets/images/Site Logos/Logo.png"
import ReactLoading from "react-loading"
import messagelogo from "../../assets/images/Icons/message.png"
const Success = () => {
  //meta title
  document.title = "Response"
  const [response, setResponse] = useState("")
  const [message, setMessage] = useState("")
  const [preloader, setPreloader] = useState(true)

  const url = window.location.href
  const apiUrl = "/api/askClientMailResponse/"
  const clientToken = url.substring(url.indexOf(apiUrl) + apiUrl.length)
  useEffect(() => {
    getAskDetails(clientToken).then(res => {
      setPreloader(false)
      if (res.success !== false) {
        if (clientToken.includes("maybe-later")) {
          setResponse("Thank you for submitting!")
          setMessage("Your response has been sent!")
        } else if (clientToken.includes("not-interested")) {
          setResponse("Thank you for submitting!")
          setMessage("Your response has been sent!")
        } else {
          setResponse("Thank You!")
          setMessage("Look for an email invitation shortly.")
        }
      } else {
        setResponse("Error!")
        setMessage("Your link has expired!")
      }
    })
  }, [])
  return (
    <React.Fragment>
      {preloader ? (
        <div
          style={{
            display: "flex",

            justifyContent: "center",
            marginTop: "40vh",
          }}
        >
          <h1>Fetching your response &nbsp;</h1>
          <ReactLoading
            type="spinningBubbles"
            color="#234256"
            height={35}
            width={35}
          />
        </div>
      ) : (
        <section
          className="d-flex justify-content-center align-items-md-center bg-white position-relative"
          style={{ height: "100vh", minHeight: "400px" }}
        >
          <div
            className="position-absolute w-100 d-flex justify-content-center"
            style={{
              top: "10%",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <img
              style={{ maxWidth: "20rem" }}
              src={mahalologo}
              alt=""
              className="img-fluid w-75"
            />
          </div>
          <div
            className="message-alert-center position-absolute w-100 d-flex flex-column text-center justify-content-center align-items-center gap-2 py-4"
            style={{ bottom: "50%", left: "0", transform: "translateY(50%)" }}
          >
            <img
              style={{ maxWidth: "4.5rem", mixBlendMode: "multiply" }}
              src={messagelogo}
              alt=""
              className="img-fluid w-50 py-2 mb-1"
            />
            <h1 className="" style={{ fontSize: "2.3rem" }}>
              <b>{response}</b>
            </h1>
            <h3 className="" style={{ fontSize: "1.8rem" }}>
              <b>{message}</b>
            </h3>
            {/* <Button
            type="submit"
            style={{
              background: "rgb(243, 6, 149)",
              background:
                "linear-gradient(50deg, rgba(243,6,149,1) 2%, rgba(139,71,186,1) 50%, rgba(3,154,234,1) 100%)",

              border: "none",
              color: "white",
            }}
            size="lg"
            className="rounded-1 d-flex justify-content-around align-items-center rounded-pill px-5 mt-3"
          >
            <span className=" h6 m-0 py-1" style={{ fontSize: "0.85rem" }}>
              <b>Go Home</b>
            </span>
          </Button> */}
          </div>
        </section>
      )}
    </React.Fragment>
  )
}

export default Success
