import { combineReducers } from "redux"

//Auth
import Auth from "../Modules/AuthKit/store/reducer"

// Front
import Layout from "./layout/reducer"

// Authentication
// import Login from "./auth/login/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

//Emails
import Emails from "./Emails/reducer"

// AdvanceSearch
import AdvanceSearch from "pages/Dashboard/AdvanceSearch/reducers"

// Agency
import Agency from "pages/Dashboard/Agency/reducers"

const rootReducer = combineReducers({
  //
  Auth,
  // public
  Layout,
  // Login,
  Dashboard,
  Emails,
  AdvanceSearch,
  Agency,
})

export default rootReducer
