import React, { useEffect } from "react"
import { useSelector } from "react-redux"

export function isAdmin() {
  return useSelector(state => state?.Auth?.authUser?.user?.isSystemAdmin)
}

export function isUser() {
  const userRoles = [2, 3, 4]
  const role = useSelector(state => state?.Auth?.authUser?.campaignUserRole)
  return userRoles.includes(role)
}

export function isSubscriber() {
  const userRoles = [2]
  const role = useSelector(state => state?.Auth?.authUser?.campaignUserRole)
  return userRoles.includes(role)
}

export function isManager() {
  const userRoles = [3]
  const role = useSelector(state => state?.Auth?.authUser?.campaignUserRole)
  return userRoles.includes(role)
}

export function isSubuser() {
  const userRoles = [4]
  const role = useSelector(state => state?.Auth?.authUser?.campaignUserRole)
  return userRoles.includes(role)
}

export function isAgency() {
  return useSelector(state => state?.Auth?.authUser?.company?.isAgency)
}

export function isOwner() {
  const authUser = useSelector(state => state?.Auth?.authUser)

  return authUser?.user?.id == authUser?.company?.user?.id
}
