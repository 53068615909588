export const isProduction = process.env.NODE_ENV === "production"

export const isDevelopment = process.env.NODE_ENV === "development"

export const isTest = process.env.NODE_ENV === "test"

export const isStaging = process.env.NODE_ENV === "staging"

export const isLocal = process.env.NODE_ENV === "local"

export const AWS_S3_DOMAIN_URL = process.env.REACT_APP_AWS_S3_DOMAIN_URL
