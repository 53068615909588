import React from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"
import error from "../../../assets/images/error-img.png"

const Page404 = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from || "/"
  console.log("Page404 -> from", location.state?.from)

  //meta title
  document.title = "MahaloHub | 404 Page Not Found"

  return (
    <div className="account-pages my-5 pt-5">
      <Container>
        <Row>
          <Col lg="12">
            <div className="text-center mb-5">
              <h1 className="display-2 fw-medium">
                4
                <i className="bx bx-buoy bx-spin text-primary display-3" />4
              </h1>
              <h4 className="text-uppercase">Page Not Found</h4>
              <div className="mt-5 text-center">
                <button
                  className="btn btn-primary waves-effect waves-light "
                  onClick={() => {
                    navigate(from, { replace: true })
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8" xl="6">
            <div>
              <img src={error} alt="" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Page404
