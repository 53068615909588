import React, { useEffect, useMemo, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { initAdminAuth, initAuth } from "../store/actions"
import clearUserData from "Shared/helpers/clearUserData"
import { queryClient } from "index"
import SessionExpired401 from "../pages/SessionExpired401"

function SyncAccounts() {
  const [token, setToken] = useState(null)
  const [superToken, setSuperToken] = useState(null)
  const [session, setSession] = useState(null)
  const auth = useSelector(state => state.Auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [expiredSession, setExpiredSession] = useState(false)

  const clearData = clearUserData()

  const [sessionId, setSessionId] = useState(null)

  useEffect(() => {
    const onLoad = () => {
      setToken(localStorage.getItem("token"))
      setSuperToken(localStorage.getItem("superToken"))
      if (!session) {
        setSession(localStorage.getItem("session"))
      }
    }

    window.addEventListener("storage", onLoad)
    return () => {
      window.removeEventListener("storage", onLoad)
    }
  }, [])

  useEffect(() => {
    if (session) {
      if (!sessionId) setSessionId(session)
      else {
        if (sessionId !== session) {
          setExpiredSession(true)
        }
      }
    }
  }, [session])

  const isPeerUser = useMemo(() => {
    return Boolean(token && !auth.token && !superToken && !auth.superToken)
  }, [token, superToken])

  const isPeerUserLogout = useMemo(() => {
    return Boolean(
      !token && auth.token && !superToken && !auth.superToken && auth.authUser
    )
  }, [token, superToken])

  const isPeerSuperUser = useMemo(() => {
    return Boolean(superToken && !auth.superToken && !token && !auth.token)
  }, [token, superToken])

  const isPeerSuperUserLogout = useMemo(() => {
    return Boolean(
      !superToken &&
        auth.superToken &&
        !token &&
        !auth.token &&
        !localStorage.getItem("superToken")
    )
  }, [token, superToken])

  const isPeerMultiUser = useMemo(() => {
    return Boolean(superToken && token && auth.superToken && !auth.token)
  }, [token, superToken])

  const isPeerMultiUserLogout = useMemo(() => {
    return Boolean(superToken && !token && auth.superToken && auth.token)
  }, [token, superToken])

  const isPeerTransferOwnersipLogin = useMemo(() => {
    return Boolean(token != auth.token)
  }, [token])

  useEffect(() => {
    if (auth.loading) return
    if (isPeerUser) {
      setExpiredSession(true)
      // dispatch(initAuth({ token: token }))
    } else if (isPeerUserLogout) {
      // window.location.reload()
      setExpiredSession(true)
    } else if (isPeerSuperUser) {
      setExpiredSession(true)
      // dispatch(initAdminAuth({ token: superToken }))
      // navigate(AdminRoutes[0].path)
    } else if (isPeerSuperUserLogout) {
      // window.location.reload()
      setExpiredSession(true)
    } else if (isPeerMultiUser) {
      // dispatch(initAuth({ token: token }))
      // navigate("/user/dashboard")
      setExpiredSession(true)
    } else if (isPeerMultiUserLogout) {
      navigate("/logout")
      setExpiredSession(true)
    } else if (isPeerTransferOwnersipLogin) {
      clearData()
      queryClient.clear()
      // dispatch(initAuth({ token: token }))
      setExpiredSession(true)
    }
  }, [token, superToken])

  return <>{expiredSession ? <SessionExpired401 /> : <Outlet />}</>
}

export default SyncAccounts
