import { dynamicSilentRequest } from "services"

const endpoints = {
  getAdvanceSearch: {
    url: "/api/subscriber/records/search",
    method: "search",
    trueMessage: null,
    falseMessage: "default",
    errorMessage: "Failed to fetch advance search, Please try again later!",
    url2: "",
    url3: "",
  },
}

export const AdvanceSearchService = {
  getAdvanceSearch: async payload => {
    return dynamicSilentRequest(
      endpoints.getAdvanceSearch.url,
      endpoints.getAdvanceSearch.method,
      payload,
      endpoints.getAdvanceSearch.trueMessage,
      endpoints.getAdvanceSearch.falseMessage,
      endpoints.getAdvanceSearch.errorMessage,
      endpoints.getAdvanceSearch.url2,
      endpoints.getAdvanceSearch.url3
    )
  },
}

export default AdvanceSearchService
