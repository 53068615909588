import React, { useEffect, useRef, useCallback, useState } from "react"
//components
import "../ComponentStyle/componentStyle.css"
import withRouter from "components/Common/withRouter"
//dependencies
import { useLocation, useNavigate } from "react-router-dom"
import { Modal, Badge, Button } from "reactstrap"
import PropTypes from "prop-types"
import "./Sidebar.css"
import PlanUpgradeModal from "./PlanUpgradeModal.js/PlanUpgradeModal"
// //Import Scrollbar
import SimpleBar from "simplebar-react"
// MetisMenu
import MetisMenu from "metismenujs"
import { Link } from "react-router-dom"

//Context Api
import {
  useAssignedCampaigns,
  useClearData,
  useUser,
  useUserUpdate,
} from "components/VerticalLayout/ImageContextProvider"
import MahaloBtn from "components/Shared/Buttons/MahaloBtn"
import { queryClient } from "index"
import { switchCompany } from "services/admin/Subscriber/subscriber"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import {
  isAgency,
  isOwner,
  isSubscriber,
  isSubuser,
  isUser,
} from "Shared/utils/roles"
import { switchCompanyAction, switchUserAction } from "store/actions"
//Functional Component
const UserSidebarContent = props => {
  const globalAssignedCampaigns = useAssignedCampaigns()
  const navigate = useNavigate()
  const updateGlobalUser = useUserUpdate()
  const userDetails = useSelector(state => state.Auth.authUser)
  const [isSwitching, setIsSwitching] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }
  const [modal_planUpgrade, setModalPlanUpgrade] = useState(false)

  const ref = useRef()
  function tog_planUpgrade() {
    setModalPlanUpgrade(!modal_planUpgrade)
  }
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  const dispatch = useDispatch()
  const clearData = useClearData()
  const token = useSelector(state => state.Auth.token)

  return (
    <React.Fragment>
      {modalOpen && (
        <PlanUpgradeModal isOpen={modalOpen} toggle={toggleModal} />
      )}
      <div className="d-flex justify-content-center mt-2">
        {localStorage.getItem("superAgency") ? (
          <MahaloBtn
            preloader={isSwitching}
            disabled={!globalAssignedCampaigns}
            handleSubmit={async () => {
              setIsSwitching(true)
              await switchCompany(localStorage.getItem("superAgency"))
                .then(res => {
                  if (res?.data) {
                    clearData()
                    queryClient.clear()
                    localStorage.removeItem("superAgency")
                    console.log('switchbackfromsidebar')
                    console.log(res)
                    dispatch(switchUserAction(res?.data))
                    // dispatch(switchCompanyAction({ token: token }))
                    navigate("/user/agency")
                  } else {
                    toast.error("Failed to switch company")
                    console.warn("Failed to switch company", res)
                  }
                })
                .catch(err => {
                  console.log(err)
                })
                .finally(() => {
                  setIsSwitching(false)
                })
            }}
            rounded={"rounded-3"}
            onClick={() => { }}
            style={{
              borderRadius: "10px !important",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="backToAgencyBtn"
          // variant="mahalo-primary"
          >
            <div className="d-flex gap-2 justify-content-center align-items-center px-2">
              <i className="fas fa-arrow-left" />
              <span className="backToAgencyText">Back to Agency</span>
            </div>
          </MahaloBtn>
        ) : (
          <span className="mt-2"></span>
        )}
      </div>
      <SimpleBar
        className="h-100"
        style={{
          Height: "calc(100vh-500px)",
          minHeight: "500px",
          maxHeight: "650px",
        }}
        ref={ref}
      >
        <div id="sidebar-menu" className="menu-mahalo mt-0 pt-0">
          <ul
            className="metismenu list-unstyled"
            id="side-menu"
            onClick={() => {
              document.body.classList.remove("sidebar-enable")
            }}
          >
            <li className="menu-title dark">{""}</li>
            <li>
              <Link to="/user/dashboard">
                <i className="bx bx-file"></i>
                <span>{"Dashboard"}</span>
              </Link>
            </li>
            <li>
              <Link to="/user/invites">
                <i className="fas fa-user-friends"></i>
                <span className="dark">{"Invites"}</span>
              </Link>
            </li>
            <li>
              <Link to="/user/library#active">
                <i className="fas fa-book"></i>
                <span className="dark">{"My Library"}</span>
              </Link>
            </li>
            {userDetails && !isSubuser() && (
              <li>
                <Link to="/user/reward">
                  <i className="fas fa-gift"></i>
                  <span className="dark">{"Rewards"}</span>
                </Link>
              </li>
            )}
            <li>
              <Link to="/user/analytics">
                <i className="fas fa-chart-bar"></i>
                <span className="dark">{"Analytics"}</span>
              </Link>
            </li>
            <li>
              <Link to="/user/manage">
                <i className="fas fa-cog"></i>
                <span className="dark">{"Manage"}</span>
              </Link>
            </li>
            {userDetails && isSubscriber() && (
              <li>
                <Link to="/user/campaign">
                  <i
                    className="bx bx-bullseye"
                    style={{ fontSize: "x-large" }}
                  ></i>
                  <span className="dark">{"Campaigns"}</span>
                </Link>
              </li>
            )}

            {!isSubuser() && (
              <li>
                <Link to="/user/users">
                  <i
                    className="bx bx-user-circle"
                    style={{ fontSize: "x-large" }}
                  ></i>
                  <span className="dark">{"Users"}</span>
                </Link>
              </li>
            )}
            {userDetails && isOwner() && isAgency() && (
              <li>
                <Link to="/user/agency">
                  <i
                    className="fas fa-building"
                    style={{ fontSize: "x-large" }}
                  ></i>
                  <span className="dark">{"Agency"}</span>
                </Link>
              </li>
            )}
            <li>
              <Link to="/user/support">
                <i
                  className="bx bxs-user-voice "
                  style={{ fontSize: "x-large" }}
                ></i>
                <span className="dark">{"Support"}</span>
              </Link>
            </li>
            <li>
              {userDetails && isSubscriber() && (
                <div className="d-flex justify-content-center mt-2">
                  <button
                    className="upgrade-plan btn-rounded text-break text-wrap"
                    onClick={toggleModal}
                  >
                    Upgrade Plan
                  </button>
                </div>
              )}
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

UserSidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(UserSidebarContent)
