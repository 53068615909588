import React, { useMemo } from "react"
import { useSelector } from "react-redux"

function isUser() {
  const auth = useSelector(state => state.Auth)
  const user = useMemo(() => {
    return !!auth.authUser && !auth.superUser
  }, [auth.authUser, auth.superUser])
  return user
}

export default isUser
